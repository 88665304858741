import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import TypographyUI from '../../components/TypographyUI';

const Success = () => {
  return (
    <Box className="centered">
      <FontAwesomeIcon
        icon={faCircleCheck}
        size="2xl"
        className="icon-success"
        color="#66DEAE"
      />
      <TypographyUI variant="h2" align="center">
        Succès
      </TypographyUI>
      <TypographyUI variant="h4" fontSize="16px" align="center">
        Vous avez été invité avec succès
      </TypographyUI>
    </Box>
  );
};

export default Success;
