import { DialogTitle } from '@mui/material';
import React from 'react';

const DialogTitleCustom = ({
  align = 'left',
  children,
  id,
  fontWeight = 550,
  fontSize = '24px',
  marginBottom = '0',
}) => {
  return (
    <DialogTitle
      id={id}
      sx={{
        color: '#4546CD',
        textAlign: align,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: fontWeight,
        fontSize: fontSize,
        marginBottom: marginBottom,
      }}
    >
      {children}
    </DialogTitle>
  );
};

export default DialogTitleCustom;
