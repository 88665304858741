import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import '../Home.css';
import { useLearnerContext } from '../../context/LearnerContext';
import { useEffect, useState } from 'react';
import EvaluateTable from './components/EvaluateTable';
import TypographyUI from '../../components/TypographyUI';

function Evaluate() {
  const { getStatistics, statistics, exportStatistics } = useLearnerContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoaderVisible, setLoaderVisible] = useState(true);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchData = async () => {
    await getStatistics();
    setLoaderVisible(false);
  };
  const filteredBroadcasts = statistics.filter((broadcast) => {
    return (
      broadcast.status !== 'DRAFT' &&
      (broadcast.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      broadcast.type.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const handleClick = async () => {
    await exportStatistics();
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoaderVisible) return <></>;

  return (
    <Box>
      <Box>
        <TypographyUI variant="h6" align="left" marginBottom="5px">
          Resultats
        </TypographyUI>
        <Grid
          container
          spacing={0}
          display={'flex'}
          justifyContent={'space-between'}
          gap={1}
        >
          <Grid item className="align-cell-center">
            <TextField
              label="Recherche"
              variant="outlined"
              size="small"
              value={searchTerm}
              autoComplete="off"
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item className="align-cell-center">
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Exporter
              </Button>
            </Box>
          </Grid>
        </Grid>

        {statistics.length > 0 ? (
          <EvaluateTable filteredBroadcasts={filteredBroadcasts} />
        ) : (
          <Typography variant="body1" style={{ padding: '16px' }}>
            Aucune évaluation disponible.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default Evaluate;
