import { BASE_URL } from '../constants/config';
import axios from './app.utils';

export const LEARNERS_ENDPOINTS = {
  getTemplate: `${BASE_URL}api/learners/bulk_template`,
  parseUpload: `${BASE_URL}api/learners/parse_upload`,
  bulk_insert: `${BASE_URL}api/learners/bulk_insert`,
  learners: `${BASE_URL}api/learners/`,
  inviteLearner: `${BASE_URL}api/learners/broadcast_invite`,
  welcome: `${BASE_URL}api/learners/broadcast`,
  unsubscribe: `${BASE_URL}api/learners/unsubscribe`,
  invitation_preview: `${BASE_URL}api/learners/invitation_preview`,
  
};

const token = localStorage.getItem('authorization');

class Learners {
  async getTemplate() {
    const response = await axios.get(LEARNERS_ENDPOINTS.getTemplate, {
      responseType: 'blob',
      headers: {
        authorization: token,
      },
    });

    return response;
  }

  async validateTemplate(file) {
    const response = await axios.post(
      LEARNERS_ENDPOINTS.parseUpload,
      { file },
      {
        headers: {
          authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response;
  }

  async bulkInsert(data, companyId) {
    const response = await axios.post(
      LEARNERS_ENDPOINTS.bulk_insert,
      { data, company_id: companyId },
      {
        headers: {
          authorization: token,
        },
      }
    );

    return response;
  }

  async createLearner(data) {
    const response = await axios.post(LEARNERS_ENDPOINTS.learners, data, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async updateLearner(id, updatedData) {
    const response = await axios.put(
      `${LEARNERS_ENDPOINTS.learners}/${id}`,
      updatedData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
  async getById(id) {
    const response = await axios.get(`${LEARNERS_ENDPOINTS.learners}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async getAll(filter = false) {
    // const isQuerry = filter;
    // To Do - filter available learners
    // const query = `${LEARNERS_ENDPOINTS.learners}?broadcast_query=true`;

    const response = await axios.get(
      // isQuerry ? query : LEARNERS_ENDPOINTS.learners,
      LEARNERS_ENDPOINTS.learners,
      {
        headers: {
          authorization: token,
        },
      }
    );

    return response;
  }
  async deleteLearner(id, selectedCompanyId) {
    const response = await axios.delete(
      `${LEARNERS_ENDPOINTS.learners}/${id}?companyId=${selectedCompanyId}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async inviteLearner(data) {
    const response = await axios.post(
      LEARNERS_ENDPOINTS.inviteLearner,
      { data },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async welcomeLearner(id) {
    const response = await axios.get(`${LEARNERS_ENDPOINTS.welcome}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async unsubscribeLearner(id, removal_reason) {
    const response = await axios.post(
      `${LEARNERS_ENDPOINTS.unsubscribe}/${id}`,
      { removal_reason },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async unSubscribeLearners(data) {
    const response = await axios.post(
      `${LEARNERS_ENDPOINTS.unsubscribe}`,
      data,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async invitationPreview(data) {
    const response = await axios.post(
      LEARNERS_ENDPOINTS.invitation_preview,
      { data },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
}

export const LearnersService = new Learners();
