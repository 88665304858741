import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { ADMIN_COMPANY } from '../../../constants/auth';

const EditButton = ({ handleOpen, companyName = '' }) => {
  const isCompanyName = !!companyName;

  return (
    <Tooltip title="Modifier">
      <Button
        onClick={handleOpen}
        disabled={isCompanyName && companyName.trim() === ADMIN_COMPANY}
        sx={{
          color: '#4546CD',
          opacity:
            isCompanyName && companyName.trim() === ADMIN_COMPANY ? '0.3' : '1',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          margin: 0,
          padding: 0,
        }}
        size="small"
        startIcon={<i className="fas fa-edit"></i>}
      />
    </Tooltip>
  );
};

export default EditButton;
