import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  CssBaseline,
  Snackbar,
  TextField,
} from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import axios from '../../services/app.utils';
import { UsersService } from '../../services/users';
import useStore from '../../store/store';
import { BASE_URL } from '../../constants/config';
import { useNavigate } from 'react-router-dom';
import { removeSpaces, setPlaceholderForInput } from '../../helpers';

function ForgotPassword() {
  const containerStyle = {
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const formStyle = {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const submitStyle = {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#4caf50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [phoneExists, setPhoneExists] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const setSnackbarContent = useStore((store) => store.setSnackbarContent);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    return passwordRegex.test(password);
  };

  const handleNewPasswordChange = (event) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);

    let error = '';

    if (newPassword.length < 8 || newPassword.length > 30) {
      error = 'Password must be between 8 and 30 characters.';
    } else if (!/[a-z]/.test(newPassword)) {
      error = 'Password must include at least one lowercase letter.';
    } else if (!/[A-Z]/.test(newPassword)) {
      error = 'Password must include at least one uppercase letter.';
    } else if (!/\d/.test(newPassword)) {
      error = 'Password must include at least one number.';
    } else if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(newPassword)) {
      error = 'Password must include at least one special character.';
    }

    setIsPasswordValid(error === '');
    setPasswordError(error);
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const sendSMS = async (event) => {
    event.preventDefault();
    try {
      await UsersService.forgotPassword(removeSpaces(phoneNumber));
      navigate('/phone-success-sent');

      setSnackbarContent(
        'success',
        'SMS envoyé avec succès sur votre téléphone!'
      );
    } catch (e) {
      console.log(e);
      setSnackbarContent(
        'error',
        'Oups, peut-être avez-vous tapé un mauvais numéro de téléphone ?'
      );
    }
  };

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        'http://localhost:3000/api/users/check-phone',
        { phoneNumber }
      );
      setPhoneExists(response.data.exists);

      if (response.data.exists) {
        await axios.post('http://localhost:3000/api/users/forgot_password', {
          phoneNumber,
        });
        setStep(2);
      } else {
      }
    } catch (error) {
      console.error('Error checking phone number:', error);
    }
  };

  const handleConfirmationCodeSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}api/users/reset_password`, {
        phoneNumber,
        resetCode: confirmationCode,
        newPassword,
      });

      if (response.data.success) {
        setStep(3);
      } else {
      }
    } catch (error) {
      console.error('Error verifying confirmation code:', error);
    }
  };

  const handleNewPasswordSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}api/users/reset_password`, {
        phoneNumber,
        resetCode: confirmationCode,
        newPassword,
      });

      if (response.data.success) {
      } else {
      }
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  useEffect(() => {
    setPlaceholderForInput();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={containerStyle}>
        <Typography
          component="h1"
          variant="h5"
          style={{ marginBottom: '16px' }}
        >
          {step === 1 && 'Mot de passe oublié'}
          {step === 2 && 'Enter Confirmation Code'}
          {step === 3 && 'Set New Password'}
        </Typography>

        {step === 1 && (
          <form style={formStyle} onSubmit={sendSMS}>
            <PhoneInput
              defaultCountry={'fr'}
              placeholder="Phone Number"
              disableCountryGuess={true}
              forceDialCode={true}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ marginBottom: '20px', width: '100%', display: 'flex' }}
              inputStyle={{ maxWidth: '385px', width: '100%', height: '50px', marginTop: '10px' }}
              countrySelectorStyleProps={{
                buttonStyle: { height: '50px', marginTop: '10px' },
              }}
              dialCodePreviewStyleProps={{
                style: {
                  height: '50px',
                  alignSelf: 'center',
                  marginTop: '10px',
                },
              }}
            />
            <Button type="submit" fullWidth style={submitStyle}>
              Réinitialiser
            </Button>
          </form>
        )}

        {step === 2 && (
          <form style={formStyle} onSubmit={handleConfirmationCodeSubmit}>
            {/* Confirmation code input and submit button */}
            <input
              type="text"
              placeholder="Enter Confirmation Code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            <Button type="submit" fullWidth style={submitStyle}>
              Submit Code
            </Button>
          </form>
        )}

        {step === 3 && (
          <form style={formStyle} onSubmit={handleNewPasswordSubmit}>
            {/* New password and confirm password inputs */}
            <TextField
              required
              label="New Password"
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              fullWidth
              margin="dense"
              error={!isPasswordValid && newPassword.length > 0}
              helperText={newPassword.length > 0 ? passwordError : ''}
            />
            {passwordError && <div>{passwordError}</div>}
            <TextField
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <Button type="submit" fullWidth style={submitStyle}>
              Set New Password
            </Button>
          </form>
        )}
      </div>
    </Container>
  );
}

export default ForgotPassword;
