import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const SelectResponseType = ({
  responseType,
  handleResponseTypeChange,
  selectedForm,
  webAnswer,
}) => {
  return (
    <Box className="vertical-center">
      <InputLabel
        id="label-response-type"
        style={{ marginRight: '20px', marginLeft: '2px' }}
        sx={{ fontSize: 14 }}
      >
        Souhaitez-vous une réponse par SMS ?
      </InputLabel>
      <RadioGroup
        aria-label="responseType"
        name="responseType"
        className="response-types"
        value={responseType}
        onChange={handleResponseTypeChange}
        row
      >
        <FormControlLabel value="Non" control={<Radio />} label="Non" />
        <FormControlLabel
          value="Oui réponse ouverte"
          control={<Radio disabled={!!selectedForm || webAnswer} />}
          label="Oui réponse ouverte"
        />
        <FormControlLabel
          value="Oui réponse fermée"
          control={<Radio disabled={!!selectedForm || webAnswer} />}
          label="Oui réponse fermée"
        />
      </RadioGroup>
    </Box>
  );
};

export default SelectResponseType;
