import { BASE_URL } from '../constants/config';
import axios from './app.utils';

export const COMPANY_ENDPOINTS = {
  companies: `${BASE_URL}api/companies/`,
  statistics: `${BASE_URL}api/statistics`,
  getLogo: (company_id) => `${BASE_URL}api/companies/${company_id}/logo`,
};

const token = localStorage.getItem('authorization');

class Companies {
  async getAll() {
    const response = await axios.get(COMPANY_ENDPOINTS.companies, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }

  async getById(id) {
    const response = await axios.get(`${COMPANY_ENDPOINTS.companies}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async getCompanyStatistics(id) {
    const response = await axios.get(`${COMPANY_ENDPOINTS.companies}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async getCompanyCreditHistory(id) {
    const response = await axios.get(`${COMPANY_ENDPOINTS.companies}${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async getCompanyLogo(company_id) {
    const response = await axios.get(
      `${COMPANY_ENDPOINTS.getLogo(company_id)}`,
      {
        headers: {
          authorization: token,
        },
        responseType: 'arraybuffer',
      }
    );
    return response;
  }

  async createCompany(data) {
    const response = await axios.post(COMPANY_ENDPOINTS.companies, data, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async updateCompany(id, data) {
    const response = await axios.put(
      `${COMPANY_ENDPOINTS.companies}/${id}`,
      data,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async deleteCompany(id) {
    const response = await axios.delete(`${BASE_URL}api/companies/${id}`, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }
}

export const CompanyServices = new Companies();
