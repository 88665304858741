import { Typography } from '@mui/material';
import React from 'react';

const TypographyUI = ({
  align = 'left',
  variant = 'h6',
  children,
  fontWeight = 700,
  fontSize = '32px',
  marginBottom = "0",
  color = '#4546CD',
}) => {
  return (
    <Typography
      variant={variant}
      align={align}
      sx={{
        color: color,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: fontWeight,
        fontSize: `calc(${fontSize} / 1.3)`,
        marginBottom: marginBottom,
        
        '@media (min-width: 600px)': {
          fontSize: fontSize,
        }
      }}
    >
      {children}
    </Typography>
  );
};

export default TypographyUI;
