import React, { useState } from 'react';
import '../styles/SideMenu.scss';
import { Link } from 'react-router-dom';
import { getNavbarRoutesProfiles } from './AppRouter/routes';
import useStore from '../store/store';
import { ROLES } from '../constants/auth';

function SideMenuProfile({ croute, setCroute }) {
  const isMenuOpen = useStore((state) => state.isMenuOpen);
  const toggleMenu = useStore((state) => state.toggleMenu);
  const closeToggleMenu = useStore((state) => state.closeToggleMenu);

  // Get role after auth
  const role = useStore((state) => state.userRole);

  const routes = getNavbarRoutesProfiles(role);

  if (role === ROLES.UNAUTHORIZED) {
    return <></>;
  }

  return (
    <>
      <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          {routes.map((route) => (
            <Link
              to={route.path}
              key={route.path}
              onClick={() => {
                setCroute(route.path);
                closeToggleMenu();
              }}
              className={route.path === croute ? 'side-menu-selected' : ''}
            >
              {route.label}
              <span
                className={route.path === croute ? 'side-menu-bubble' : ''}
              />
            </Link>
          ))}
        </ul>
      </div>
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </>
  );
}

export default SideMenuProfile;
