import { Button, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { diffuserTypes } from '../../../constants/broadcast';

const DiffuserFooterActions = ({
  handleClose,
  isSubmitBtnDisabled,
  handleSubmit,
  isTitleEmpty,
  isContentEmpty,
  disabled,
  selectionValue,
  prepareLaunchBroadcast,
}) => {
  return (
    <Box display={'flex'} justifyContent={'center'} marginTop={'30px'} gap={1}>
      <Button variant="outlined" color="error" onClick={handleClose}>
        Annuler
      </Button>
      <Tooltip title={isSubmitBtnDisabled ? 'Loading' : ''}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              isTitleEmpty ||
              isContentEmpty ||
              disabled ||
              isSubmitBtnDisabled
            }
          >
            Enregistrer
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={isSubmitBtnDisabled ? 'Loading' : ''}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={prepareLaunchBroadcast}
            disabled={
              isTitleEmpty ||
              isContentEmpty ||
              disabled ||
              isSubmitBtnDisabled
            }
          >
            Diffuser
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default DiffuserFooterActions;
