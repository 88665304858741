import { FilesService } from '../services/files';

export const downloadAvatar = async (identifier, setFunction) => {
  try {
    const response = await FilesService.downloadFile(identifier);
    const fileData = response.data;

    const blob = new Blob([fileData], {
      type: 'application/octet-stream',
    });

    const reader = new FileReader();

    reader.onload = function () {
      const base64String = reader.result.split(',')[1];
      localStorage.setItem('avatar', `data:image/jpeg;base64, ${base64String}`);
      setFunction(`data:image/jpeg;base64, ${base64String}`);
    };

    reader.readAsDataURL(blob);
  } catch (e) {
    console.error(e);
  }
};
