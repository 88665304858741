export const learnerRegisteredFrom = {
    // Via link
    registration: {
        value: 'registration',
        label: "Via lien d'inscription",
    },
    // Via DB
    native: {
        value: 'native',
        label: 'Via base de donnée',
    },
    // Unsubscrived
    unsubscribed: {
        value: 'unsubscribed',
        label: 'Désinscrits',
    },
    unknown: {
        value: '',
        label: ''
    }
}