import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

const ResponseActions = ({
  responseActionType,
  getRootProps,
  getInputProps,
  toggleEmojiPicker,
  openDialog,
  handleOpenUrl,
  openUrl,
  handleInputChange,
  url,
  handleSubmitUrl,
  handleCloseUrl,
  isDialogOpen,
  selectedFile,
  closeDialog,
  handleFileUpload,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '105px',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        className='emojie-picker-btn'
        onClick={() => toggleEmojiPicker(responseActionType)}
        style={{
          borderRadius: '50%',
          padding: '0',
          minWidth: '30px',
          minHeight: '30px',
          marginLeft: '10px',
        }}
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          className="iconify iconify--emojione-monotone"
          fill="white"
        >
          <path d="M59.998 21.223a12.826 12.826 0 0 1-.006-.402v-.527c0-.41-.045-.492-.263-.604-.156-.08-.316-.154-.478-.229C54.504 9.156 44.09 2 32 2S9.497 9.156 4.748 19.461c-.161.074-.321.148-.477.229-.219.111-.262.193-.262.604v.527c0 .178-.003.301-.006.404A29.89 29.89 0 0 0 2 32c0 16.568 13.432 30 30 30s30-13.432 30-30a29.93 29.93 0 0 0-2.002-10.777M32 4.5c10.278 0 19.252 5.672 23.971 14.047-3.744-.625-8.794-.586-11.467-.354-3.436.303-6.307 1.076-8.656 2.279-2.198 1.098-5.497 1.098-7.697 0-2.349-1.203-5.22-1.977-8.654-2.279-2.673-.232-7.722-.271-11.467.354C12.748 10.172 21.722 4.5 32 4.5m0 55C16.836 59.5 4.5 47.164 4.5 32c0-3.041.504-5.967 1.42-8.705.596 1.066.998 2.553 1.259 4.346.598 4.213 2.666 6.854 6.022 8.115a13.471 13.471 0 0 0 9.69-.105c1.691-.676 3.176-1.742 4.355-3.477 2.067-3.037 1.448-4.936 2.516-7.547.932-2.277 3.541-2.277 4.473 0 1.067 2.611.448 4.51 2.516 7.547 1.179 1.734 2.664 2.801 4.354 3.477a13.474 13.474 0 0 0 9.691.105c3.356-1.262 5.424-3.902 6.022-8.115.262-1.793.663-3.281 1.26-4.346A27.38 27.38 0 0 1 59.498 32C59.5 47.164 47.164 59.5 32 59.5" />
          <path d="M44.584 42.279c-8.109 5.656-17.105 5.623-25.168 0-.97-.678-1.845.494-1.187 1.578 2.457 4.047 7.417 7.649 13.771 7.649s11.314-3.604 13.771-7.649c.659-1.084-.216-2.253-1.187-1.578" />
        </svg>
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => openDialog(responseActionType)}
        style={{
          borderRadius: '50%',
          padding: '0',
          minWidth: '30px',
          minHeight: '30px',
          marginLeft: '10px',
        }}
      >
        <svg
          height="26"
          width="26"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 184.69 184.69"
          fill="white"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <g>
                <g>
                  <path d="M149.968,50.186c-8.017-14.308-23.796-22.515-40.717-19.813 C102.609,16.43,88.713,7.576,73.087,7.576c-22.117,0-40.112,17.994-40.112,40.115c0,0.913,0.036,1.854,0.118,2.834 C14.004,54.875,0,72.11,0,91.959c0,23.456,19.082,42.535,42.538,42.535h33.623v-7.025H42.538 c-19.583,0-35.509-15.929-35.509-35.509c0-17.526,13.084-32.621,30.442-35.105c0.931-0.132,1.768-0.633,2.326-1.392 c0.555-0.755,0.795-1.704,0.644-2.63c-0.297-1.904-0.447-3.582-0.447-5.139c0-18.249,14.852-33.094,33.094-33.094 c13.703,0,25.789,8.26,30.803,21.04c0.63,1.621,2.351,2.534,4.058,2.14c15.425-3.568,29.919,3.883,36.604,17.168 c0.508,1.027,1.503,1.736,2.641,1.897c17.368,2.473,30.481,17.569,30.481,35.112c0,19.58-15.937,35.509-35.52,35.509H97.391 v7.025h44.761c23.459,0,42.538-19.079,42.538-42.535C184.69,71.545,169.884,53.901,149.968,50.186z"></path>
                </g>
                <g>
                  <path d="M108.586,90.201c1.406-1.403,1.406-3.672,0-5.075L88.541,65.078 c-0.701-0.698-1.614-1.045-2.534-1.045l-0.064,0.011c-0.018,0-0.036-0.011-0.054-0.011c-0.931,0-1.85,0.361-2.534,1.045 L63.31,85.127c-1.403,1.403-1.403,3.672,0,5.075c1.403,1.406,3.672,1.406,5.075,0L82.296,76.29v97.227 c0,1.99,1.603,3.597,3.593,3.597c1.979,0,3.59-1.607,3.59-3.597V76.165l14.033,14.036 C104.91,91.608,107.183,91.608,108.586,90.201z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenUrl(responseActionType)}
        style={{
          borderRadius: '50%',
          padding: '0',
          minWidth: '30px',
          minHeight: '30px',
          marginLeft: '10px',
        }}
      >
        <svg
          width="26"
          height="26"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="white"
        >
          <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
        </svg>
      </Button>
      <Dialog open={openUrl === responseActionType} maxWidth="sm" fullWidth>
        <DialogTitle>Ajouter un lien</DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 5,
          }}
        >
          <TextField
            label="Entrez votre lien ici"
            variant="outlined"
            style={{ width: '70%' }}
            onChange={(e) => handleInputChange(e, responseActionType)}
            value={url}
          />
          <Button
            onClick={() => handleSubmitUrl(responseActionType)}
            variant="contained"
            style={{ marginLeft: '30px' }}
          >
            Enregistrer
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseUrl(responseActionType)}
            variant="outlined"
            color="error"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDialogOpen === responseActionType}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Ajouter un média</DialogTitle>
        <DialogContent>
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            {selectedFile ? (
              <Typography sx={{ cursor: 'pointer' }}>
                Fichier téléchargé: {selectedFile.name}, cliquez ici pour
                resélectionner
              </Typography>
            ) : (
              <Typography className="drop-area">
                Faites glisser votre média ou cliquez ici pour le sélectionner
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeDialog(responseActionType)}
            variant="outlined"
            color="error"
          >
            Fermer
          </Button>
          <Button
            variant="contained"
            onClick={() => handleFileUpload(responseActionType)}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResponseActions;
