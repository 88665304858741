import { Box } from '@mui/material';
import React from 'react';

const ReturnButton = ({ isAuhtorized, returnBack }) => {
  return isAuhtorized ? (
    <Box
      sx={{
        marginBottom: '20px',
        alignSelf: 'flex-start',
        cursor: 'pointer',
      }}
      onClick={returnBack}
    >
      <font size="4" style={{ marginRight: '5px' }}>
        <div className="fa fa- fa-chevron-circle-left"></div>
      </font>
      Retour à la liste des formulaires
    </Box>
  ) : null;
};

export default ReturnButton;
