import React, { useEffect, useState } from 'react';
import '../Home.css';
import TextField from '@mui/material/TextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { UsersService } from '../../services/users';
import useStore from '../../store/store';
import ProfileAvatar from './components/ProfileAvatar';
import ProfileForm from './components/ProfileForm';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Profile() {
  const [image, setImage] = useState(null);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [telephone, setTelephone] = useState('');
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const { setAvatar, setSnackbarContent } = useStore((state) => state);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showRetryPassword, setShowRetryPassword] = useState(false);
  const handleClickShowRetryPassword = () =>
    setShowRetryPassword(!showRetryPassword);

  const handlePasswordDialogOpen = () => {
    setOpenPasswordDialog(true);
  };

  const handlePasswordDialogClose = () => {
    setOpenPasswordDialog(false);
    setCurrentPassword('');
    setNewPassword('');
  };

  const handleNewPasswordChange = (event) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);

    let error = '';

    if (newPassword.length < 12 || newPassword.length > 30) {
      error = 'Le mot de passe doit comporter entre 8 et 30 caractères.';
    } else if (!/[a-z]/.test(newPassword)) {
      error = 'Le mot de passe doit inclure au moins une lettre minuscule.';
    } else if (!/[A-Z]/.test(newPassword)) {
      error = 'Le mot de passe doit inclure au moins une lettre majuscule.';
    } else if (!/\d/.test(newPassword)) {
      error = 'Le mot de passe doit inclure au moins un numéro.';
    } else if (!/[^a-zA-Z0-9\s]/.test(newPassword)) {
      error = 'Le mot de passe doit inclure au moins un caractère spécial.';
    }

    setIsPasswordValid(error === '');
    setPasswordError(error);
  };

  const handlePasswordSave = async () => {
    try {
      const getUserResponse = await UsersService.getUser();
      const userId = getUserResponse.data._id;
      const passwordUpdateData = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };
      await UsersService.updatePassword(userId, passwordUpdateData);
      setSnackbarContent('success', "Mot de passe mis à jour avec succès'");
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.error || 'Error');
    }
  };

  const getUserData = async () => {
    const response = await UsersService.getUser();
    const userData = response.data;
    setNom(userData.lastName);
    setPrenom(userData.firstName);
    setOrganisation(userData.company_name);
    setTelephone(userData.phoneNumber);
  };

  useEffect(() => {
    const avatar = localStorage.getItem('avatar');
    setImage(avatar);
    getUserData();
  }, []);

  const handleImageUpload = async (event) => {
    const selectedImage = event.target.files[0];

    if (!selectedImage) {
      return;
    }

    const imageURL = URL.createObjectURL(selectedImage);
    setImage(imageURL);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const imageBuffer = event.target.result;

      const formData = new FormData();
      formData.append(
        'file',
        new Blob([imageBuffer], { type: selectedImage.type })
      );

      const getUser = await UsersService.getUser();

      const userId = getUser.data._id;
      try {
        const uploadResponse = await UsersService.uploadAvatar(formData);

        const identifier = uploadResponse.data.identifier;

        try {
          await UsersService.updateUser(userId, {
            avatar: identifier,
          });

          const downloadResponse =
            await UsersService.downloadAvatar(identifier);

          const blob = new Blob([downloadResponse.data], {
            type: 'application/octet-stream',
          });

          const reader = new FileReader();

          reader.onload = function () {
            const base64String = reader.result.split(',')[1];
            localStorage.setItem(
              'avatar',
              `data:image/jpeg;base64, ${base64String}`
            );
            setAvatar(`data:image/jpeg;base64, ${base64String}`);
          };

          reader.readAsDataURL(blob);
        } catch (downloadError) {
          console.error('Download error:', downloadError);
        }
      } catch (uploadError) {
        console.error('Upload error:', uploadError);
      }
    };

    reader.readAsArrayBuffer(selectedImage);
  };

  const updateUser = async (userId) => {
    const updatedUser = {
      lastName: nom,
      firstName: prenom,
      // company_id: organisation,
      phoneNumber: telephone,
    };

    try {
      await UsersService.updateUser(userId, updatedUser);
      setSnackbarContent(
        'success',
        'Données utilisateur mises à jour avec succès'
      );
    } catch (error) {
      console.error('Error updating user data:', error);
      setSnackbarContent(
        'error',
        'Les données utilisateur ne sont pas mises à jour'
      );
    }
  };

  const handleSubmit = async (e) => {
    const getUserResponse = await UsersService.getUser();
    const userId = getUserResponse.data._id;
    e.preventDefault();
    await updateUser(userId);
    await getUserData();
  };

  const handleInputChange = (field, value) => {
    switch (field) {
      case 'prenom':
        setPrenom(value);
        break;
      case 'nom':
        setNom(value);
        break;
      // case "organisation":
      //   setOrganisation(value);
      //   break;
      case 'telephone':
        setTelephone(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="profile-content">
      <ProfileAvatar image={image} handleImageUpload={handleImageUpload} />
      <div className="profile-elements">
        <ProfileForm
          prenom={prenom}
          nom={nom}
          organisation={organisation}
          telephone={telephone}
          handleInputChange={handleInputChange}
          handlePasswordDialogOpen={handlePasswordDialogOpen}
          handleSubmit={handleSubmit}
        />
      </div>
      <Dialog open={openPasswordDialog} fullWidth>
        <DialogTitle>Modifier le mot de passe</DialogTitle>
        <DialogContent>
          <TextField
            required
            label="Mot de passe actuel"
            type={showPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            label="Nouveau mot de passe"
            type={showRetryPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={handleNewPasswordChange}
            fullWidth
            margin="dense"
            error={!isPasswordValid && newPassword.length > 0}
            helperText={newPassword.length > 0 ? passwordError : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle retryPassword visibility"
                    onClick={handleClickShowRetryPassword}
                    onMouseDown={handleClickShowRetryPassword}
                  >
                    {showRetryPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handlePasswordDialogClose}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordSave}
            disabled={passwordError}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Profile;
