import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { responseTypes } from '../../constants/form';
import { FormsService } from '../../services/forms';
import '../Form/Form.scss';
import useStore from '../../store/store';
import TypographyUI from '../../components/TypographyUI';
import { CompanyServices } from '../../services/companies';
import { downloadImageBase64 } from '../../helpers/company';

import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormToPass = () => {
  const [form, setForm] = useState({});
  const { id } = useParams();
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const navigate = useNavigate();
  const {
    setSnackbarContent,
    companyLogo,
    setCompanyLogo,
    setTotallPointsFormSubmit,
  } = useStore((state) => state);
  const [isError, setIsError] = useState(false);
  const [areQuestionsDisabled, setAreQuestionsDisabled] = useState(false);

  const getForm = async () => {
    try {
      const { data } = await FormsService.getByIdToComplete(id);
      setLoaderVisible(false);

      let result;

      if (data.data.answers.length) {
        result = { ...data.data, content: data.data.answers };
        setForm({ ...data.data, content: data.data.answers });
        setAreQuestionsDisabled(true);
      } else {
        result = data.data;
        setForm(data.data);
      }

      const requiredQuestions = data.data.content.filter(
        (question) => question?.required
      );
      setRequiredQuestions(requiredQuestions);

      return result;
    } catch (e) {
      console.error(e);
      setIsError(true);
    }
  };

  const answerQuestion = (
    e,
    question,
    questionIndex,
    isMultipleAnswers = false
  ) => {
    const answer = e.target.value + '';

    setForm((prevForm) => {
      const updatedContent = [...prevForm.content];
      const updatedAnswer = prevForm.content[questionIndex];

      if (isMultipleAnswers) {
        if (!updatedContent[questionIndex]?.answer) {
          updatedContent[questionIndex] = {
            ...updatedAnswer,
            answer: [answer],
          };

          return { ...prevForm, content: updatedContent };
        }

        const index = updatedContent[questionIndex]?.answer.indexOf(answer);
        if (index !== -1) {
          updatedContent[questionIndex]?.answer.splice(index, 1);
          return { ...prevForm, content: updatedContent };
        }

        updatedContent[questionIndex] = {
          ...updatedAnswer,
          answer: [...updatedContent[questionIndex]?.answer, answer],
        };
        return { ...prevForm, content: updatedContent };
      }

      updatedContent[questionIndex] = { ...updatedAnswer, answer: answer };
      return { ...prevForm, content: updatedContent };
    });
  };

  const submitForm = async () => {
    try {
      const response = await FormsService.completeForm(id, form.content);
      if (response?.data?.data?.score) {
        setTotallPointsFormSubmit(response.data.data.score);
      }
      navigate('/form-completed');
    } catch (e) {
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await getForm();

      if (!companyLogo && result && result?.company_id) {
        downloadImageBase64(
          CompanyServices.getCompanyLogo,
          result.company_id,
          setCompanyLogo
        );
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (form?.content) {
      if (form.answers.length) {
        setIsSubmitBtnDisabled(true);
        return;
      }
      if (requiredQuestions.length === 0) {
        setIsSubmitBtnDisabled(false);
        return;
      }
      const answers = new Map();
      for (let requiredQuestion of requiredQuestions) {
        for (let question of form.content) {
          if (
            requiredQuestion._id === question._id &&
            typeof question.answer === 'string' &&
            question.answer
          ) {
            answers.set(requiredQuestion._id, true);
          } else if (
            requiredQuestion._id === question._id &&
            question.answer instanceof Array &&
            question.answer.length
          ) {
            answers.set(requiredQuestion._id, true);
          }
        }
      }

      if (answers.size === requiredQuestions.length) {
        setIsSubmitBtnDisabled(false);
        return;
      }

      if (!isSubmitBtnDisabled) {
        setIsSubmitBtnDisabled(true);
      }
    }
  }, [form]);

  if (isError)
    return (
      <TypographyUI variant="h4" align="center" fontSize="24px">
        Le formulaire avec cet identifiant n'existe pas
      </TypographyUI>
    );

  if (isLoaderVisible) return <></>;

  return (
    <Grid
      spacing={2}
      display={'flex'}
      flexDirection={'column'}
      className="container-shrinked"
    >
      <Box sx={{ textAlign: 'center' }} className="forms-wrapper">
        <form className="form-answers-list">
          <div className="form-title">
            <h1>{form.title}</h1>
            <pre className="form-description">{form.description}</pre>
            <p className="form-required">* Required</p>
          </div>
          {form?.content &&
            form?.content.map((question, questionIndex) => (
              <div
                key={questionIndex}
                style={{ textAlign: 'left', marginBottom: '20px' }}
                className="form-answers-wrapper"
              >
                <div>
                  {question.question}{' '}
                  {question.required && <span style={{ color: 'red' }}>*</span>}{' '}
                  ({question.points} point(s))
                  <br />
                  {question.responseType === responseTypes.TEXT.value && (
                    <TextField
                      multiline
                      onChange={(e) =>
                        answerQuestion(e, question, questionIndex)
                      }
                      disabled={areQuestionsDisabled}
                      placeholder={areQuestionsDisabled ? question.answer : ''}
                      variant="standard"
                      style={{
                        width: '100%',
                      }}
                    />
                  )}
                  {question.responseType ===
                    responseTypes.MULTIPLE_CHOICE.value &&
                    [...new Set(question.options)].map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <FormControlLabel
                          key={optionIndex}
                          disabled={areQuestionsDisabled}
                          control={
                            <Checkbox
                              value={option}
                              checked={
                                form.content[questionIndex]?.answer &&
                                form.content[questionIndex]?.answer.includes(
                                  option
                                )
                              }
                              id={`question-${questionIndex}-option-${optionIndex}`}
                              name="radio-buttons"
                              onChange={(e) =>
                                answerQuestion(e, question, questionIndex, true)
                              }
                            />
                          }
                        />
                        <label
                          htmlFor={`question-${questionIndex}-option-${optionIndex}`}
                        >
                          {option}
                        </label>
                        {areQuestionsDisabled &&
                        (option === question?.correctAnswer ||
                          question?.correctAnswer.includes(option) ||
                          question?.correct?.includes(option)) ? (
                          <Tooltip title="Bonne réponse">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              size="lg"
                              color="#66DEAE"
                              style={{ marginLeft: 20 }}
                            />
                          </Tooltip>
                        ) : null}

                        {areQuestionsDisabled &&
                        (option === question?.incorrect ||
                          question?.incorrect?.includes(option)) ? (
                          <Tooltip title="Mauvaise réponse">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              size="lg"
                              color="#fa7070"
                              style={{ marginLeft: 20 }}
                            />
                          </Tooltip>
                        ) : null}
                      </div>
                    ))}
                  {question.responseType === responseTypes.CHECKBOX.value && (
                    <FormControl>
                      { [...new Set(question.options)].map((option, optionIndex) => (
                        <div key={optionIndex}>
                          <FormControlLabel
                            key={optionIndex}
                            disabled={areQuestionsDisabled}
                            control={
                              <Radio
                                value={option}
                                checked={
                                  form.content[questionIndex]?.answer === option
                                }
                                id={`question-${questionIndex}-option-${optionIndex}`}
                                name={`question-${questionIndex}`}
                                onChange={(e) =>
                                  answerQuestion(
                                    e,
                                    question,
                                    questionIndex,
                                    false
                                  )
                                }
                              />
                            }
                          />
                          <label
                            htmlFor={`question-${questionIndex}-option-${optionIndex}`}
                          >
                            {option}
                          </label>
                          {areQuestionsDisabled &&
                          (option === question?.correctAnswer ||
                            question?.correctAnswer?.includes(option) ||
                            question?.correct?.includes(option)) ? (
                            <Tooltip title="Bonne réponse">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                size="lg"
                                color="#66DEAE"
                                style={{ marginLeft: 20 }}
                              />
                            </Tooltip>
                          ) : null}

                          {areQuestionsDisabled &&
                          (option === question?.incorrect ||
                            question?.incorrect?.includes(option)) ? (
                            <Tooltip title="Mauvaise réponse">
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="lg"
                                color="#fa7070"
                                style={{ marginLeft: 20 }}
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                      ))}
                    </FormControl>
                  )}
                  {question.responseType ===
                    responseTypes.LINEAR_SCALE.value && (
                    <div className="linear-wrapper">
                      <div className="form-range-labels">
                        {Array.from(
                          { length: question.linear_scale_size || 10 },
                          (_, i) => i + 1
                        ).map((num) => (
                          <span
                            key={num}
                            style={{
                              width: '30px',
                              textAlign: 'center',
                            }}
                          >
                            {num}
                          </span>
                        ))}
                      </div>
                      <ToggleButtonGroup
                        exclusive
                        value={question?.answer && +question.answer}
                        disabled={areQuestionsDisabled}
                        onChange={(e) =>
                          answerQuestion(e, question, questionIndex)
                        }
                        aria-label="linear scale"
                      >
                        {Array.from(
                          { length: question.linear_scale_size || 10 },
                          (_, i) => i + 1
                        ).map((num) => (
                          <ToggleButton key={num} value={num}>
                            {/* {num} */}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </form>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitBtnDisabled}
          sx={{
            display: 'inline-block',
            backgroundColor: '#66DEAE',
          }}
          onClick={submitForm}
        >
          Valider
        </Button>
      </Box>
    </Grid>
  );
};

export default FormToPass;
