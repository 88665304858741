import { Button } from '@mui/material';
import React from 'react';

const CreateButton = ({ handleOpen }) => {
  return (
    <Button variant="contained" color="primary" onClick={handleOpen}>
      AJOUTER
    </Button>
  );
};

export default CreateButton;
