import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from '../services/app.utils';
import { CreerService } from '../services/creeer';
import { BroadcastService } from '../services/broadcast';
import { LearnersService } from '../services/learners';
import { TagService } from '../services/tags';
import { EmailService } from '../services/emails';
import { FormsService } from '../services/forms';
import { BASE_URL } from '../constants/config';
import useStore from '../store/store';
import { ROLES } from '../constants/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyServices } from '../services/companies';
import { pathsToGetTags } from '../constants/paths';
import { formatDates } from '../helpers';
import { diffuserStatuses } from '../constants/broadcast';

const LearnerContext = createContext();

const LearnerProvider = ({ children }) => {
  const [selectedLearnerNames, setSelectedLearnerNames] = useState([]);
  const [data, setData] = useState([]);
  const [availableLearners, setAvailableLearners] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [formattedTags, setFormattedTags] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [oneCompanyStatistics, setOneCompanyStatistics] = useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem('authorization');

  const {
    selectedOrganization,
    userRole,
    setSnackbarContent,
    selectedOrganizationId,
    selectedCompanyId,
    setSelectedCompanyId,
    tempCompany,
    setTempCompany,
  } = useStore((state) => state);

  const isAdmin = userRole === ROLES.ADMIN;
  const location = useLocation();

  const changeSelectedForm = (form) => {
    localStorage.setItem('form', JSON.stringify(form));
  };

  const getSelectedForm = () => {
    const form = localStorage.getItem('form') || undefined;
    return form && form !== 'undefined' ? JSON.parse(form) : null;
  };

  // Refetch data if organization changed for admin
  const refreshData = async (newId) => {
    const routeLevels = location.pathname.split('/');
    const rootPath = '/' + routeLevels[1];

    if (location.pathname in pathsToRefresh) {
      const functionNames = pathsToRefresh[location.pathname];

      const locationName = pathsToGetTags[location.pathname] || null;
      navigate(`${location.pathname}`);

      if (functionNames) {
        functionNames.forEach((func, index) => {
          setTimeout(() => {
            func(newId, locationName);
          }, 500 * index);
        });
        setSnackbarContent(
          'success',
          'Vous avez réussi à modifier une organisation'
        );
      }
    } else if (rootPath in pathsToNavigateBack) {
      navigate(pathsToNavigateBack[rootPath]);
    }
  };

  // Update for learners number of participations in Broadcasts
  const calculateLearners = (broadcasts, learners) => {
    const updatedData = structuredClone(learners).map((learner) => ({
      ...learner,
      countOfParticipations: 0,
    }));

    broadcasts.forEach((broadcast) => {
      learners.forEach((learner, indexOfLearner) => {
        if (
          broadcast?.recipients.includes(learner._id) ||
          broadcast?.personal_registrations.includes(learner._id)
        ) {
          updatedData[indexOfLearner].countOfParticipations += 1;
        }
      });
    });

    return updatedData;
  };

  const updatedLearnersData = useMemo(
    () => calculateLearners(broadcasts, data),
    [broadcasts, data]
  );

  // Track if admin changed an organization
  useEffect(() => {
    if (organization.length) {
      const adminCompany = organization.find(
        (org) => org.name === selectedOrganization
      );

      if (adminCompany && adminCompany._id) {
        setSelectedCompanyId(adminCompany._id);
        setTempCompany(selectedOrganization);

        if (
          selectedOrganization &&
          tempCompany &&
          selectedOrganization !== tempCompany
        ) {
          refreshData(adminCompany._id);
        }
      }
    }
  }, [selectedOrganization, organization]);

  const getEmailData = async () => {
    const response = await EmailService.getAll();
    setEmailData(response.data.data);
  };

  const companiesData = async () => {
    const response = await axios.get(`${BASE_URL}api/companies`, {
      headers: {
        authorization: token,
      },
    });
    setOrganization(response.data.data);
  };

  const getBroadcasts = async (newId = null) => {
    const response = await BroadcastService.getAll();
    const broadcastsWithDates = response.data.data.map((br) => {
      if (br.trainingFormatted && br.trainingFormatted.length) {
        const dateOfCreation = br._id;

        const startDate = br.trainingFormatted[0].date;
        const endDate =
          br.trainingFormatted[br.trainingFormatted.length - 1].date;

        return { ...br, idTemp: br._id, startDate, endDate, dateOfCreation };
      }

      return { ...br, idTemp: br._id };
    }).filter(br => !br.deleted);

    if (isAdmin && !newId) {
      setBroadcasts(
        broadcastsWithDates.filter(
          (item) =>
            item.company_id === selectedCompanyId ||
            item.company_id === selectedOrganizationId
        )
      );
      return broadcastsWithDates.filter(
        (item) =>
          item.company_id === selectedCompanyId ||
          item.company_id === selectedOrganizationId
      );
    } else if (isAdmin && newId) {
      setBroadcasts(
        broadcastsWithDates.filter((item) => item.company_id === newId)
      );
      return broadcastsWithDates.filter((item) => item.company_id === newId);
    } else {
      setBroadcasts(broadcastsWithDates);
      return broadcastsWithDates;
    }
  };

  const getAllTags = async (newId = null, tagType) => {
    const response = await TagService.getAll(tagType);

    let tagsData;

    if (isAdmin && !newId) {
      tagsData = response.data.filter(
        (item) =>
          item.company_id === selectedCompanyId ||
          item.company_id === selectedOrganizationId
      );
    } else if (isAdmin && newId) {
      tagsData = response.data.filter((item) => item.company_id === newId);
    } else {
      tagsData = response.data;
    }

    const uniqueTags = [];
    const uniqueTagNames = new Set();

    tagsData.forEach((tag) => {
      if (!uniqueTagNames.has(tag.tagName)) {
        uniqueTagNames.add(tag.tagName);
        uniqueTags.push(tag);
      }
    });

    setFormattedTags(uniqueTags);
    setTags(uniqueTags);
  };

  const dataLearner = async (newId = null) => {
    const response = await LearnersService.getAll();
    setData(response.data);
    if (isAdmin && !newId) {
      setData(
        response.data.filter(
          (item) =>
            item.company_id === selectedCompanyId ||
            item.company_id === selectedOrganizationId ||
            item.companies.includes(selectedCompanyId) ||
            item.companies.includes(selectedOrganizationId)
        )
      );
    } else if (isAdmin && newId) {
      setData(
        response.data.filter(
          (item) => item.company_id === newId || item.companies.includes(newId)
        )
      );
    } else {
      setData(response.data);
    }
  };

  const getAvailableLearners = async (newId = null) => {
    const filter = true;
    const response = await LearnersService.getAll(filter);
    if (isAdmin && !newId) {
      setAvailableLearners(
        response.data.filter(
          (item) =>
            item.company_id === selectedCompanyId ||
            item.company_id === selectedOrganizationId ||
            item.companies.includes(selectedCompanyId) ||
            item.companies.includes(selectedOrganizationId)
        )
      );
    } else if (isAdmin && newId) {
      setAvailableLearners(
        response.data.filter(
          (item) => item.company_id === newId || item.companies.includes(newId)
        )
      );
    } else {
      setAvailableLearners(response.data);
    }
  };

  const getTrainings = async (newId = null) => {
    const response = await CreerService.getAll();
    if (isAdmin && !newId) {
      setTrainings(
        response.data.data.filter(
          (item) =>
            item.company_id === selectedCompanyId ||
            item.company_id === selectedOrganizationId
        )
      );
    } else if (isAdmin && newId) {
      setTrainings(
        response.data.data.filter((item) => item.company_id === newId)
      );
    } else {
      setTrainings(response.data.data);
    }
  };

  const getForms = async (newId = null) => {
    const response = await FormsService.getAll();

    if (isAdmin && !newId) {
      setAllForms(
        response.data.data.filter(
          (item) =>
            item.company_id === selectedCompanyId ||
            item.company_id === selectedOrganizationId
        )
      );
    } else if (isAdmin && newId) {
      setAllForms(
        response.data.data.filter((item) => item.company_id === newId)
      );
    } else {
      setAllForms(response.data.data);
    }
  };

  const exportStatistics = async (broadcast = null) => {
    await BroadcastService.exportStatistics(selectedCompanyId || selectedOrganizationId, broadcast);
  };

  const getOneCompanyStatistics = async () => {
    try {
      // Pass new selected company id OR users company id
      const response = await CompanyServices.getCompanyStatistics(
        selectedOrganizationId || selectedCompanyId
      );
      setOneCompanyStatistics(response.data.data);
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', "Impossible d'obtenir des statistiques");
    }
  };

  const getDetailedStatistics = async (id) => {
    try {
      const broadcast = await BroadcastService.getBroadcastById(id);
      const broadcastData = broadcast.data.data;

      const stats = await BroadcastService.postStatistics({
        broadcasts_id: [id],
      });
      const statsData = stats.data.data;

      const keys = Object.keys(statsData);

      if (keys.length) {
        const updatedBroadcasts = [broadcastData].map((broadcast) => {
          if (keys.includes(broadcast._id)) {
            return {
              ...broadcast,
              ...statsData[broadcast._id],
              type: broadcast.type,
            };
          }

          return {
            ...broadcast,
          };
        });

        return updatedBroadcasts;
      } else {
        return [broadcastData];
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getStatistics = async (newId) => {
    try {
      const newBroadcasts = await getBroadcasts(newId);
      const ids = {
        broadcasts_id: newBroadcasts.map((broadcast) => broadcast._id),
      };

      const response = await BroadcastService.postStatistics(ids);
      const result = response.data.data;

      const keys = Object.keys(result);

      if (keys.length) {
        const updatedBroadcasts = newBroadcasts.map((broadcast) => {
          if (
            keys.includes(broadcast._id) &&
            broadcast.status !== diffuserStatuses.DRAFT.value
          ) {
            return {
              ...broadcast,
              ...result[broadcast._id],
              type: broadcast.type,
            };
          }

          return {
            ...broadcast,
          };
        });

        setStatistics(updatedBroadcasts);
      } else {
        setStatistics(newBroadcasts);
      }
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', "Impossible d'obtenir des statistiques");
    }
  };

  // Functions to run, when admin changed company
  const pathsToRefresh = {
    '/create': [getTrainings, getAllTags],
    '/form': [getForms],
    '/diffuser': [getBroadcasts, getOneCompanyStatistics, getAllTags],
    '/evaluate': [getStatistics],
    '/learners': [dataLearner, getAllTags, getBroadcasts],
  };

  // Paths to navigate root page
  const pathsToNavigateBack = {
    '/evaluate-statistics': '/evaluate',
    '/evaluate-details': '/evaluate',
  };

  return (
    <LearnerContext.Provider
      value={{
        companiesData,
        organization,
        setOrganization,
        getForms,
        allForms,
        getEmailData,
        emailData,
        setEmailData,
        getBroadcasts,
        broadcasts,
        setBroadcasts,
        selectedLearnerNames,
        setSelectedLearnerNames,
        data,
        dataLearner,
        availableLearners,
        getAvailableLearners,
        tags,
        setTags,
        selectedTags,
        setSelectedTags,
        getAllTags,
        formattedTags,
        setData,
        getTrainings,
        trainings,
        updatedLearnersData,
        getStatistics,
        statistics,
        getDetailedStatistics,
        changeSelectedForm,
        getSelectedForm,
        exportStatistics,
        getOneCompanyStatistics,
        oneCompanyStatistics,
        setTrainings,
      }}
    >
      {children}
    </LearnerContext.Provider>
  );
};

export const useLearnerContext = () => useContext(LearnerContext);

export default LearnerProvider;
