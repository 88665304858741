import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  lengthOfInput,
  trainingResponseType,
  trainingWebAnswerTypes,
} from '../../../../constants/trainings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@mui/icons-material/Check';
import ResponseActions from '../ResponseActions';
import { useDropzone } from 'react-dropzone';
import EmojiePickerWrapper from '../EmojiePickerWrapper';
import ContentLength from '../ContentLength';
import useStore from '../../../../store/store';
import { LinkService } from '../../../../services/link';
import { FilesService } from '../../../../services/files';
import { fileError } from '../../../../constants/files';

const WebPageAdmin = ({
  submitWebAnswer,
  unselectWebAnswerDialog,
  disabled,
  webAnswer,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [answerType, setAnswerType] = useState(
    trainingWebAnswerTypes.TEXT.value
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [isMediaDialogOpen, setIsMediaDialogOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [openUrl, setOpenUrl] = useState(false);
  const [url, setUrl] = useState('');

  const emojieDescriptionRef = useRef(null);
  const emojieSussessResponseRef = useRef(null);
  const emojieFailResponseRef = useRef(null);
  const emojieInfoResponseRef = useRef(null);

  const descriptionRef = useRef(null);
  const successResponseRef = useRef(null);
  const failResponseRef = useRef(null);
  const infoResponseRef = useRef(null);

  const { setSnackbarContent, selectedOrganizationId } = useStore(
    (store) => store
  );

  const [asnwerData, setAnswerData] = useState({
    title: '',
    description: '',
    options: [],
    correctResponse: '',
    incorrectResponse: '',
    responseText: '',
    correctAnswers: [],
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSelectedFile(file);
      }
    },
  });

  const openMediaDialog = (type) => {
    setIsMediaDialogOpen((prev) => (prev ? false : type));
  };

  const toggleEmojiPicker = (type) => {
    setShowEmojiPicker((prev) => (prev ? false : type));
  };

  const closeDialog = () => {
    setIsMediaDialogOpen(false);
  };

  const handleOpenUrl = (type) => {
    setOpenUrl((prev) => (prev ? false : type));
    setUrl('');
  };

  const handleCloseUrl = () => {
    setOpenUrl(false);
  };

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const checkCharacterLimit = (type = trainingResponseType.webDescription) => {
    if (type === trainingResponseType.webDescription) {
      return asnwerData.description.length;
    }

    if (type === trainingResponseType.webCorrectReponse) {
      return asnwerData.correctResponse.length;
    }

    if (type === trainingResponseType.webIncorrectResponse) {
      return asnwerData.incorrectResponse.length;
    }

    if (type === trainingResponseType.webInfoResponse) {
      return asnwerData.responseText.length;
    }
  };

  const getSelectedRef = (refToChange) => {
    return refToChange === trainingResponseType.webInfoResponse
      ? infoResponseRef
      : refToChange === trainingResponseType.webCorrectReponse
      ? successResponseRef
      : refToChange === trainingResponseType.webIncorrectResponse
      ? failResponseRef
      : descriptionRef;
  };

  const focusTextField = (refToChange) => {
    if (refToChange && trainingResponseType?.[refToChange]) {
      const selectedRef = getSelectedRef(refToChange);

      setTimeout(() => {
        selectedRef.current.focus();
        selectedRef.current?.setSelectionRange(
          selectedRef.current.value.length,
          selectedRef.current.value.length
        );
      }, 500);
      return;
    }

    setTimeout(() => {
      descriptionRef.current.focus();
      descriptionRef.current?.setSelectionRange(
        descriptionRef.current.value.length,
        descriptionRef.current.value.length
      );
    }, 500);
  };

  const handleSubmitUrl = async (
    responseActionType = trainingResponseType.none
  ) => {
    const dataLink = {
      source: url,
    };
    const response = await LinkService.shortenLink(dataLink);
    let shortenLink = response.data.link;

    const characterLength = checkCharacterLimit(responseActionType);
    if (characterLength + shortenLink.length > lengthOfInput) {
      setSnackbarContent('error', "Il n'y a pas d'espace vide");
      handleCloseUrl();
      focusTextField(responseActionType);
      return;
    }

    const selectedRef = getSelectedRef(responseActionType);

    if (selectedRef.current) {
      const input = selectedRef.current;
      const cursorPosition = input.selectionStart;
      const selectedContent = getSelectedContent(responseActionType);
      const currentValue = selectedContent;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        ' ' +
        shortenLink +
        ' ' +
        currentValue.substring(cursorPosition);

      setTimeout(() => {
        input.focus();
        const newCursorPosition = cursorPosition + shortenLink.length + 2;
        input?.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 500);

      changeContent(responseActionType, newValue);
    } else {
      const selectedContent = getSelectedContent(responseActionType);
      changeContent(responseActionType, selectedContent + shortenLink);
      focusTextField(responseActionType, responseActionType);
    }

    handleCloseUrl();
  };

  const handleFileUpload = async (
    responseActionType = trainingResponseType.none
  ) => {
    if (selectedFile) {
      try {
        setSnackbarContent(
          'info',
          'Le téléchargement des médias peut prendre un certain temps'
        );
        const response = await FilesService.uploadFile(
          selectedFile,
          1,
          null,
          selectedOrganizationId
        );

        setSnackbarContent('success', 'Fichier téléchargé avec succès');

        let link = response.link;
        // if (window?.location?.host.includes('localhost')) {
        //   link = link.replace(/[^/]+/, 'localhost:3001');
        // }

        const characterLength = checkCharacterLimit(responseActionType);
        if (characterLength + link.length > lengthOfInput) {
          setSnackbarContent('error', "Il n'y a pas d'espace vide");
          closeDialog();
          setSelectedFile(null);
          focusTextField(responseActionType);
          return;
        }

        const selectedRef = getSelectedRef(responseActionType);

        if (selectedRef.current) {
          const input = selectedRef.current;
          const cursorPosition = input.selectionStart;
          const selectedContent = getSelectedContent(responseActionType);
          const currentValue = selectedContent;
          const newValue =
            currentValue.substring(0, cursorPosition) +
            ' ' +
            link +
            ' ' +
            currentValue.substring(cursorPosition);

          changeContent(responseActionType, newValue);
          setTimeout(() => {
            input.focus();
            const newCursorPosition = cursorPosition + link.length + 2;
            input?.setSelectionRange(newCursorPosition, newCursorPosition);
          }, 500);
        } else {
          const selectedContent = getSelectedContent(responseActionType);
          changeContent(responseActionType, selectedContent + link);
          focusTextField(responseActionType);
        }

        closeDialog();
        setSelectedFile(null);
      } catch (error) {
        console.error(error);
        setSelectedFile(null);
        setSnackbarContent(
          'error',
          fileError[error]
            ? fileError[error]
            : 'Erreur lors du téléchargement du fichier.'
        );
      }
    }
  };

  const getSelectedContent = (type) => {
    if (type === trainingResponseType.webDescription) {
      return asnwerData.description;
    }
    if (type === trainingResponseType.webCorrectReponse) {
      return asnwerData.correctResponse;
    }
    if (type === trainingResponseType.webIncorrectResponse) {
      return asnwerData.incorrectResponse;
    }
    if (type === trainingResponseType.webInfoResponse) {
      return asnwerData.responseText;
    }

    return asnwerData.description;
  };

  const changeContent = (type, value) => {
    if (type === trainingResponseType.webDescription) {
      setAnswerData((prev) => ({ ...prev, description: value }));
      return;
    }
    if (type === trainingResponseType.webCorrectReponse) {
      setAnswerData((prev) => ({ ...prev, correctResponse: value }));
      return;
    }
    if (type === trainingResponseType.webIncorrectResponse) {
      setAnswerData((prev) => ({ ...prev, incorrectResponse: value }));
      return;
    }

    if (type === trainingResponseType.webInfoResponse) {
      setAnswerData((prev) => ({ ...prev, responseText: value }));
      return;
    }

    setAnswerData((prev) => ({ ...prev, description: value }));
  };

  const handleEmojiSelect = (
    emoji,
    responseActionType = trainingResponseType.webDescription
  ) => {
    const characterLength = checkCharacterLimit(responseActionType);
    if (characterLength + 1 > lengthOfInput) {
      setSnackbarContent('error', "Il n'y a pas d'espace vide");
      setShowEmojiPicker(false);
      focusTextField(responseActionType);
      return;
    }

    const selectedRef = getSelectedRef(responseActionType);

    if (selectedRef.current) {
      const input = selectedRef.current;
      const cursorPosition = input.selectionStart;
      const selectedContent = getSelectedContent(responseActionType);
      const currentValue = selectedContent;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        emoji.emoji +
        currentValue.substring(cursorPosition);

      changeContent(responseActionType, newValue);
      setShowEmojiPicker(false);

      setTimeout(() => {
        input.focus();
        const newCursorPosition = cursorPosition + 2;
        input?.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 500);
    } else {
      const selectedContent = getSelectedContent(responseActionType);
      changeContent(responseActionType, selectedContent + emoji.emoji);
      setShowEmojiPicker(false);
      focusTextField(responseActionType);
    }
  };

  const openWebAnswerDialog = () => {
    setOpenDialog(true);
    getAnswerData();
  };

  const closeWebAnswerDialog = () => {
    setOpenDialog(false);
    setAnswerData({
      title: '',
      description: '',
      options: [],
      correctResponse: '',
      incorrectResponse: '',
      responseText: '',
    });
    setAnswerType(trainingWebAnswerTypes.TEXT.value);
  };

  const handleUnselectWebAnswerDialog = () => {
    setOpenDialog(false);
    setAnswerData({
      title: '',
      description: '',
      options: [],
      correctResponse: '',
      incorrectResponse: '',
      responseText: '',
    });
    setAnswerType(trainingWebAnswerTypes.TEXT.value);
    unselectWebAnswerDialog();
  };

  const handleSubmitWebAnswer = async () => {
    setOpenDialog(false);
    const webAnswerPayload = { ...asnwerData, type: answerType };
    await submitWebAnswer(webAnswerPayload);
  };

  const handleAddOption = () => {
    setAnswerData((prev) => {
      const prevOptions = prev.options;
      prevOptions.push({ value: '', isCorrect: false });

      return {
        ...prev,
        options: prevOptions,
      };
    });
  };

  const handleOptionChange = (index, value) => {
    setAnswerData((prev) => {
      const options = [...prev.options];
      options[index].value = value;

      return {
        ...prev,
        options: options,
      };
    });
  };

  const handleDeleteOption = (optionValue, optionIndex) => {
    setAnswerData((prev) => {
      const options = [...prev.options].filter((_, index) => {
        return index !== optionIndex;
      });

      return {
        ...prev,
        options: options,
      };
    });
  };

  const changeCorrectAnswer = (optionValue, optionIndex) => {
    setAnswerData((prev) => {
      prev.options[optionIndex].isCorrect =
        !prev.options[optionIndex].isCorrect;

      return {
        ...prev,
      };
    });
  };

  const getAnswerData = () => {
    if (webAnswer) {
      const newOptions = webAnswer?.options?.map((option) => {
        return {
          value: option,
          isCorrect: webAnswer?.correctAnswers?.includes(option),
        };
      });
      setAnswerData({ ...webAnswer, options: newOptions });
      setAnswerType(webAnswer.type);
      return;
    }

    return setAnswerData({
      title: '',
      description: '',
      options: [],
      correctResponse: '',
      incorrectResponse: '',
      responseText: '',
    });
  };

  const submitBtnDisabled =
    answerType === trainingWebAnswerTypes.MULTIPLE_CHOICE.value ||
    answerType === trainingWebAnswerTypes.CHECKBOX.value
      ? !(
          asnwerData.title &&
          asnwerData.description &&
          asnwerData.description.length <= lengthOfInput &&
          asnwerData.options.length &&
          asnwerData.options.every(
            (option) => option.value.trim().length > 0
          ) &&
          asnwerData.correctResponse.length &&
          asnwerData.incorrectResponse.length &&
          asnwerData.correctResponse.length <= lengthOfInput &&
          asnwerData.incorrectResponse.length <= lengthOfInput
        )
      : !(
          asnwerData.title &&
          asnwerData.description &&
          asnwerData.description.length <= lengthOfInput &&
          asnwerData.responseText.length &&
          asnwerData.responseText.length <= lengthOfInput
        );

  useEffect(() => {
    function handleClickOutside(event) {
      const isTargetHasEmojieWrapper = event.target.closest(
        '.emojie-picker-wrapper'
      );
      if (isTargetHasEmojieWrapper) {
        return;
      }

      const isParensEmojieWrapper = event.target
        .closest('.emojie-picker-btn')
        ?.classList.contains('emojie-picker-btn');
      if (emojieDescriptionRef.current && !isParensEmojieWrapper) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={openWebAnswerDialog}
        disabled={disabled}
        sx={{
          width: '30px',
          minHeight: '30px',
          background: '#66DEAE',
          borderRadius: '50%',
          color: '#fff',
          marginLeft: '10px',
          minWidth: 'unset',
        }}
        size="small"
      >
        <i
          class="fa-solid fa-message"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '22px',
            height: '22px',
            color: '#fff',
          }}
        ></i>
      </Button>

      <Dialog
        open={openDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 24px',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#4546CD', fontWeight: 'bold' }}
            >
              Réponse Web
            </Typography>
          </Box>
          <DialogContent>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginBottom: 3,
                }}
                autoComplete="off"
              >
                <TextField
                  id="answer-title"
                  label="Titre"
                  value={asnwerData.title}
                  onChange={(e) =>
                    setAnswerData((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  variant="outlined"
                />
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      label="Description"
                      value={asnwerData.description}
                      inputRef={descriptionRef}
                      onChange={(e) =>
                        setAnswerData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      multiline
                      minRows={5}
                      fullWidth
                      error={asnwerData.description.length > lengthOfInput}
                    />
                    <ResponseActions
                      responseActionType={trainingResponseType.webDescription}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      openDialog={openMediaDialog}
                      toggleEmojiPicker={toggleEmojiPicker}
                      handleOpenUrl={handleOpenUrl}
                      openUrl={openUrl}
                      handleInputChange={handleInputChange}
                      url={url}
                      handleSubmitUrl={handleSubmitUrl}
                      handleCloseUrl={handleCloseUrl}
                      isDialogOpen={isMediaDialogOpen}
                      selectedFile={selectedFile}
                      closeDialog={closeDialog}
                      handleFileUpload={handleFileUpload}
                    />
                    {showEmojiPicker ===
                      trainingResponseType.webDescription && (
                      <EmojiePickerWrapper
                        responseActionType={trainingResponseType.webDescription}
                        emojiPickerRef={emojieDescriptionRef}
                        handleEmojiSelect={handleEmojiSelect}
                      />
                    )}
                  </Box>
                  <ContentLength
                    characterCount={checkCharacterLimit(
                      trainingResponseType.webDescription
                    )}
                    spacing
                    margin="5px 50px 0 0"
                  />
                </Box>

                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="web-answer-select">
                    Type de réponse
                  </InputLabel>
                  <Select
                    label="Type de réponse"
                    labelId="web-answer-select"
                    value={answerType}
                    onChange={(e) => {
                      setAnswerType(e.target.value);
                    }}
                  >
                    {Object.entries(trainingWebAnswerTypes).map(
                      (answerType) => (
                        <MenuItem
                          key={answerType[1].value}
                          value={answerType[1].value}
                        >
                          {answerType[1].label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                {(answerType === trainingWebAnswerTypes.MULTIPLE_CHOICE.value ||
                  answerType === trainingWebAnswerTypes.CHECKBOX.value) && (
                  <Box>
                    {asnwerData.options.map((option, optionIndex) => {
                      return (
                        <div className="option-container">
                          <Tooltip title="Bonne réponse">
                            <ToggleButton
                              value="check"
                              selected={
                                asnwerData.options[optionIndex].isCorrect
                              }
                              onChange={() => {
                                changeCorrectAnswer(option, optionIndex);
                              }}
                            >
                              <CheckIcon />
                            </ToggleButton>
                          </Tooltip>
                          {answerType ===
                            trainingWebAnswerTypes.CHECKBOX.value && (
                            <input type="checkbox" disabled />
                          )}
                          {answerType ===
                            trainingWebAnswerTypes.MULTIPLE_CHOICE.value && (
                            <input type="radio" disabled />
                          )}
                          <TextField
                            label={`Option`}
                            sx={{
                              '& input': {
                                height: 50,
                              },
                            }}
                            error={!option.value.trim().length}
                            value={option.value}
                            multiline
                            className="fix-label"
                            onChange={(e) =>
                              handleOptionChange(optionIndex, e.target.value)
                            }
                          />
                          <button
                            className="delete-option"
                            style={{
                              marginLeft: '15px',
                            }}
                            onClick={() =>
                              handleDeleteOption(option, optionIndex)
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      );
                    })}
                  </Box>
                )}

                {(answerType === trainingWebAnswerTypes.MULTIPLE_CHOICE.value ||
                  answerType === trainingWebAnswerTypes.CHECKBOX.value) && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                      display: 'block',
                      backgroundColor: '#66DEAE',
                      margin: '0 auto 15px auto',
                    }}
                    className="add-option"
                    onClick={() => handleAddOption()}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Ajouter une option
                  </Button>
                )}

                {(answerType === trainingWebAnswerTypes.MULTIPLE_CHOICE.value ||
                  answerType === trainingWebAnswerTypes.CHECKBOX.value) && (
                  <>
                    <Box>
                      <Box sx={{ display: 'flex' }}>
                        {' '}
                        <TextField
                          label="Texte si réponse correcte"
                          value={asnwerData.correctResponse}
                          onChange={(e) =>
                            setAnswerData((prev) => ({
                              ...prev,
                              correctResponse: e.target.value,
                            }))
                          }
                          multiline
                          fullWidth
                          minRows={5}
                          inputRef={successResponseRef}
                          error={
                            asnwerData.correctResponse.length > lengthOfInput
                          }
                        />{' '}
                        <ResponseActions
                          responseActionType={
                            trainingResponseType.webCorrectReponse
                          }
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          openDialog={openMediaDialog}
                          toggleEmojiPicker={toggleEmojiPicker}
                          handleOpenUrl={handleOpenUrl}
                          openUrl={openUrl}
                          handleInputChange={handleInputChange}
                          url={url}
                          handleSubmitUrl={handleSubmitUrl}
                          handleCloseUrl={handleCloseUrl}
                          isDialogOpen={isMediaDialogOpen}
                          selectedFile={selectedFile}
                          closeDialog={closeDialog}
                          handleFileUpload={handleFileUpload}
                        />
                        {showEmojiPicker ===
                          trainingResponseType.webCorrectReponse && (
                          <EmojiePickerWrapper
                            responseActionType={
                              trainingResponseType.webCorrectReponse
                            }
                            emojiPickerRef={emojieSussessResponseRef}
                            handleEmojiSelect={handleEmojiSelect}
                          />
                        )}
                      </Box>
                      <ContentLength
                        characterCount={checkCharacterLimit(
                          trainingResponseType.webCorrectReponse
                        )}
                        spacing
                        margin="5px 50px 0 0"
                      />
                    </Box>

                    <Box>
                      <Box sx={{ display: 'flex' }}>
                        <TextField
                          label="Texte si réponse eronnée"
                          value={asnwerData.incorrectResponse}
                          onChange={(e) =>
                            setAnswerData((prev) => ({
                              ...prev,
                              incorrectResponse: e.target.value,
                            }))
                          }
                          multiline
                          fullWidth
                          minRows={5}
                          inputRef={failResponseRef}
                          error={
                            asnwerData.incorrectResponse.length > lengthOfInput
                          }
                        />
                        <ResponseActions
                          responseActionType={
                            trainingResponseType.webIncorrectResponse
                          }
                          getRootProps={getRootProps}
                          getInputProps={getInputProps}
                          openDialog={openMediaDialog}
                          toggleEmojiPicker={toggleEmojiPicker}
                          handleOpenUrl={handleOpenUrl}
                          openUrl={openUrl}
                          handleInputChange={handleInputChange}
                          url={url}
                          handleSubmitUrl={handleSubmitUrl}
                          handleCloseUrl={handleCloseUrl}
                          isDialogOpen={isMediaDialogOpen}
                          selectedFile={selectedFile}
                          closeDialog={closeDialog}
                          handleFileUpload={handleFileUpload}
                        />
                        {showEmojiPicker ===
                          trainingResponseType.webIncorrectResponse && (
                          <EmojiePickerWrapper
                            responseActionType={
                              trainingResponseType.webIncorrectResponse
                            }
                            emojiPickerRef={emojieFailResponseRef}
                            handleEmojiSelect={handleEmojiSelect}
                          />
                        )}
                      </Box>
                      <ContentLength
                        characterCount={checkCharacterLimit(
                          trainingResponseType.webIncorrectResponse
                        )}
                        spacing
                        margin="5px 50px 0 0"
                      />
                    </Box>
                  </>
                )}

                {answerType === trainingWebAnswerTypes.TEXT.value && (
                  <Box>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        label="Texte à afficher une fois la réponse de l'apprenant partagée"
                        value={asnwerData.responseText}
                        onChange={(e) =>
                          setAnswerData((prev) => ({
                            ...prev,
                            responseText: e.target.value,
                          }))
                        }
                        multiline
                        fullWidth
                        minRows={5}
                        inputRef={infoResponseRef}
                        error={asnwerData.responseText.length > lengthOfInput}
                      />
                      <ResponseActions
                        responseActionType={
                          trainingResponseType.webInfoResponse
                        }
                        getRootProps={getRootProps}
                        getInputProps={getInputProps}
                        openDialog={openMediaDialog}
                        toggleEmojiPicker={toggleEmojiPicker}
                        handleOpenUrl={handleOpenUrl}
                        openUrl={openUrl}
                        handleInputChange={handleInputChange}
                        url={url}
                        handleSubmitUrl={handleSubmitUrl}
                        handleCloseUrl={handleCloseUrl}
                        isDialogOpen={isMediaDialogOpen}
                        selectedFile={selectedFile}
                        closeDialog={closeDialog}
                        handleFileUpload={handleFileUpload}
                      />
                      {showEmojiPicker ===
                        trainingResponseType.webInfoResponse && (
                        <EmojiePickerWrapper
                          responseActionType={
                            trainingResponseType.webInfoResponse
                          }
                          emojiPickerRef={emojieInfoResponseRef}
                          handleEmojiSelect={handleEmojiSelect}
                        />
                      )}
                    </Box>
                    <ContentLength
                      characterCount={checkCharacterLimit(
                        trainingResponseType.webInfoResponse
                      )}
                      spacing
                      margin="5px 50px 0 0"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className="dialog-actions-form">
            <Button
              variant="outlined"
              color="error"
              onClick={closeWebAnswerDialog}
            >
              Annuler
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={handleUnselectWebAnswerDialog}
            >
              Supprimer
            </Button>

            <Button
              disabled={submitBtnDisabled}
              variant="contained"
              color="primary"
              sx={{
                display: 'block',
                backgroundColor: '#66DEAE',
              }}
              onClick={handleSubmitWebAnswer}
            >
              Enregistrer
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default WebPageAdmin;
