import { SvgIcon, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

const Tags = ({
    options,
    value,
    handleTagChange,
    setNewTag,
    handleAddTag,
    openConfirmDeleteTag,
    inputMinWidth = '300px',
    inputMaxWidth = null,
}) => {
  const filter = createFilterOptions();

  return (
    <Autocomplete
      freeSolo
      clearOnBlur
      disableClearable
      options={options.map((tag) => tag.tagName)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          filtered.push(`"Ajouter" ${params.inputValue}`);
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.tagName;
      }}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          style={{
            minWidth: inputMinWidth,
            maxWidth: inputMaxWidth ? inputMaxWidth : '500px',
            fontSize: '12px',
            border: 'none',
            outline: 'none',
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
      value={value.tags}
      onChange={(event, newValue) => {
        const clickedElement = event.target;
        let lenghtToCut = 10;

        if (event.code === 'Backspace' || event.key === 'Backspace') {
          return;
        }

        if (event.code === 'Enter' || event.key === 'Enter') {
          lenghtToCut = 0;
        }

        if (
          clickedElement instanceof SVGElement ||
          clickedElement.nodeName.toLowerCase() === 'path'
        ) {
          return handleTagChange({
            event: newValue,
            id: value._id,
          });
        }

        if (newValue && newValue.length > 0) {
          const newTagValue = newValue[newValue.length - 1];
          const existingTags = options.map((tag) =>
            tag.tagName.toLowerCase()
          );

          if (!existingTags.includes(newTagValue.toLowerCase())) {
            setNewTag(newTagValue.slice(lenghtToCut));
            handleAddTag(newTagValue.slice(lenghtToCut));
            handleTagChange({
              event: [...value.tags, newTagValue.slice(lenghtToCut)],
              id: value._id,
            });
          } else {
            handleTagChange({ event: newValue, id: value._id });
          }
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span>{option}</span>
              {typeof option === 'string' && (
                <span
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation(); // prevent closing the dropdown
                    openConfirmDeleteTag(option);
                  }}
                >
                  <SvgIcon component={CloseIcon} />
                </span>
              )}
            </div>
          </li>
        );
      }}
      multiple
      filterSelectedOptions={false}
    />
  );
};

export default Tags;
