import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLearnerContext } from '../../../context/LearnerContext';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { LearnersService } from '../../../services/learners';
import DialogTitleCustom from '../../../components/DialogTitleCustom';
import useSortableTable from '../../../hooks/useSortTable';

function EditSelectRecipients({
  broadcastId,
  initialSelectedLearners,
  onlyPreview = false,
  disabled,
  personalBroadcasts = [],
  setPersonalBroadcasts,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedLearners(initialSelectedLearners);
    setOpen(false);
  };
  const [searchQuery, setSearchQuery] = useState('');

  const { data, dataLearner, setSelectedLearnerNames } = useLearnerContext();
  const [filteredLearners, setFilteredLearnsers] = useState([]);
  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredLearners,
    {
      key: 'firstName',
      direction: 'asc',
    }
  );

  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [tempPersonalBroadcasts, setTempPersonalBroadcasts] = useState([]);
  const [usersToUnsubscribe, setUsersToUnsubscribe] = useState([]);

  useEffect(() => {
    const searchTermLowerCase = searchQuery.toLowerCase();

    const filteredTable = data.filter((learner) => {
      const firstNameIncludesSearchTerm = learner.firstName
        .toLowerCase()
        .includes(searchTermLowerCase);

      const lastNameIncludesSearchTerm = learner.lastName
        .toLowerCase()
        .includes(searchTermLowerCase);

      const isTagsIncludesSearchTerm = learner.tags.some((tag) =>
        tag.toLowerCase().includes(searchTermLowerCase)
      );

      const isPhonecludesSearchTerm = learner.phoneNumber
        .toLowerCase()
        .includes(searchTermLowerCase);

      return (
        firstNameIncludesSearchTerm ||
        lastNameIncludesSearchTerm ||
        isTagsIncludesSearchTerm || 
        isPhonecludesSearchTerm
      );
    });
    setFilteredLearnsers(filteredTable);
  }, [data, searchQuery]);

  const handleConfirm = async () => {
    const learnerNames = data
      .filter((learner) => selectedLearners.includes(learner._id))
      .map((learner) => learner._id);

    setSelectedLearnerNames(learnerNames);

    if (usersToUnsubscribe && usersToUnsubscribe.length) {
      const data = {
        broadcast_id: broadcastId,
        learner_id_list: usersToUnsubscribe,
      };

      setPersonalBroadcasts(tempPersonalBroadcasts);
      try {
        await LearnersService.unSubscribeLearners(data);
      } catch (e) {
        console.log(e);
      }
    }

    setSelectedCount(learnerNames.length + tempPersonalBroadcasts?.length);
    setTempPersonalBroadcasts([]);
    setUsersToUnsubscribe([]);
    dataLearner();
    handleClose();
  };

  const handleSelectionChange = (learnerId, isSelected) => {
    const isPersonal = personalBroadcasts.includes(learnerId);

    if (isPersonal && isSelected) {
      setSelectedCount((prevCount) => prevCount + 1);
      setUsersToUnsubscribe((prev) => prev.filter((id) => id !== learnerId));
      setTempPersonalBroadcasts((prev) => [...prev, learnerId]);
    } else if (isPersonal && !isSelected) {
      setSelectedCount((prevCount) => prevCount - 1);
      setUsersToUnsubscribe((prev) => [...prev, learnerId]);
      setTempPersonalBroadcasts((prev) =>
        prev.filter((id) => id !== learnerId)
      );
    } else if (isSelected && !isPersonal) {
      setSelectedLearners((prevSelected) => [...prevSelected, learnerId]);
      setSelectedCount((prevCount) => prevCount + 1);
    } else if (!isSelected && isPersonal === false) {
      setSelectedLearners((prevSelected) =>
        prevSelected.filter((id) => id !== learnerId)
      );
      setSelectedCount((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    if (open) {
      setTempPersonalBroadcasts(personalBroadcasts);
      setSelectedCount(
        initialSelectedLearners.length + personalBroadcasts.length
      );
    }
  }, [open, personalBroadcasts]);

  useEffect(() => {
    setSelectedLearners(initialSelectedLearners);
    setSelectedCount(initialSelectedLearners.length);
  }, [initialSelectedLearners, initialSelectedLearners.length]);

  useEffect(() => {
    dataLearner();
    setSelectedLearners(initialSelectedLearners);
    setSelectedCount(initialSelectedLearners.length);
  }, []);

  const selectAllLearners = () => {
    const allSelectedLearners = filteredLearners.map((learner) => learner._id);
    const uniqueLearners = [
      ...new Set([...allSelectedLearners, ...selectedLearners]),
    ];

    setSelectedLearners(uniqueLearners);
    setSelectedCount(uniqueLearners.length);
  };
  return (
    <>
      <Button
        sx={{ paddingLeft: 0, color: '#4546CD' }}
        onClick={handleOpen}
        disabled={disabled}
      >
        <Typography variant="h7">Sélectionner les destinataires</Typography>
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        scroll={'paper'}
        aria-labelledby="select-recipient"
        aria-describedby="dialog-select-recipient"
      >
        <DialogTitleCustom id="select-recipient-title" fontSize="20px">
          Sélectionner les destinataires
        </DialogTitleCustom>
        <DialogContent>
          <Typography variant="body2">
            Nombre de destinataire(s) du parcours : {selectedCount}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <TextField
              label="Recherche"
              autoFocus
              autoComplete="off"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Button
              sx={{ flexShrink: 0, color: '#4546CD' }}
              onClick={selectAllLearners}
            >
              Tout sélectionner
            </Button>
          </Box>
          {sortedData.length ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sélection</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'lastName'}
                        direction={
                          sortConfig.key === 'lastName'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('lastName')}
                      >
                        Nom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'firstName'}
                        direction={
                          sortConfig.key === 'firstName'
                            ? sortConfig.direction
                            : 'asc'
                        }
                        onClick={() => handleSort('firstName')}
                      >
                        Prénom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Tags</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((learner) => (
                    <TableRow key={learner._id}>
                      <TableCell>
                        <Checkbox
                          checked={
                            selectedLearners.includes(learner._id) ||
                            tempPersonalBroadcasts.includes(learner._id)
                          }
                          disabled={onlyPreview}
                          onChange={(e) =>
                            handleSelectionChange(learner._id, e.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell>{learner.lastName}</TableCell>
                      <TableCell>{learner.firstName}</TableCell>
                      <TableCell>{learner.phoneNumber}</TableCell>

                      <TableCell>{learner.tags.join(', ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box>
              <Typography
                variant="body1"
                style={{ padding: '16px 16px 0 16px', textAlign: 'center' }}
              >
                Aucun apprenant enregistré.
              </Typography>
              <Typography
                variant="body1"
                style={{ padding: '0 16px 16px 16px', textAlign: 'center' }}
              >
                Veuillez en ajouter un nouveau.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            Annuler
          </Button>

          {onlyPreview ? null : (
            <Button onClick={handleConfirm} variant="contained" color="primary">
              Valider
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditSelectRecipients;
