import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const DeleteTraining = ({
  deleteConfirmationOpen,
  handleCloseDeleteConfirmation,
  confirmDeleteTraining,
}) => {
  return (
    <Dialog open={deleteConfirmationOpen}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Êtes-vous sûr de vouloir supprimer cette formation ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={handleCloseDeleteConfirmation}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={confirmDeleteTraining}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTraining;
