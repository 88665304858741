import { Button, Typography } from '@mui/material';
import React from 'react';

const AddDayIntoTable = ({
  AddRow,
  disabled,
  withBorder = false,
  withIcon = true,
}) => {
  return (
    <Button
      onClick={AddRow}
      disabled={disabled}
      sx={{
        color: '#4546CD',
        paddingLeft: withIcon ? 0 : 1,
        marginTop: withBorder ? 0 : 1,
      }}
      variant={withBorder ? 'contained' : 'text'}
    >
      {withIcon && <i className="fa-solid fa-plus"></i>}
      <Typography className="gap" sx={{ display: 'flex', gap: 1 }} variant="h7">
        Ajouter un Jour
      </Typography>
    </Button>
  );
};

export default AddDayIntoTable;
