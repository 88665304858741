import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material';
import { ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { LearnersService } from '../../services/learners';
import DropFile from './DropFile';
import UsersTable from './UsersTable';

const BulkInvite = ({ refetch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isDropFileOpen, setIsDropFileOpen] = useState(false);

  const MenuElements = [
    {
      name: 'Obtenir un modèle',
      info: 'Get template',
      action: 'get',
    },
    {
      name: 'Ajouter',
      info: 'Invite learners',
      action: 'invite',
    },
  ];
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getTemplate = async () => {
    const res = await LearnersService.getTemplate();
    if (res.status !== 200) {
      console.log('Error by downloading template');
      return;
    }

    const mimeType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    const blob = res.data;

    const newBlob = new Blob([blob], { type: mimeType });
    const objectUrl = window.URL.createObjectURL(newBlob);

    const a = document.createElement('a');
    a.href = objectUrl;
    a.download = 'Anemon - Modèle Liste Bénéficiaires.xlsx';
    a.click();
    URL.revokeObjectURL(objectUrl);
  };

  const showFilePicker = async () => {
    setIsDropFileOpen(true);
  };
  const closeDropFile = () => {
    setIsDropFileOpen(false);
  };

  const clickOnItem = (e) => {
    if (e.action === 'get') {
      getTemplate();
    }

    if (e.action === 'invite') {
      showFilePicker();
    }
    setAnchorEl(null);
  };

  const [userData, setUserData] = useState([]);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);

  const handleEdit = (editedData) => {
    const newData = userData.map((user, index) => {
      if (editedData[index]) {
        return editedData[index];
      }
      return user;
    });
    setUserData(newData);
  };

  const handleDelete = (index) => {
    const newData = [...userData];
    newData.splice(index, 1);
    setUserData(newData);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={openMenu}>
        Ajout groupé
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {MenuElements.map((e) => (
          <MenuItem key={e.name} onClick={() => clickOnItem(e)}>
            <ListItemText>{e.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        open={isDropFileOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <DropFile
            setUserData={setUserData}
            closeDropFile={closeDropFile}
            setIsInviteDialogOpen={setIsInviteDialogOpen}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDropFile} variant="outlined" color="error">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <UsersTable
        data={userData}
        open={isInviteDialogOpen}
        onClose={() => setIsInviteDialogOpen(false)}
        refetch={refetch}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </>
  );
};

export default BulkInvite;
