import { BASE_URL } from '../constants/config';
import axios from './app.utils';

export const FILES_ENDPOINTS = {
  upload: `${BASE_URL}api/files/upload`,
  download: `${BASE_URL}api/files/download`,
};

const token = localStorage.getItem('authorization');

class Files {
  async downloadFile(identifier) {
    const response = await axios.get(
      `${FILES_ENDPOINTS.download}/${identifier}`,
      {
        headers: {
          authorization: token,
        },
        responseType: 'blob',
      }
    );
    return response;
  }

  uploadFile(file, fileId = 1, onProgress = null, company_id = null) {
    return new Promise((resolve, reject) => {
      const uploadedFilename = file.name;
      const formData = new FormData();
      // Files bigger then 100MB aren't supported with current nginx config
      if (file.size > 100_000_000) {
        return reject('File is too big');
      }
      formData.append('file', file, uploadedFilename);
      formData.append('file_id', fileId);
      if (company_id) formData.append('company_id', company_id)
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4) {
          onProgress && onProgress(100);
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            try {
              const response = JSON.parse(xhr.responseText);
              reject(
                `Error occurred during upload. ${response?.detail?.message}.`
              );
            } catch {
              reject('Error occurred during upload.');
            }
          }
        }
      };

      xhr.onprogress = function (e) {
        if (e.lengthComputable) {
          onProgress && onProgress((e.loaded / e.total) * 100);
        }
      };
      onProgress && onProgress(0);

      xhr.open('post', FILES_ENDPOINTS.upload);
      xhr.setRequestHeader('authorization', token);

      xhr.send(formData);
    });
  }
}

export const FilesService = new Files();
