import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Container, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CreerService } from '../../services/creeer';
import TypographyUI from '../../components/TypographyUI';
import { dynamicUrlPattern } from '../../constants/trainings';
import useStore from '../../store/store';
import { downloadImageBase64 } from '../../helpers/company';
import { CompanyServices } from '../../services/companies';

const MicroTrainingsAnswer = () => {
  const [answer, setAnswer] = useState('');
  const { identifier } = useParams();
  const [training, setTraining] = useState([]);
  const requiredFields = [answer];
  const canSubmit = requiredFields.every((field) => field.trim() !== '');
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const { setSnackbarContent, companyLogo, setCompanyLogo } = useStore(
    (store) => store
  );

  const getTrainingId = async () => {
    try {
      const response = await CreerService.getTrainingByIdentifier(identifier);

      const contentWithoutSentence = response.data.data.content.replace(
        dynamicUrlPattern,
        ''
      );

      if (
        !companyLogo &&
        response?.data?.data &&
        response.data.data?.company_id
      ) {
        downloadImageBase64(
          CompanyServices.getCompanyLogo,
          response.data.data.company_id,
          setCompanyLogo
        );
      }

      if (
        response?.data?.data?.answer &&
        response.data.data.answer.length > 0
      ) {
        navigate('/answer_submitted');
      }

      setTraining({
        ...response.data.data,
        content: contentWithoutSentence,
      });
    } catch (e) {
      setIsError(true);
    }
  };
  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await CreerService.submitResponse(identifier, answer);
      navigate('/answer_submitted');
      setAnswer('');
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  useEffect(() => {
    getTrainingId();
  }, [identifier]);

  if (isError) {
    return (
      <TypographyUI variant="h4" align="center" fontSize="24px">
        La formation avec cet identifiant n'existe pas
      </TypographyUI>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        {/* <TypographyUI
          color="black"
          fontSize="24px"
          marginBottom="10px"
          fontWeight={500}
        >
          {training.name}
        </TypographyUI> */}
        <Typography
          variant="body1"
          gutterBottom
          style={{ whiteSpace: 'pre-line' }}
        >
          {training.content}
        </Typography>
        <TextField
          label="Ecrivez votre réponse"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={answer}
          onChange={handleAnswerChange}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!canSubmit}
        >
          Enregistrer
        </Button>
      </Box>
    </Container>
  );
};

export default MicroTrainingsAnswer;
