import {
  Button,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState, memo } from 'react';
import { formatDates } from '../../../helpers';
import useSortableTable from '../../../hooks/useSortTable';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateEditForm from '../CreateEditForm';
import { actionTypes } from '../../../constants/types';

const FormTable = ({
  allForms,
  searchTerm,
  deleteForm,
  handleTogglePreview,
  openCopyDialog,
}) => {
  const [filteredForms, setFilteredForms] = useState([]);

  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredForms,
    {
      key: 'created_at',
      direction: 'asc',
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElIndex, setAnchorElIndex] = useState(null);

  const openMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setAnchorElIndex(index);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setAnchorElIndex(null);
  };

  useEffect(() => {
    const searchTermLowerCase = searchTerm.toLowerCase();

    const filteredTable = allForms.filter((form) => {
      const nameIncludesSearchTerm = form.title
        .toLowerCase()
        .includes(searchTermLowerCase);

      const contentIncludesSearchTerm = form?.description
        ?.toLowerCase()
        .includes(searchTermLowerCase);

      const date = formatDates(new Date(form.created_at));

      const datesIncludesSearchTerm = date
        .toLowerCase()
        .includes(searchTermLowerCase);

      return (
        nameIncludesSearchTerm ||
        contentIncludesSearchTerm ||
        datesIncludesSearchTerm
      );
    });

    setFilteredForms(filteredTable);
  }, [searchTerm, allForms]);

  const MemoizedSelectedForm = memo(CreateEditForm);

  return (
    <TableContainer component={Paper} className="green-border">
      {filteredForms.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow className="green-border">
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'created_at'}
                  direction={
                    sortConfig.key === 'created_at' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('created_at')}
                >
                  Date de création
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'title'}
                  direction={
                    sortConfig.key === 'title' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('title')}
                >
                  Titre Formulaire
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'description'}
                  direction={
                    sortConfig.key === 'description'
                      ? sortConfig.direction
                      : 'asc'
                  }
                  onClick={() => handleSort('description')}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((tr, index) => {
              const form = JSON.parse(JSON.stringify(tr));
              const date = formatDates(new Date(form.created_at));

              return (
                <TableRow
                  key={index}
                  style={{
                    border: '1px solid #c2e3d1',
                  }}
                >
                  <TableCell className="green-border">{date}</TableCell>
                  <TableCell
                    style={{
                      border: '1px solid #c2e3d1',
                    }}
                  >
                    <Box className="shrink-text-by-row">
                      <MemoizedSelectedForm
                        id={form._id}
                        type={actionTypes.edit}
                        text={form.title}
                        callbackOnClickIcon={() => closeMenu()}
                      />
                    </Box>
                  </TableCell>
                  <TableCell className="green-border ">
                    <Box className="shrink-text-by-row">{form.description}</Box>
                  </TableCell>
                  <TableCell className="green-border">
                    <Box className="align-cell-center">
                      <IconButton onClick={(e) => openMenu(e, index)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={anchorElIndex !== null && anchorElIndex === index}
                        onClose={closeMenu}
                      >
                        <Box className="align-cell-center">
                          <Tooltip title="Cliquez pour voir l'aperçu">
                            <Button
                              sx={{
                                color: '#4546CD',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              size="small"
                              startIcon={<i className="fas fa-eye"></i>}
                              onClick={() => {
                                handleTogglePreview(form);
                                closeMenu();
                              }}
                              style={{ margin: '0', padding: '0' }}
                            />
                          </Tooltip>

                          <MemoizedSelectedForm
                            id={form._id}
                            type={actionTypes.edit}
                            callbackOnClickIcon={() => closeMenu()}
                          />

                          <Tooltip title="Dupliquer">
                            <Button
                              sx={{
                                color: '#4546CD',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              size="small"
                              startIcon={<i className="fas fa-clone"></i>}
                              onClick={() => {
                                openCopyDialog(form);
                                closeMenu();
                              }}
                              style={{ margin: '0', padding: '0' }}
                            />
                          </Tooltip>
                          <Tooltip title="Supprimer">
                            <Button
                              sx={{
                                color: '#4546CD',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              size="small"
                              startIcon={<i className="fas fa-trash-alt"></i>}
                              onClick={() => {
                                deleteForm(form._id);
                                closeMenu();
                              }}
                              style={{ margin: '0', padding: '0' }}
                            />
                          </Tooltip>
                        </Box>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : null}

      {!allForms.length ? (
        <Box>
          <Typography variant="body1" style={{ padding: '16px 16px 0 16px' }}>
            Aucun formulaire disponible.
          </Typography>
          <Typography variant="body1" style={{ padding: '0 16px 16px 16px' }}>
            Vous pouvez en ajouter un nouveau
          </Typography>
        </Box>
      ) : null}

      {allForms.length && !filteredForms.length ? (
        <Box>
          <Typography variant="body1" style={{ padding: '16px 16px 0 16px' }}>
            Aucun formulaire disponible.
          </Typography>
          <Typography variant="body1" style={{ padding: '0 16px 16px 16px' }}>
            Vous pouvez en ajouter un nouveau
          </Typography>
        </Box>
      ) : null}
    </TableContainer>
  );
};

export default FormTable;
