import React from 'react';
import '../styles/Footer.scss';

function Footer() {

  return (
    <footer className="footer">
      <div className="content-footer">
        <p>&copy; Anemon</p>
        <p>|</p>
        <p>
          <a href="mailto:support@anemon.fr" target="_blank" rel="noreferrer">
            Contactez-nous
          </a>
        </p>
        <p>|</p>
        <p>
          <a
            href="https://drive.google.com/file/d/1te179MM6Xs2H9zXMxhjV9MO1wThTpRzr/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            Conditions générales d'utilisation
          </a>
        </p>
        <p>|</p>
        <p>
          <a
            href="https://anemon.co/politique-de-confidentialite/ "
            target="_blank"
            rel="noreferrer"
          >
            Politique de confidentialité
          </a>
        </p>
        <p>|</p>
        <p>
          <a
            href="https://anemon.co/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
