import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CreerService } from '../../../services/creeer';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import TypographyUI from '../../../components/TypographyUI';
import useStore from '../../../store/store';
import {
  contentWithFormString,
  trainingTypes,
} from '../../../constants/trainings';
import { formatDates } from '../../../helpers';
import { responseTypes } from '../../../constants/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const EvaluateTrainingStatistics = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [trainingId, setTrainingId] = useState(null);
  const [broadcastId, setBroadcastId] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [training, setTraining] = useState(null);
  const setSnackbarContent = useStore((store) => store.setSnackbarContent);
  const [trainingType, setTrainingType] = useState(null);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const handleExport = async () => {
    await CreerService.getExportTrainingStatistics(trainingId, broadcastId);
  };

  useEffect(() => {
    const { search } = location;

    const params = new URLSearchParams(search);
    const tempTrainingId = params.get('training_id');
    const tempBroadcastId = params.get('broadcast_id');

    setTrainingId(tempTrainingId);
    setBroadcastId(tempBroadcastId);

    const getTrainingStatistics = async () => {
      try {
        const response = await CreerService.getTrainingStatistics(
          tempTrainingId,
          tempBroadcastId
        );

        const trainingData = await CreerService.getTrainingById(tempTrainingId);
        setTraining(trainingData.data.data);
        setStatistics(response.data.data);

        const data = trainingData?.data?.data;

        if (data?.content.includes('/g/'))
          setTrainingType(trainingTypes.withForm);
        else if (data?.content.includes('/w/'))
          setTrainingType(trainingTypes.withWebAnswer);
        else if (data?.responseType && data?.responseType !== 'Non')
          setTrainingType(trainingTypes.withQuestion);
      } catch (e) {
        console.error('Error', e);
        setSnackbarContent('error', 'Error');
      }
    };

    if (tempTrainingId && tempBroadcastId) {
      getTrainingStatistics();
    }
  }, [location]);

  const openPopup = (form) => {
    setIsPopUpOpen(true);
    setSelectedAnswers(form);
  };

  const closePopUp = () => {
    setIsPopUpOpen(false);
  };

  const hasQuestions =
    trainingType === trainingTypes.withQuestion &&
    statistics?.questions_data?.length === 0;

  const hasForms =
    trainingType === trainingTypes.withForm &&
    statistics?.form_completions_data?.length === 0;

  const hasWebAnswers =
    trainingType === trainingTypes.withWebAnswer &&
    statistics?.web_flow_answers?.length === 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'inline-block',
          marginBottom: '20px',
          textAlign: 'left',
        }}
      >
        <font
          size="4"
          style={{
            cursor: 'pointer',
            marginRight: '5px',
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
          }}
          onClick={() => navigate(-1)}
        >
          <div className="fa fa- fa-chevron-circle-left"></div>
          <TypographyUI
            variant="h6"
            color="black"
            fontWeight={500}
            fontSize="16px"
          >
            Retour à la liste des évaluations de campagnes
          </TypographyUI>
        </font>
      </Box>

      {!statistics && (
        <TypographyUI
          variant="h6"
          color="black"
          fontWeight={500}
          fontSize="16px"
        >
          Il n'y a pas de réponses
        </TypographyUI>
      )}

      {(hasQuestions || hasForms || hasWebAnswers) && (
        <TypographyUI
          variant="h6"
          color="black"
          fontWeight={500}
          fontSize="16px"
        >
          Il n'y a pas de réponses
        </TypographyUI>
      )}

      {statistics &&
        training &&
        ((trainingType === trainingTypes.withForm &&
          statistics?.form_completions_data.length > 0) ||
          (trainingType === trainingTypes.withQuestion &&
            statistics?.questions_data?.answers.length > 0) ||
          (trainingType === trainingTypes.withWebAnswer &&
            statistics?.web_flow_answers?.length > 0)) && (
          <Box>
            <div style={{ textAlign: 'left', fontFamily: 'Montserrat' }}>
              {training && (
                <div>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: '50px',
                        color: 'blue',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      <strong>{training.name}</strong>
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleExport}
                      sx={{ flexShrink: 0 }}
                    >
                      Exporter
                    </Button>
                  </Box>
                </div>
              )}

              <TableContainer component={''} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="green-border">Prenom</TableCell>
                      <TableCell className="green-border">Nom</TableCell>
                      <TableCell className="green-border">
                        {trainingType === trainingTypes.withForm ? (
                          "Date d'achèvement"
                        ) : (
                          <>
                            "Téléphone"
                            <br />
                            <small style={{ whiteSpace: 'nowrap' }}>
                              (ex: 33 6 85 53 99 59)
                            </small>
                          </>
                        )}
                      </TableCell>
                      <TableCell className="green-border">
                        {trainingType === trainingTypes.withForm
                          ? 'Score'
                          : trainingType === trainingTypes.withWebAnswer
                          ? "Date d'achèvement"
                          : 'Réponse'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trainingType === trainingTypes.withForm &&
                      statistics?.form_answers
                        ?.filter((learner) => learner?.answers?.length)
                        .map((learner, index) => {
                          const form = JSON.parse(JSON.stringify(learner));

                          return (
                            <TableRow
                              className="green-border"
                              key={learner.learner}
                            >
                              <TableCell className="green-border">
                                <span
                                  className="text-link"
                                  onClick={() => openPopup(form.answers, index)}
                                >
                                  {learner?.learner?.firstName || 'Anonyme'}
                                </span>
                              </TableCell>
                              <TableCell className="green-border">
                                {learner?.learner?.lastName}
                              </TableCell>
                              <TableCell className="green-border">
                                {learner?.created_at
                                  ? formatDates(new Date(learner?.created_at))
                                  : ''}
                              </TableCell>
                              <TableCell className="green-border">
                                {learner?.points}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    {trainingType === trainingTypes.withWebAnswer &&
                      statistics?.web_flow_answers.map((learner, index) => {
                        const form = JSON.parse(JSON.stringify(learner));

                        return (
                          <TableRow
                            className="green-border"
                            key={learner.phoneNumber}
                          >
                            <TableCell className="green-border">
                              <span
                                className="text-link"
                                onClick={() => openPopup([form], index)}
                              >
                                {learner?.leaner?.firstName || 'Anonyme'}
                              </span>
                            </TableCell>
                            <TableCell className="green-border">
                              {learner?.leaner?.lastName}
                            </TableCell>
                            <TableCell className="green-border">
                              {learner?.leaner?.phoneNumber}
                            </TableCell>
                            <TableCell className="green-border">
                              {learner?.completedAt
                                ? formatDates(new Date(learner?.completedAt))
                                : ''}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {trainingType === trainingTypes.withQuestion &&
                      statistics?.questions_data?.answers.map((learner) => {
                        return (
                          <TableRow
                            className="green-border"
                            key={learner.phone_number}
                          >
                            <TableCell className="green-border">
                              {learner?.first_name}
                            </TableCell>
                            <TableCell className="green-border">
                              {learner?.last_name}
                            </TableCell>
                            <TableCell className="green-border">
                              {learner?.phone_number}
                            </TableCell>
                            <TableCell className="green-border">
                              {learner?.answer}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        )}

      <Dialog
        open={isPopUpOpen}
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'space-between',
              padding: '16px 24px',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#4546CD', fontWeight: 'bold' }}
            >
              Réponses
            </Typography>
            <Button variant="outlined" color="error" onClick={closePopUp}>
              Fermer
            </Button>
          </Box>
        </Box>
        <DialogContent>
          <Box className="forms-wrapper">
            {selectedAnswers &&
              selectedAnswers.map((question, questionIndex) => (
                <div key={questionIndex} className="form-question">
                  {trainingType === trainingTypes.withWebAnswer ? (
                    <pre
                      className="form-question-label"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {question.description}
                      {question.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </pre>
                  ) : (
                    <label className="form-question-label">
                      {question.question}
                      {question.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}{' '}
                      ({question.points} point(s))
                    </label>
                  )}

                  {(question.responseType || question.type) ===
                    responseTypes.TEXT.value && (
                    <TextField
                      disabled
                      multiline
                      value={question.answer}
                      className="form-input"
                      style={{
                        width: '100%',
                        marginTop: '5px',
                      }}
                    />
                  )}
                  {(question.responseType || question.type) ===
                    responseTypes.MULTIPLE_CHOICE.value && (
                    <div className="form-radio-group">
                      {question.options.map((option, optionIndex) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            key={optionIndex}
                            value={option}
                            checked={
                              option === question.answer ||
                              question?.answer.includes(option)
                            }
                            control={<Radio disabled />}
                            label={option}
                          />

                          {option === question?.correctAnswer ||
                          question?.correctAnswer?.includes(option) ||
                          question?.correctAnswers?.includes(option) ? (
                            <Tooltip title="Bonne réponse">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                size="lg"
                                color="#66DEAE"
                              />
                            </Tooltip>
                          ) : null}

                          {option === question?.incorrect ||
                          question?.incorrect?.includes(option) ||
                          (trainingType === trainingTypes.withWebAnswer &&
                            !question?.correctAnswers?.includes(option) &&
                            question?.answer?.includes(option)) ? (
                            <Tooltip title="Mauvaise réponse">
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="lg"
                                color="#fa7070"
                              />
                            </Tooltip>
                          ) : null}
                        </Box>
                      ))}
                    </div>
                  )}
                  {(question.responseType || question.type) ===
                    responseTypes.CHECKBOX.value && (
                    <div className="form-checkbox-group">
                      {question.options.map((option, optionIndex) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            key={optionIndex}
                            value={option}
                            checked={
                              option === question.answer ||
                              question?.answer.includes(option)
                            }
                            control={<Checkbox disabled />}
                            label={option}
                          />

                          {option === question?.correctAnswer ||
                          question?.correctAnswer?.includes(option) ||
                          question?.correctAnswers?.includes(option) ? (
                            <Tooltip title="Bonne réponse">
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                size="lg"
                                color="#66DEAE"
                              />
                            </Tooltip>
                          ) : null}

                          {option === question?.incorrect ||
                          question?.incorrect?.includes(option) ||
                          (trainingType === trainingTypes.withWebAnswer &&
                            !question?.correctAnswers?.includes(option) &&
                            question?.answer?.includes(option)) ? (
                            <Tooltip title="Mauvaise réponse">
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="lg"
                                color="#fa7070"
                              />
                            </Tooltip>
                          ) : null}
                        </Box>
                      ))}
                    </div>
                  )}
                  {question.responseType ===
                    responseTypes.LINEAR_SCALE.value && (
                    <div className="linear-wrapper">
                      <div className="form-range-labels">
                        {Array.from(
                          { length: question.linear_scale_size || 10 },
                          (_, i) => i + 1
                        ).map((num) => (
                          <span
                            key={num}
                            style={{
                              width: '30px',
                              textAlign: 'center',
                            }}
                          >
                            {num}
                          </span>
                        ))}
                      </div>
                      <ToggleButtonGroup
                        exclusive
                        value={question?.answer && +question.answer}
                        disabled
                        aria-label="linear scale"
                      >
                        {Array.from(
                          { length: question.linear_scale_size || 10 },
                          (_, i) => i + 1
                        ).map((num) => (
                          <ToggleButton key={num} value={num}>
                            {/* {num} */}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </div>
                  )}
                </div>
              ))}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EvaluateTrainingStatistics;
