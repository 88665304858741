import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinkService } from '../../services/link';

const Links = () => {
  const { id } = useParams();
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    const getLinks = async (link) => {
      const response = await LinkService.getLink(link);

      let redirectLink = response.data.redirectLink.source;
      const prefix = 'https://';

      if (!redirectLink.includes(prefix)) {
        redirectLink = prefix + redirectLink;
      }

      setIsRedirected(true);
      window.location.replace(redirectLink);
    };
    if (id && isRedirected === false) getLinks(id);
  }, []);

  return (
    <Box className="files-wrapper">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Box>
  );
};

export default Links;
