import React, { useEffect, useState } from 'react';

import './MicroTrainings.scss';
import {
  TextField,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useLearnerContext } from '../../context/LearnerContext';
import { CreerService } from '../../services/creeer';
import TrainingTable from './TrainingTable';
import CreateEditTrainings from './CreateEditTrainings';
import { actionTypes } from '../../constants/types';
import TypographyUI from '../../components/TypographyUI';
import useStore from '../../store/store';
import { dynamicUrlPattern } from '../../constants/trainings';
import { TagService } from '../../services/tags';
import { TAG_TYPES } from '../../constants/tags';
import { ROLES } from '../../constants/auth';

function MicroTrainings() {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    getTrainings,
    trainings,
    formattedTags,
    getAllTags,
    setTrainings,
    organization,
  } = useLearnerContext();
  const [open, setOpen] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [trainingToDelete, setTrainingToDelete] = useState(null);
  const [clonedTrainingName, setClonedTrainingName] = useState('');
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const { setSnackbarContent, selectedOrganizationId } = useStore(
    (store) => store
  );
  const [isCloneBtnDisabled, setsIsClonseBtnDisabled] = useState(false);

  const [newTag, setNewTag] = useState('');
  const [isDeleteTagOption, setIsDeleteTagOption] = useState(false);
  const [deleteOptionTag, setDeleteOptionTag] = useState(null);

  const role = useStore((state) => state.userRole);
  const [selectedOrganizationToCopy, setSelectedOrganizationToCopy] =
    useState(null);

  const handleOpen = (trainingId, index) => {
    setActivePopup(index);
    const trainingToClone = trainings.find(
      (training) => training._id === trainingId
    );
    setClonedTrainingName(`Copie de ${trainingToClone.name}`);
    setOpen(true);
  };
  const handleClose = () => {
    setActivePopup(null);
    setOpen(false);
    resetCompanyToCopy();
  };

  const changeCopmanyForCopy = (event) => {
    const selectedCompany = organization.find(
      (org) => org.name === event.target.value.name
    );

    setSelectedOrganizationToCopy(selectedCompany);
  };

  const cloneTraining = async (id) => {
    setsIsClonseBtnDisabled(true);

    if (
      role === ROLES.ADMIN &&
      selectedOrganizationToCopy._id !== selectedOrganizationId
    ) {
      try {
        const trainingToCopy = trainings.find((t) => t._id === id);
        const payload = {
          training_id: trainingToCopy._id,
          company_id: selectedOrganizationToCopy._id,
          title: clonedTrainingName,
        };
        await CreerService.crossCopyTraining(payload);

        handleClose();
        getTrainings();
        resetCompanyToCopy();
        setSnackbarContent('success', 'Formation copiée avec succès');
      } catch (e) {
        console.error(e);
        setSnackbarContent('error', e?.response?.data?.message || 'Error');
      } finally {
        setsIsClonseBtnDisabled(false);
      }

      return;
    }

    try {
      const training = trainings.find((t) => t._id === id);
      const newTraining = {
        ...training,
        name: clonedTrainingName,
        company_id: selectedOrganizationId,
      };
      delete newTraining._id;
      delete newTraining.__v;
      delete newTraining.created_at;

      const updatedContentWithLink = newTraining.content.replace(
        dynamicUrlPattern,
        ''
      );
      newTraining.content = updatedContentWithLink;

      await CreerService.createTraining(newTraining);
      setClonedTrainingName(newTraining.name);
      handleClose();
      getTrainings();
      resetCompanyToCopy();
    } catch (e) {
      console.error(e);
      setSnackbarContent('Erreur due à la formation au clonage');
    } finally {
      setsIsClonseBtnDisabled(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const deleteTraining = (trainingId) => {
    setTrainingToDelete(trainingId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const confirmDeleteTraining = async () => {
    if (trainingToDelete) {
      try {
        await CreerService.deleteTraining(trainingToDelete);
        getTrainings();
      } catch (error) {
        setSnackbarContent('error', error?.response?.data?.message);
      }
    }
    handleCloseDeleteConfirmation();
  };

  const fetchData = async () => {
    Promise.all([
      getAllTags(undefined, TAG_TYPES.TRAINING),
      getTrainings(),
    ]).then(() => {
      setLoaderVisible(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateTraining = async (id, updatedData) => {
    await CreerService.updateTraining(id, updatedData);
  };

  const handleTagChange = ({ event, id }) => {
    const foundLearnerIndex = trainings.findIndex(
      (learner) => learner._id === id
    );

    if (foundLearnerIndex !== -1) {
      const updatedLearner = { ...trainings[foundLearnerIndex], tags: event };

      const newData = [...trainings];
      newData[foundLearnerIndex] = updatedLearner;
      setTrainings(newData);

      const { _id, tags, ...otherData } = updatedLearner;
      updateTraining(_id, { tags, ...otherData });
    }
  };

  const handleAddNewTag = async (newTagValue) => {
    await TagService.createTag(
      newTagValue,
      selectedOrganizationId,
      TAG_TYPES.TRAINING
    );
    setNewTag('');
    getAllTags(undefined, TAG_TYPES.TRAINING);
    setOpen(false);
  };

  const openConfirmDeleteTag = (tagName) => {
    const foundTag = formattedTags.find((tag) => tag.tagName === tagName);
    if (foundTag) {
      setIsDeleteTagOption(true);
      setDeleteOptionTag(foundTag);
    }
  };

  const deleteTag = async () => {
    try {
      await TagService.deleteTag(deleteOptionTag?._id, selectedOrganizationId);
      await Promise.all([
        getAllTags(undefined, TAG_TYPES.TRAINING),
        getTrainings(),
      ]);
      closeDeleteTag();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const closeDeleteTag = () => {
    setIsDeleteTagOption(false);
    setDeleteOptionTag(null);
  };

  const resetCompanyToCopy = () => {
    const company = organization.find(
      (org) => org._id === selectedOrganizationId
    );

    setSelectedOrganizationToCopy(company);
  };

  useEffect(() => {
    if (!selectedOrganizationToCopy && organization.length) {
      const companyToSelect = organization.find(
        (org) => org._id === selectedOrganizationId
      );

      setSelectedOrganizationToCopy(companyToSelect);
    }
  }, [organization.length]);

  if (isLoaderVisible) return <></>;

  return (
    <Box className="micro-trainings">
      <Box>
        <Grid container spacing={2} display={'flex'} flexDirection={'column'}>
          <Grid item>
            <TypographyUI variant="h6" align="left" marginBottom="5px">
              Bibliothèque
            </TypographyUI>
          </Grid>
        </Grid>

        <Box className="micro-trainings-wrapper">
          <Box>
            <TextField
              label="Recherche"
              autoComplete="off"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ marginRight: '10px' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid item align="left">
              <CreateEditTrainings type={actionTypes.create} />
            </Grid>
          </Box>
        </Box>
      </Box>
      <TrainingTable
        trainings={trainings}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        handleOpen={handleOpen}
        handleClose={handleClose}
        cloneTraining={cloneTraining}
        isCloneBtnDisabled={isCloneBtnDisabled}
        deleteTraining={deleteTraining}
        activePopup={activePopup}
        deleteConfirmationOpen={deleteConfirmationOpen}
        handleCloseDeleteConfirmation={handleCloseDeleteConfirmation}
        confirmDeleteTraining={confirmDeleteTraining}
        clonedTrainingName={clonedTrainingName}
        setClonedTrainingName={setClonedTrainingName}
        formattedTags={formattedTags}
        handleAddTag={handleAddNewTag}
        setNewTag={setNewTag}
        handleTagChange={handleTagChange}
        openConfirmDeleteTag={openConfirmDeleteTag}
        changeCopmanyForCopy={changeCopmanyForCopy}
        selectedOrganizationToCopy={selectedOrganizationToCopy}
      />

      <Dialog open={isDeleteTagOption}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voulez-vous vraiment supprimer la balise{' '}
            <u>'{deleteOptionTag?.tagName}'</u>, elle sera supprimée partout.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={closeDeleteTag}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={deleteTag}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MicroTrainings;
