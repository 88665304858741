export const questionDevider = '=GHS8PJ138Y=';
export const newQuestionDevider = '=GHS8PAHJ138Y=';

export const responseTypes = {
  TEXT: {
    value: 'Short Answer',
    label: 'Réponse courte'
  },
  MULTIPLE_CHOICE: {
    value: 'Multiple choice',
    label: 'Choix multiple'
  },
  CHECKBOX: {
    value: 'Checkboxes',
    label: 'Case à cocher'
  },
  LINEAR_SCALE: {
    value: 'Linear scale',
    label: 'Echelle Linéaire'
  }, 
};
