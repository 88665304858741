
import {Typography, Box } from "@mui/material";

export default function Contact() {
  

 const imageLinks = [
  {
    link : "https://anemon.co/wp-content/uploads/2022/03/logo-french-tech.png",
    targetUrl : "https://frenchtech-brestplus.bzh/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/03/logo-edtech-france.png",
    targetUrl : "https://edtechfrance.fr/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/01/logo-anticipa.png",
    targetUrl : "https://www.technopole-anticipa.com/anemon-lance-un-parcours-de-micro-formation-de-cybersecurite/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/01/logo-enseignement-recherche.png",
    targetUrl : "https://www.gouvernement.fr/le-ministere-de-l-enseignement-superieur-de-la-recherche-et-de-l-innovation",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/03/logo-le-village.png",
    targetUrl : "https://levillagebycacotesdarmor.com/startups-village-by-ca-cotes-d-armor/anemon-startup-villagebyca/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/01/logo-region-bretagne.png",
    targetUrl : "https://www.bretagne.bzh/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/03/logo-orange.png",
    targetUrl : "https://www.orange.fr/portail",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/01/logo-initiative-tregor.png",
    targetUrl : "https://www.technopole-anticipa.com/initiative-tregor/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/01/logo-schoolab.png",
    targetUrl : "https://www.theschoolab.com/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/09/Outlook-2owv1cbv-300x202.jpeg",
    targetUrl : "https://www.youtube.com/watch?v=7izPJp1W8R8&list=PLHWGfQmqyWtYdsnq5b--5Gl3L6IzaxwK8",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/09/Logo-BPI-NB-300x90.jpeg",
    targetUrl : "https://www.bpifrance.fr/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/11/Capture-decran-2022-11-25-a-19.34.23-1-300x122.jpeg",
    targetUrl : "https://www.impactfrance.eco/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/11/Capture-decran-2022-11-25-a-19.50.33-1-300x62.jpeg",
    targetUrl : "https://www.cheminees-seguin.com/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2022/11/Capture-decran-2022-11-25-a-19.51.10-1-300x183.jpeg",
    targetUrl : "https://www.credit-agricole.fr/",
  },
  {
    link : "https://anemon.co/wp-content/uploads/2023/07/Logo-PlaneteTC-Signataire-Noir-et-Blanc-300x142.png",
    targetUrl : "https://planet-techcare.green/"
  }  
  
 ]

  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      width: "100%"
    }}
  >
    
    <Box sx={{ maxWidth: 600, p: 2 }}>
      <Typography variant="h4" align="center" mb={2} style={{fontFamily : "Poppins,sans-serif", fontWeight : "900"}}>
        Contact Us
      </Typography>
      <div
          style={{
            width: "130px",
            height: "3px",
            backgroundColor: "#66DEAE",
            margin: "0 auto",
            borderRadius:"2px"
          }}
        />
    </Box>

    
    <Box sx={{ maxWidth: 600, p: 2 }}>
      <Typography variant="body1" align="center" mb={2} style={{fontFamily : "Poppins,sans-serif", fontWeight: '500', color:"black", fontSize:'1.1rem'}}>
        Contenu en préparation
      </Typography>
    </Box>

   
    <Box sx={{ maxWidth: 600, p: 2 }}>
      <Typography variant="body1" align="center" mb={2} style={{fontFamily : "Poppins,sans-serif", fontWeight: '500',  fontSize:'1.1rem'}} >
        Anemon est accompagné par:
      </Typography>
    </Box>

    
    <Box sx={{ maxWidth: 900, p: 2 }}>
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        
          {imageLinks.map((item, index) => (
            <Box key={index} sx={{ p: 1 }}>
              <a href={item.targetUrl} target="_blank" rel="noopener noreferrer">
                <img src={item.link} alt={`${index + 1}`} style={{ width: 100 }} />
              </a>
            </Box>
          ))}
        </Box>
    </Box>
  </Box>
  );
}