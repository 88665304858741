import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import TypographyUI from '../../components/TypographyUI';

const SuccessPhoneSent = () => {
  return (
    <>
      <Box className="centered" sx={{ marginTop: '-55px' }}>
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="2xl"
          className="icon-success"
          color="#66DEAE"
        />
        <TypographyUI variant="h2" align="center">
          Succès
        </TypographyUI>
        <TypographyUI variant="h4" fontSize="16px" align="center">
          Message envoyé avec succès sur votre téléphone
        </TypographyUI>
      </Box>
    </>
  );
};

export default SuccessPhoneSent;
