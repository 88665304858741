import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { LearnersService } from '../../services/learners';
import { useLearnerContext } from '../../context/LearnerContext';
import { TagService } from '../../services/tags';
import useStore from '../../store/store';
import { TAG_TYPES } from '../../constants/tags';

function UsersTable({ data, open, onClose, onEdit, onDelete, refetch }) {
  const [editedData, setEditedData] = useState({});
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const { formattedTags } = useLearnerContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setSnackbarContent = useStore((store) => store.setSnackbarContent);
  const selectedOrganizationId = useStore(
    (store) => store.selectedOrganizationId
  );

  const handleEdit = (id, field, value) => {
    const updatedData = { ...editedData };
    if (!updatedData[id]) {
      updatedData[id] = { ...data[id] };
    }
    updatedData[id][field] = value;
    setEditedData(updatedData);
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    setSnackbarContent('info', "Essayer d'inviter des apprenants");
    try {
      const combinedData = data.map((user, index) => {
        if (editedData[index]) {
          return editedData[index];
        }
        return user;
      });

      const tagsData = combinedData.map((item) => item.tags);
      const tagsArray = [];
      tagsData?.forEach((tags) => {
        tags?.forEach((tag) => {
          let exists = false;
          formattedTags.map((e) => {
            if (tag.trim() === e.tagName.trim()) {
              exists = true;
            }
          });
          if (!exists) tagsArray.push(tag.trim());
        });
      });

      const postedTags = {
        tags: [...new Set(tagsArray)],
        tagType: TAG_TYPES.LEARNER,
        company_id: selectedOrganizationId,
      };
      const response = await TagService.createMiltipleTas(postedTags);

      if (!response.data.success) {
        console.log('Error due inviting');
      }

      await LearnersService.bulkInsert(
        combinedData.map((learner) => ({
          ...learner,
          phoneNumber: learner.phoneNumber.trim(),
        })),
        selectedOrganizationId
      );
      setSnackbarContent('success', 'Apprenants invités avec succès');

      refetch();
      onClose();
    } catch (e) {
      console.error(e);
      setSnackbarContent(
        'error',
        e?.response?.data?.message || "Erreur due à l'invitation d'apprenants"
      );
    } finally {
      setIsSubmitting(false);
    }

    onEdit(editedData);
    setEditedData({});
  };

  return (
    <Dialog
      scroll={'paper'}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Prénom</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>
                  Téléphone
                  <br /> 
                  <small style={{ whiteSpace: 'nowrap'}}>(33 6 85 53 99 59)</small>
                </TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        value={editedData[index]?.firstName || user.firstName}
                        onChange={(e) =>
                          handleEdit(index, 'firstName', e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={editedData[index]?.lastName || user.lastName}
                        onChange={(e) =>
                          handleEdit(index, 'lastName', e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={
                          editedData[index]?.phoneNumber || user.phoneNumber
                        }
                        onChange={(e) =>
                          handleEdit(index, 'phoneNumber', e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={
                          editedData[index]?.tags ||
                          (user?.tags && user.tags.join(', '))
                        }
                        onChange={(e) =>
                          handleEdit(index, 'tags', e.target.value.split(','))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => onDelete(index)}
                        sx={{ color: '#4546CD' }}
                        size="small"
                        startIcon={<i className="fas fa-trash-alt"></i>}
                        style={{ margin: '0', padding: '0' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Annuler
        </Button>
        <Button
          onClick={handleSave}
          disabled={isSubmitting}
          variant="contained"
          color="primary"
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UsersTable;
