import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { CreerService } from '../../../../services/creeer';
import { useParams } from 'react-router-dom';

const WebPageInfo = () => {
  const [responseText, setResponseText] = useState('');
  const { id } = useParams();

  const getTrainingResponse = async (trainingId) => {
    const response = await CreerService.getWebAnswerTraining(trainingId);
    const text = response.data.data.responseText;
    setResponseText(text);
  };

  useEffect(() => {
    getTrainingResponse(id);
  }, []);

  function linkify(text) {
    text = text.replace(/(triton\.anemon\.fr\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*)/gi, '<a style="color: #66DEAE;" href="https://$1" target="_blank" rel="noopener noreferrer">$1</a>');
    text = text.replace(/(vps-de6aee50\.vps\.ovh\.net\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*)/gi, '<a style="color: #66DEAE;" href="https://$1" target="_blank" rel="noopener noreferrer">$1</a>');
    return text;
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%',
      }}
    >
      <FontAwesomeIcon icon={faCircleInfo} size="4x" color="#2f7ef2" />
      <pre
        style={{
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          marginTop: '20px',
          whiteSpace: 'pre-wrap',
        }}
        dangerouslySetInnerHTML={{ __html: linkify(responseText) }}
      />
    </Box>
  );
};

export default WebPageInfo;
