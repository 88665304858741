import { Button, Tooltip } from '@mui/material';
import React from 'react';

const ModifyButton = ({ handleOpen }) => {
  return (
    <Tooltip title="Modifier">
      <Button
        sx={{
          color: '#4546CD',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        size="small"
        startIcon={<i className="fas fa-edit"></i>}
        onClick={handleOpen}
        style={{ margin: '0', padding: '0' }}
      />
    </Tooltip>
  );
};

export default ModifyButton;
