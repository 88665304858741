import React from 'react';
import { Avatar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import 'react-international-phone/style.css';

function ProfileAvatar({ image, handleImageUpload }) {
  return (
    <label htmlFor="avatar-upload" style={{ cursor: 'pointer' }}>
      <Avatar
        alt="User Sharp"
        src={image || ''}
        className="profile-avatar"
        sx={{
          width: 150,
          height: 150,
          position: 'relative',
          '&:hover::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.7)',
          },
          '&:hover::before svg': {
            fontSize: 50,
            color: '#fff',
          },
        }}
      >
        <CameraAltIcon sx={{ fontSize: 50 }} />
      </Avatar>
      <input
        type="file"
        accept="image/*"
        id="avatar-upload"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
    </label>
  );
}

export default ProfileAvatar;
