import { useState, useEffect } from 'react';
import { formatDates } from '../helpers';

const keyForDates = ['_id', 'id', 'idTemp', 'dateOfCreation'];
const keyToTransforfDate = ['created_at'];
const keyScore = ['score', 'points'];

const useSortableTable = (
  data,
  defaultSortConfig = { key: '', direction: '' }
) => {
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState(defaultSortConfig);

  useEffect(() => {
    const sortedForms = [...data].sort((a, b) => {
      const keyA = getKey(a, sortConfig.key);
      const keyB = getKey(b, sortConfig.key);

      if (typeof keyA === 'number' && typeof keyB === 'number') {
        return sortConfig.direction === 'asc' ? keyA - keyB : keyB - keyA;
      } else if (keyToTransforfDate.includes(sortConfig.key)) {
        const dateA = new Date(keyA);
        const dateB = new Date(keyB);

        return sortConfig.direction === 'asc' ? dateB - dateA : dateA - dateB;
      } else if (keyScore.includes(sortConfig.key)) {
        const scoreA = keyA.split('/')[0];
        const scoreB = keyB.split('/')[0];

        return sortConfig.direction === 'asc'
          ? +scoreB - +scoreA
          : +scoreA - +scoreB;
      } else if (
        keyForDates.includes(sortConfig.key) &&
        isDate(keyA) &&
        isDate(keyB)
      ) {
        const timestampA = parseInt(keyA.substring(0, 8), 16) * 1000;
        const idA = formatDates(new Date(timestampA));

        const timestampB = parseInt(keyB.substring(0, 8), 16) * 1000;
        const idB = formatDates(new Date(timestampB));

        const dateA = parseDate(idA);
        const dateB = parseDate(idB);

        return sortConfig.direction === 'asc' ? dateB - dateA : dateA - dateB;
      } else {
        const stringA = keyA ? keyA.toString().toLowerCase() : '';
        const stringB = keyB ? keyB.toString().toLowerCase() : '';

        return sortConfig.direction === 'asc'
          ? stringA.localeCompare(stringB)
          : stringB.localeCompare(stringA);
      }
    });

    setSortedData(sortedForms);
  }, [data, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';

    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });
  };

  const getKey = (item, key) => {
    const keys = key.split('.');
    return keys.reduce(
      (obj, k) => (obj && obj[k] !== 'undefined' ? obj[k] : undefined),
      item
    );
  };

  const isDate = (value) => {
    const timestamp = parseInt(value.substring(0, 8), 16) * 1000;
    const date = formatDates(new Date(timestamp));

    const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return regex.test(date);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  };

  return { sortedData, handleSort, sortConfig };
};

export default useSortableTable;
