import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { UsersService } from '../../../services/users';

const DeleteUser = ({
  user,
  currentUserEmail,
  setSnackbarContent,
  refreshData,
}) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const deleteUser = (companyId) => {
    setUserId(companyId);
    setDeleteConfirmationOpen(true);
  };

  const deleteUserConfirm = async () => {
    if (currentUserEmail === user.email) {
      return setSnackbarContent('error', 'You cannot delete yourself');
    }

    try {
      await UsersService.deleteUser(userId);
      handleCloseDeleteConfirmation();
      refreshData();
      setUserId(null);
    } catch (e) {
      console.log('Error', e);
      return setSnackbarContent(
        'error',
        "Erreur due à la suppression de l'utilisateur"
      );
    }
  };

  return (
    <>
      <Tooltip title="Supprimer">
        <Button
          sx={{
            color: '#4546CD',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          size="small"
          onClick={() => deleteUser(user._id)}
          startIcon={<i className="fas fa-trash-alt"></i>}
        />
      </Tooltip>
      <Dialog open={deleteConfirmationOpen}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cet utilisateur?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseDeleteConfirmation}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={deleteUserConfirm}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUser;
