import { Box, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FilesService } from '../../services/files';
import { LearnersService } from '../../services/learners';

function DropFile({ setUserData, setIsInviteDialogOpen, closeDropFile }) {
  const [excelFile, setExcelFile] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsError(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx, .xls',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          setExcelFile(file);
        } else {
          alert('Please upload a valid Excel file.');
        }
      }
    },
  });

  const ValidateData = async () => {
    try {
      const res = await LearnersService.validateTemplate(excelFile);
      return res.data;
    } catch (e) {
      console.log('Error:', e);
      if (e?.response?.data?.message) {
        setErrorMessage(e.response.data.message);
      } else {
        setErrorMessage('Error due inviting');
      }
      setIsError(true);
    }
  };

  const uploadFile = async () => {
    setIsError(false);
    setIsInviteDialogOpen(false);
    if (excelFile) {
      try {
        await FilesService.uploadFile(excelFile, 1);
        const fileData = await ValidateData(excelFile);
        setUserData(fileData.data);
        setIsInviteDialogOpen(true);
        closeDropFile();
      } catch (e) {
        console.log('Error: ', e.response.data.message);
        if (e?.response?.data?.message) {
          setErrorMessage(e.response.data.message);
        } else {
          setErrorMessage('Error due inviting');
        }

        setIsError(true);
      }
    }
  };

  useEffect(() => {
    uploadFile();
  }, [excelFile]);

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      {excelFile ? (
        <Typography>Fichier Excel téléchargé: {excelFile.name}</Typography>
      ) : (
        <Typography className="drop-area">
          Faites glisser et déposez un fichier Excel ici, ou cliquez pour
          sélectionner
        </Typography>
      )}

      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMessage}
      />
    </Box>
  );
}

export default DropFile;
