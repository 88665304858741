import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { diffuserTypes } from '../../../constants/broadcast';
import useSortableTable from '../../../hooks/useSortTable';
import { formatDates } from '../../../helpers';

function EvaluateTable({ filteredBroadcasts }) {
  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredBroadcasts,
    {
      key: 'name',
      direction: 'asc',
    }
  );

  return (
    <>
      {filteredBroadcasts.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ marginTop: '20px' }}>
            <TableHead>
              <TableRow className="green-border">
                <TableCell>
                  <TableSortLabel
                    style={{ textAlign: 'center' }}
                    active={sortConfig.key === 'type'}
                    direction={
                      sortConfig.key === 'type' ? sortConfig.direction : 'asc'
                    }
                    onClick={() => handleSort('type')}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    style={{ textAlign: 'center' }}
                    active={sortConfig.key === 'name'}
                    direction={
                      sortConfig.key === 'name' ? sortConfig.direction : 'asc'
                    }
                    onClick={() => handleSort('name')}
                  >
                    Nom Parcours
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'recipients_amount'}
                    direction={
                      sortConfig.key === 'recipients_amount'
                        ? sortConfig.direction
                        : 'asc'
                    }
                    onClick={() => handleSort('recipients_amount')}
                  >
                    Nombre Apprenants
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'endDate'}
                    direction={
                      sortConfig.key === 'endDate'
                        ? sortConfig.direction
                        : 'asc'
                    }
                    onClick={() => handleSort('endDate')}
                  >
                    Date de fin
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Taux Complétion
                  <Tooltip
                    title="Statistique de diffusion du parcours"
                    placement="left"
                  >
                    <i
                      className="fa fa-fas fa-question-circle"
                      style={{ color: '#3333cc', marginLeft: '5px' }}
                    ></i>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  Taux Engagement
                  <Tooltip
                    title="Statistique globale de réponses par sms, page web et formulaire"
                    placement="left"
                  >
                    <i
                      className="fa fa-fas fa-question-circle"
                      style={{ color: '#3333cc', marginLeft: '5px' }}
                    ></i>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Taux de Clicks
                  <Tooltip
                    title="Statistique globale de clics sur les liens"
                    placement="left"
                  >
                    <i
                      className="fa fa-fas fa-question-circle"
                      style={{ color: '#3333cc', marginLeft: '5px' }}
                    ></i>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((broadcast) => (
                <TableRow className="green-border" key={broadcast._id}>
                  <TableCell align="center" className="green-border">
                    {(broadcast.type &&
                      diffuserTypes[broadcast?.type]?.label) ||
                      broadcast.type}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    <Link to={`/evaluate-details/${broadcast._id}`}>
                      <span className="text-link">{broadcast.name}</span>
                    </Link>
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast?.recipients_amount || broadcast?.recipients}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {diffuserTypes.specific.value === broadcast.type &&
                    broadcast.trainingFormatted[0]
                      ? formatDates(
                          new Date(
                            broadcast.trainingFormatted[
                              broadcast.trainingFormatted.length - 1
                            ].date
                          )
                        )
                      : ''}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast?.tax_completion || '0.00%'}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast?.tax_engagement || '0.00%'}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast?.tax_clicks || '0.00%'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" style={{ padding: '16px' }}>
          Aucune évaluation disponible.
        </Typography>
      )}
    </>
  );
}

export default EvaluateTable;
