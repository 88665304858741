export const extractTextAndLink = (inputText) => {
  // Define a regular expression pattern to match the text and link
  const regex = /(.*?)(Lien pour répondre: .*)/s;

  // Use the regular expression to extract matches
  const matches = inputText.match(regex);

  if (matches) {
    // Extracted text and link from the matches
    const text = matches[1];
    const link = matches[2];

    return { text, link };
  } else {
    // Return an error or handle the case where no match is found
    return null;
  }
};

export const isContainLinkOrResponse = (text) => {
  if (text.indexOf('/q/') >= 0) return false;
  else if (text.indexOf('/g/') >= 0) return false;
  else if (text.indexOf('/f/') >= 0) return false;
  else if (text.indexOf('/l/') >= 0) return false;
  else if (text.indexOf('/w/') >= 0) return false;

  return true;
};
