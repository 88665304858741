import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import React from 'react';
import TypographyUI from '../../components/TypographyUI';

const SuccessPassword = () => {
  return (
    <>
      <Box className="centered" sx={{ marginTop: '-55px' }}>
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="2xl"
          className="icon-success"
          color="#66DEAE"
        />
        <TypographyUI variant="h2" align="center">
          Succès
        </TypographyUI>
        <TypographyUI variant="h4" fontSize="16px" align="center">
          Votre mot de passe a été mis à jour avec succès
        </TypographyUI>
      </Box>
    </>
  );
};

export default SuccessPassword;
