import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Typography,
  Tooltip,
  Button,
  Switch,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import CreateEditDiffuser from '../CreateEditDiffuser';
import { actionTypes } from '../../../constants/types';
import {
  diffuserActionTypes,
  diffuserStatuses,
  diffuserTypes,
} from '../../../constants/broadcast';
import { formatDates, getCompanyIndex } from '../../../helpers';
import useSortableTable from '../../../hooks/useSortTable';
import Tags from '../../../components/Tags/Tags';
import { ROLES } from '../../../constants/auth';
import useStore from '../../../store/store';
import { useLearnerContext } from '../../../context/LearnerContext';

function BroadcastTable({
  filteredBroadcasts,
  handleLinkCopy,
  handleCheckboxChange,
  isSnackbarOpen,
  handleCloseSnackbar,
  handleDialogOpen,
  handleDialogClose,
  activePopup,
  open,
  clonedBroadcastName,
  cloneBroadcast,
  setClonedBroadcastName,
  confirmDialogOpen,
  confirmActionType,
  handleConfirm,
  handleCancel,
  selectedBroadcasts,
  changeSelectedBroadcasts,
  creditsInfo,
  formattedTags,
  handleAddTag,
  setNewTag,
  handleTagChange,
  openConfirmDeleteTag,
  isConfirmCopyDisabled,
  changeCopmanyForCopy,
  selectedOrganizationToCopy,
  setConfirmedBroadcastId,
}) {
  const navigate = useNavigate();
  const role = useStore((state) => state.userRole);
  const { organization } = useLearnerContext();

  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredBroadcasts,
    {
      key: 'dateOfCreation',
      direction: 'asc',
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElIndex, setAnchorElIndex] = useState(null);

  const openMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setAnchorElIndex(index);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setAnchorElIndex(null);
  };

  return (
    <TableContainer component={Paper} className="green-border">
      {filteredBroadcasts.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow className="green-border">
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'type'}
                  direction={
                    sortConfig.key === 'type' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('type')}
                  hideSortIcon
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={
                    sortConfig.key === 'name' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('name')}
                  hideSortIcon
                >
                  Nom Parcours
                </TableSortLabel>
              </TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'recipients_amount'}
                  direction={
                    sortConfig.key === 'recipients_amount'
                      ? sortConfig.direction
                      : 'asc'
                  }
                  onClick={() => handleSort('recipients_amount')}
                  hideSortIcon
                >
                  Nombre Apprenants
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'dateOfCreation'}
                  direction={
                    sortConfig.key === 'dateOfCreation'
                      ? sortConfig.direction
                      : 'asc'
                  }
                  onClick={() => handleSort('dateOfCreation')}
                  hideSortIcon
                >
                  Date de début
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'endDate'}
                  direction={
                    sortConfig.key === 'endDate' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('endDate')}
                  hideSortIcon
                >
                  Date de fin
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'status'}
                  direction={
                    sortConfig.key === 'status' ? sortConfig.direction : 'asc'
                  }
                  onClick={() => handleSort('status')}
                  hideSortIcon
                >
                  Statut Parcours
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((broadcast, index) => {
              const dateOfCreation = formatDates(new Date(broadcast.created_at));

              return (
                <TableRow className="green-border" key={broadcast._id}>
                  <TableCell align="center" className="green-border">
                    <Checkbox
                      checked={selectedBroadcasts.find(
                        (selectedBroadcast) =>
                          selectedBroadcast.id === broadcast._id
                      )}
                      onChange={(e) =>
                        changeSelectedBroadcasts(
                          broadcast._id,
                          e.target.checked,
                          broadcast.name
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {(broadcast.type &&
                      diffuserTypes[broadcast?.type]?.label) ||
                      broadcast.type}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    <CreateEditDiffuser
                      type={actionTypes.edit}
                      id={broadcast._id}
                      text={broadcast.name}
                      confirmCreateBroadcast={handleConfirm}
                      setConfirmedBroadcastId={setConfirmedBroadcastId}
                    />
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    <Tags
                      options={formattedTags}
                      value={broadcast}
                      handleAddTag={handleAddTag}
                      handleTagChange={handleTagChange}
                      openConfirmDeleteTag={openConfirmDeleteTag}
                      setNewTag={setNewTag}
                      key={broadcast._id}
                      inputMinWidth="120px"
                      inputMaxWidth="150px"
                    />
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast.recipients_amount}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast.trainingFormatted[0] ? dateOfCreation : ''}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {diffuserTypes.specific.value === broadcast.type &&
                    broadcast.trainingFormatted[0]
                      ? formatDates(
                          new Date(
                            broadcast.trainingFormatted[
                              broadcast.trainingFormatted.length - 1
                            ].date
                          )
                        )
                      : ''}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    <span
                      className={
                        broadcast.status === diffuserStatuses.DRAFT.value
                          ? 'orange-label'
                          : ''
                      }
                    >
                      {diffuserStatuses[broadcast.status].label}
                    </span>
                  </TableCell>

                  <TableCell className="green-border">
                    <Box className="align-cell-center">
                      <Tooltip
                        title={
                          broadcast.status === diffuserStatuses.ACTIVE.value
                            ? 'Désactiver'
                            : 'Activer'
                        }
                      >
                        <Switch
                          checked={
                            broadcast.status === diffuserStatuses.ACTIVE.value
                          }
                          disabled={
                            broadcast.status ===
                              diffuserStatuses.CLOSED.value ||
                            broadcast.status ===
                              diffuserStatuses.COMPLETED.value
                          }
                          onChange={() => {
                            handleCheckboxChange(broadcast._id, index);
                          }}
                          color="success"
                          className={
                            broadcast.status === diffuserStatuses.DRAFT.value
                              ? 'switch-orange'
                              : ''
                          }
                          style={{
                            color:
                              broadcast.status === diffuserStatuses.ACTIVE.value
                                ? '#66DEAE'
                                : broadcast.status ===
                                  diffuserStatuses.DRAFT.value
                                ? 'var(--c-orange)'
                                : undefined,
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Tooltip>
                      <IconButton onClick={(e) => openMenu(e, index)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={anchorElIndex !== null && anchorElIndex === index}
                        onClose={closeMenu}
                      >
                        <Box className="align-cell-center">
                          <Tooltip title="Invitation">
                            <Button
                              sx={{
                                opacity:
                                  broadcast.status ===
                                    diffuserStatuses.CLOSED.value ||
                                  broadcast.status ===
                                    diffuserStatuses.DRAFT.value ||
                                  broadcast.status ===
                                    diffuserStatuses.COMPLETED.value
                                    ? '0.3'
                                    : '1',
                              }}
                              size="small"
                              disabled={
                                broadcast.status ===
                                  diffuserStatuses.CLOSED.value ||
                                broadcast.status ===
                                  diffuserStatuses.DRAFT.value ||
                                broadcast.status ===
                                  diffuserStatuses.COMPLETED.value
                              }
                              onClick={() => {
                                navigate(
                                  `/b/${broadcast._id}`
                                );
                                closeMenu();
                              }}
                              startIcon={<i className="fas fa-search"></i>}
                            />
                          </Tooltip>
                          <Tooltip title="Copie lien">
                            <Button
                              sx={{
                                opacity:
                                  broadcast.status ===
                                    diffuserStatuses.CLOSED.value ||
                                  broadcast.status ===
                                    diffuserStatuses.DRAFT.value ||
                                  broadcast.status ===
                                    diffuserStatuses.COMPLETED.value
                                    ? '0.3'
                                    : '1',
                              }}
                              size="small"
                              disabled={
                                broadcast.status ===
                                  diffuserStatuses.CLOSED.value ||
                                broadcast.status ===
                                  diffuserStatuses.DRAFT.value ||
                                broadcast.status ===
                                  diffuserStatuses.COMPLETED.value
                              }
                              startIcon={<i className="fas fa-link"></i>}
                              onClick={() => {
                                handleLinkCopy(broadcast.meta?.linkIdentifier || broadcast._id);
                                closeMenu();
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Dupliquer">
                            <Button
                              sx={{
                                color: '#4546CD',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              size="small"
                              startIcon={<i className="fas fa-clone"></i>}
                              onClick={() => {
                                handleDialogOpen(broadcast);
                                closeMenu();
                              }}
                            />
                          </Tooltip>

                          <CreateEditDiffuser
                            type={actionTypes.edit}
                            id={broadcast._id}
                            callbackOnClickIcon={() => closeMenu()}
                            confirmCreateBroadcast={handleConfirm}
                            setConfirmedBroadcastId={setConfirmedBroadcastId}
                            disabled={
                              broadcast.status ===
                                diffuserStatuses.CLOSED.value ||
                              broadcast.status ===
                                diffuserStatuses.COMPLETED.value
                            }
                          />

                          <Snackbar
                            open={isSnackbarOpen}
                            autoHideDuration={3000}
                            onClose={handleCloseSnackbar}
                          >
                            <Alert
                              onClose={handleCloseSnackbar}
                              severity="success"
                            >
                              Copié
                            </Alert>
                          </Snackbar>
                        </Box>
                      </Menu>

                      <Dialog
                        open={confirmDialogOpen === index}
                        maxWidth={
                          confirmActionType === diffuserActionTypes.DRAFT.value
                            ? 'md'
                            : 'sm'
                        }
                      >
                        <DialogTitle>Confirmation</DialogTitle>
                        <DialogContent>
                          <Typography>
                            {confirmActionType ===
                            diffuserActionTypes.ACTIVE.value
                              ? diffuserActionTypes.ACTIVE.text
                              : null}
                            {confirmActionType ===
                            diffuserActionTypes.DRAFT.value
                              ? diffuserActionTypes.DRAFT.text(
                                  broadcast.type,
                                  broadcast,
                                  creditsInfo
                                )
                              : null}
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCancel}
                            variant="outlined"
                            color="error"
                          >
                            Annuler
                          </Button>
                          <Button
                            onClick={() => handleConfirm(broadcast)}
                            variant="contained"
                            color="primary"
                          >
                            Confirmer
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <Dialog open={activePopup === broadcast._id}>
                        <DialogTitle>
                          Dupliquer la diffusion "{broadcast.name}"
                        </DialogTitle>
                        <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={clonedBroadcastName}
                            fullWidth
                            variant="standard"
                            onChange={(e) =>
                              setClonedBroadcastName(e.target.value)
                            }
                          />

                          {role === ROLES.ADMIN && (
                            <FormControl
                              className="full-width"
                              variant="standard"
                              sx={{ minWidth: 120, marginTop: 5 }}
                            >
                              <Select
                                id="organizations-form-select"
                                value={
                                  organization.length
                                    ? organization[
                                        getCompanyIndex(
                                          selectedOrganizationToCopy?._id,
                                          organization
                                        )
                                      ]
                                    : ''
                                }
                                onChange={changeCopmanyForCopy}
                                label="Organizations"
                              >
                                {organization.map((org) => (
                                  <MenuItem key={org.name} value={org}>
                                    {org.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleDialogClose}
                            variant="outlined"
                            color="error"
                          >
                            Annuler
                          </Button>
                          <Tooltip
                            title={isConfirmCopyDisabled ? 'Loading' : ''}
                          >
                            <span style={{ marginLeft: '10px' }}>
                              <Button
                                onClick={() => cloneBroadcast(broadcast._id)}
                                disabled={isConfirmCopyDisabled}
                                variant="contained"
                                color="primary"
                              >
                                Clone
                              </Button>
                            </span>
                          </Tooltip>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box>
          <Typography variant="body1" style={{ padding: '16px 16px 0 16px' }}>
            Aucun parcours disponible.
          </Typography>
          <Typography variant="body1" style={{ padding: '0 16px 16px 16px' }}>
            Vous pouvez en ajouter un nouveau.
          </Typography>
        </Box>
      )}
    </TableContainer>
  );
}

export default BroadcastTable;
