import { BASE_URL } from '../constants/config';
import { getUTCOffset } from '../helpers/dates';
import axios from './app.utils';

export const BROADCAST_ENDPOINTS = {
  broadcast: `${BASE_URL}api/broadcasts`,
  statistics: `${BASE_URL}api/broadcasts/statistics`,
  statistics_export: `${BASE_URL}api/broadcasts/statistics/export`,
  calculate_credits: `${BASE_URL}api/broadcasts/calculate_credits`,
  crossCopy: `${BASE_URL}api/broadcasts/cross_copy`,
  manualRegistration: `${BASE_URL}api/broadcasts/manual_registration`,
};

const token = localStorage.getItem('authorization');

class Broadcast {
  async getAll() {
    const response = await axios.get(BROADCAST_ENDPOINTS.broadcast, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }
  async exportStatistics(company_id, broadcast = null) {
    const payload = broadcast
      ? {
          broadcasts_id: [broadcast],
        }
      : {
          company_id,
        };

    const response = await axios.post(
      `${BROADCAST_ENDPOINTS.statistics_export}?utc=${getUTCOffset()}`,
      payload,
      {
        responseType: 'blob',
        headers: {
          authorization: token,
        },
      }
    );

    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    const filename = `BroadcastData_${new Date()}.xlsx`;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    return response;
  }
  async postStatistics(broadcasts) {
    const response = await axios.post(
      BROADCAST_ENDPOINTS.statistics,
      {
        broadcasts_id: [...broadcasts.broadcasts_id],
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    return response;
  }
  async manualRegistration(data) {
    const response = await axios.post(
      BROADCAST_ENDPOINTS.manualRegistration,
      { data },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
  async getBroadcastById(id) {
    const response = await axios.get(`${BROADCAST_ENDPOINTS.broadcast}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async getBroadcastByLink(id) {
    const response = await axios.get(`${BROADCAST_ENDPOINTS.broadcast}/b/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async createBroadcast(dataBroadcast) {
    const response = await axios.post(
      BROADCAST_ENDPOINTS.broadcast,
      { data: dataBroadcast },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async crossCopyBroadcast(payload) {
    const response = await axios.post(BROADCAST_ENDPOINTS.crossCopy, payload, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async updateBroadcast(id, dataBroadcast) {
    const response = await axios.put(
      `${BROADCAST_ENDPOINTS.broadcast}/${id}`,
      { data: dataBroadcast },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async deleteBroadcasts(broadcasts) {
    const response = await axios.delete(
      BROADCAST_ENDPOINTS.broadcast,
      { data: broadcasts },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async calculateCredits(data) {
    const response = await axios.post(
      BROADCAST_ENDPOINTS.calculate_credits,
      { data },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
}

export const BroadcastService = new Broadcast();
