import axios from 'axios';
import useStore from '../store/store';

const axiosClient = axios.create();

axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            useStore?.getState()?.resetPersistedStore()
            localStorage.clear()
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default axiosClient;