import { faCopy, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { forwardRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CheckIcon from '@mui/icons-material/Check';
import {
  newQuestionDevider,
  questionDevider,
  responseTypes,
} from '../../../constants/form';

const FormQuestionsBuilder = forwardRef(
  (
    {
      handleOnDragEnd,
      questions,
      handleQuestionChange,
      selectedAnswer,
      setSelectedAnswer,
      handleOptionChange,
      handleDeleteOption,
      handleAddOption,
      handleLinearScaleSizeChange,
      handleDuplicateQuestion,
      handleDeleteQuestion,
    },
    ref
  ) => {
    const changeCorrectAnswer = (
      question,
      questionIndex,
      option,
      optionIndex
    ) => {
      setSelectedAnswer((prev) => {
        // For MULTIPLE_CHOICE
        if (question.responseType === responseTypes.MULTIPLE_CHOICE.value) {
          if (questionIndex in prev) {
            const questionToFind = `${questionIndex}${questionDevider}${optionIndex}`;
            const questionOptionToFInd = `${questionIndex}${questionDevider}${optionIndex}${questionDevider}${option}`;
            const index = prev[questionIndex].indexOf(questionToFind);
            // Unselect if already selected
            if (index !== -1) {
              return {
                ...prev,
                [questionIndex]:
                  prev[questionIndex].slice(0, index) +
                  prev[questionIndex].slice(
                    index + questionOptionToFInd.length,
                    prev[questionIndex].length + index
                  ),
              };
            }

            // Add answers for existings
            return {
              ...prev,
              [questionIndex]: `${prev[questionIndex]}${newQuestionDevider}${questionIndex}${questionDevider}${optionIndex}${questionDevider}${option}`,
            };
          }

          // Set new answer, if we asnwer frist time
          return {
            ...prev,
            [questionIndex]: `${questionIndex}${questionDevider}${optionIndex}${questionDevider}${option}`,
          };
        }

        // For CHECKBOX
        if (question.responseType === responseTypes.CHECKBOX.value) {
          const prevState = prev[questionIndex];
          // Unselect CHECKBOX
          if (
            prevState &&
            prevState.includes(
              `${questionIndex}${questionDevider}${optionIndex}`
            )
          ) {
            return {
              ...prev,
              [questionIndex]: '',
            };
          }

          // Replace correct answer or add new one
          return {
            ...prev,
            [questionIndex]: `${questionIndex}${questionDevider}${optionIndex}${questionDevider}${option}`,
          };
        }
      });
    };

    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, questionIndex) => (
                <Draggable
                  key={questionIndex}
                  draggableId={`question-${questionIndex}`}
                  index={questionIndex}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="card"
                    >
                      <div className="question-inputs" ref={ref}>
                        <TextField
                          label={`Question ${questionIndex + 1}`}
                          multiline
                          value={question.question}
                          onChange={(e) =>
                            handleQuestionChange(
                              questionIndex,
                              'question',
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{
                            width: '100%',
                          }}
                        />

                        <div className="response-type">
                          <FormControl sx={{ minWidth: 120 }} size="small">
                            <InputLabel>Type</InputLabel>
                            <Select
                              value={question.responseType}
                              onChange={(e) =>
                                handleQuestionChange(
                                  questionIndex,
                                  'responseType',
                                  e.target.value
                                )
                              }
                              label="Type"
                            >
                              {Object.values(responseTypes).map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                  {type.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl sx={{ minWidth: 120 }} size="small">
                            <InputLabel>Point(s)</InputLabel>
                            <Select
                              value={question.points}
                              onChange={(e) =>
                                handleQuestionChange(
                                  questionIndex,
                                  'points',
                                  e.target.value
                                )
                              }
                              label="Point(s)"
                            >
                              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                (points) => (
                                  <MenuItem value={points} key={points}>
                                    {points}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>

                        {(question.responseType ===
                          responseTypes.MULTIPLE_CHOICE.value ||
                          question.responseType ===
                            responseTypes.CHECKBOX.value) &&
                          question.options.map((option, optionIndex) => {
                            return (
                              <div
                                key={optionIndex}
                                className="option-container"
                              >
                                <Tooltip title="Correct answer">
                                  <ToggleButton
                                    value="check"
                                    selected={
                                      (selectedAnswer[questionIndex] &&
                                        `${questionIndex}${questionDevider}${optionIndex}` ===
                                          selectedAnswer[questionIndex]) ||
                                      (selectedAnswer[questionIndex] &&
                                        selectedAnswer[questionIndex].includes(
                                          `${questionIndex}${questionDevider}${optionIndex}`
                                        ))
                                    }
                                    onChange={() => {
                                      changeCorrectAnswer(
                                        question,
                                        questionIndex,
                                        option,
                                        optionIndex
                                      );
                                    }}
                                  >
                                    <CheckIcon />
                                  </ToggleButton>
                                </Tooltip>
                                {question.responseType ===
                                  responseTypes.CHECKBOX.value && (
                                  <input type="checkbox" disabled />
                                )}
                                {question.responseType ===
                                  responseTypes.MULTIPLE_CHOICE.value && (
                                  <input type="radio" disabled />
                                )}
                                <TextField
                                  label={`Option ${optionIndex + 1}`}
                                  sx={{
                                    '& input': {
                                      height: 50,
                                    },
                                  }}
                                  error={!option.trim().length}
                                  value={option}
                                  multiline
                                  className="fix-label"
                                  onChange={(e) =>
                                    handleOptionChange(
                                      questionIndex,
                                      optionIndex,
                                      e.target.value
                                    )
                                  }
                                />
                                <button
                                  className="delete-option"
                                  onClick={() =>
                                    handleDeleteOption(
                                      questionIndex,
                                      optionIndex
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            );
                          })}

                        {(question.responseType ===
                          responseTypes.MULTIPLE_CHOICE.value ||
                          question.responseType ===
                            responseTypes.CHECKBOX.value) && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{
                              display: 'block',
                              backgroundColor: '#66DEAE',
                              margin: '0 auto 15px auto',
                            }}
                            className="add-option"
                            onClick={() => handleAddOption(questionIndex)}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Ajouter une option
                          </Button>
                        )}
                        {question.responseType ===
                          responseTypes.LINEAR_SCALE.value && (
                          <div className="linear-scale-container">
                            <label className="linear-scale-size-field">
                              <TextField
                                type="number"
                                label="Taille de l'échelle linéaire"
                                value={question.linear_scale_size}
                                onChange={(e) =>
                                  handleLinearScaleSizeChange(
                                    questionIndex,
                                    e.target.value
                                  )
                                }
                              />
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="question-content">
                        <label className="required-field">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={question.required}
                                onChange={(e) =>
                                  handleQuestionChange(
                                    questionIndex,
                                    'required',
                                    e.target.checked
                                  )
                                }
                                sx={{
                                  color: '#66DEAE',
                                  '&.Mui-checked': {
                                    color: '#66DEAE',
                                  },
                                }}
                              />
                            }
                            label="
                            Obligatoire"
                          />
                        </label>

                        <Box className="question-controls">
                          <button
                            className="duplicate-question"
                            onClick={() =>
                              handleDuplicateQuestion(questionIndex)
                            }
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                          <button
                            className="delete-question trashcan-button"
                            onClick={() => handleDeleteQuestion(questionIndex)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </Box>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default FormQuestionsBuilder;
