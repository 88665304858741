import axios from 'axios';
import { BASE_URL } from '../constants/config';

export const USERS_ENDPOINTS = {
  register: `${BASE_URL}api/users/register`,
  userAction: `${BASE_URL}api/users`,
  login: `${BASE_URL}api/users/login`,
  user: `${BASE_URL}api/users/me`,
  password: `${BASE_URL}api/users/update-password`,
  users: `${BASE_URL}api/users/update`,
  upload: `${BASE_URL}api/files/upload`,
  download: `${BASE_URL}api/files/download`,
  me: `${BASE_URL}api/users/me`,
  getUsers: `${BASE_URL}api/users`,
  forgotPassword: `${BASE_URL}api/users/forgot_password`,
  checkPhone: `${BASE_URL}api/users/check-phone`,
  verifyRecovery: `${BASE_URL}api/users/verify_recovery`,
  resetPassword: `${BASE_URL}api/users/reset_password`,
};

const token = localStorage.getItem('authorization');

class Users {
  async register(data) {
    const response = await axios.post(USERS_ENDPOINTS.register, data, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async deleteUser(id) {
    const response = await axios.delete(`${USERS_ENDPOINTS.userAction}/${id}`);
    return response;
  }

  async login(data) {
    const response = await axios.post(USERS_ENDPOINTS.login, data);
    return response;
  }

  async getUser() {
    const response = await axios.get(USERS_ENDPOINTS.user, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async getUsers() {
    const response = await axios.get(USERS_ENDPOINTS.getUsers, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async getOneUser(id) {
    const response = await axios.get(`${USERS_ENDPOINTS.getUsers}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async updateUser(id, updatedUser) {
    const response = await axios.put(
      `${USERS_ENDPOINTS.users}/${id}`,
      updatedUser,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
  async updatePassword(id, passwordUpdateData) {
    const response = await axios.patch(
      `${USERS_ENDPOINTS.password}/${id}`,
      passwordUpdateData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async uploadAvatar(formData) {
    const response = await axios.post(USERS_ENDPOINTS.upload, formData, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async downloadAvatar(identifier) {
    const response = await axios.get(
      `${USERS_ENDPOINTS.download}/${identifier}`,
      {
        headers: {
          authorization: token,
        },
        responseType: 'arraybuffer',
      }
    );
    return response;
  }

  async me() {
    const response = await axios.get(`${USERS_ENDPOINTS.me}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async forgotPassword(phone) {
    const response = await axios.post(
      USERS_ENDPOINTS.forgotPassword,
      {
        phoneNumber: phone,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async checkPhone(phone) {
    const response = await axios.post(
      USERS_ENDPOINTS.checkPhone,
      {
        phoneNumber: phone,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async verifyRecovery(phone) {
    const response = await axios.post(
      USERS_ENDPOINTS.verifyRecovery,
      {
        secret: phone,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async resetPassword(data) {
    const response = await axios.post(USERS_ENDPOINTS.resetPassword, data, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
}

export const UsersService = new Users();
