import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  RadioGroup,
  Radio,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useNavigate, useParams } from 'react-router-dom';
import { BroadcastService } from '../../services/broadcast';
import useStore from '../../store/store';
import { LearnersService } from '../../services/learners';
import {
  formatDates,
  removeSpaces,
  setPlaceholderForInput,
} from '../../helpers';
import { ROLES } from '../../constants/auth';
import { diffuserDateTypes, diffuserTypes } from '../../constants/broadcast';
import {
  CalendarIcon,
  DatePicker,
  DateTimePicker,
  DesktopTimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { downloadImageBase64 } from '../../helpers/company';
import { CompanyServices } from '../../services/companies';
import { PhoneNumberUtil } from 'google-libphonenumber';
import ReturnButton from './components/ReturnButton';
import { isDisabledDate } from '../../helpers/dates';
import { ANEMON_HOLIDAYS } from '../../constants/dates';
import TypographyUI from '../../components/TypographyUI';

function CompaignInvitation() {
  let { id } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [broadcast, setBroadcast] = useState([]);
  const [isBroadcastloaded, setIsBroadcastloaded] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState('today');
  const [customDate, setCustomDate] = useState(() =>
    dayjs().add(10, 'minutes')
  );
  const navigate = useNavigate();
  const [isWeekendsMenu, setIsWeekendsMenu] = useState(false);
  const [menuValues, setMenuValues] = useState([]);
  const [menuDate, setMenuDate] = useState(() => dayjs().add(10, 'minutes'));

  const { setSnackbarContent, companyLogo, setCompanyLogo } = useStore(
    (state) => state
  );

  const role = useStore((state) => state.userRole);
  const isAuhtorized = role !== ROLES.UNAUTHORIZED;
  const [calculatedTrainings, setCalculatedTrainings] = useState([]);
  const [isCalculatedTrainingsLoading, setIsCalculatedTrainingsLoading] =
    useState(false);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const currentTime = dayjs();

  const isAllInputsFilled =
    broadcast.type === diffuserTypes.manual.value
      ? isAccepted &&
        lastName &&
        firstName &&
        isPhoneValid(phoneNumber) &&
        menuDate?.isValid() &&
        currentTime.isBefore(menuDate) &&
        menuValues.length >= broadcast?.manual_broadcast?.min_trainings &&
        menuValues.length <= broadcast?.manual_broadcast?.max_trainings
      : isAccepted &&
        lastName &&
        firstName &&
        isPhoneValid(phoneNumber) &&
        customDate?.isValid() &&
        currentTime.isBefore(customDate) 

  const getBroadcastById = async () => {
    const queryIdd = id;
    const isLink = queryIdd.length <= 6;

    try {
      const response = isLink
        ? await BroadcastService.getBroadcastByLink(id)
        : await BroadcastService.getBroadcastById(id);
      setIsBroadcastloaded(true);
      setBroadcast(response.data.data);
      return response.data.data;
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', 'Error');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getBroadcastById();

      if (!companyLogo && response && response?.company_id) {
        downloadImageBase64(
          CompanyServices.getCompanyLogo,
          response.company_id,
          setCompanyLogo
        );
      }
    };

    fetchData();
  }, []);

  const changeSelectedStartDate = (event) => {
    const value = event?.target?.value || event;
    setSelectedStartDate(value);

    const today = dayjs();

    switch (value) {
      case diffuserDateTypes.today.value:
        setCustomDate(today.add(10, 'minutes'));
        break;
      case diffuserDateTypes.tomorrow.value:
        setCustomDate(today.add(1, 'day'));
        break;
      default:
        setCustomDate(today.add(2, 'day'));
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsAccepted(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data =
        broadcast.type === diffuserTypes.manual.value
          ? {
              firstName: firstName,
              lastName: lastName,
              phoneNumber: removeSpaces(phoneNumber),
              start_date: menuDate,
              tags: [],
              company_id: broadcast?.company_id,
              broadcast_id: broadcast._id,
              include_weekends: isWeekendsMenu,
              selected_trainings: menuValues,
            }
          : {
              firstName: firstName,
              lastName: lastName,
              phoneNumber: removeSpaces(phoneNumber),
              start_date: customDate,
              tags: [],
              company_id: broadcast?.company_id,
              broadcast_id: broadcast._id,
            };

      const currentTime = dayjs();

      if (
        broadcast.type === diffuserTypes.timing.value &&
        (customDate.startOf('minute').isBefore(currentTime) ||
          customDate.startOf('minute').isSame(currentTime))
      ) {
        return setSnackbarContent(
          'error',
          'Veuillez sélectionner une date différente'
        );
      }

      if (
        broadcast.type === diffuserTypes.manual.value &&
        (menuDate.startOf('minute').isBefore(currentTime) ||
          menuDate.startOf('minute').isSame(currentTime))
      ) {
        return setSnackbarContent(
          'error',
          'Veuillez sélectionner une date différente'
        );
      }

      broadcast.type === diffuserTypes.manual.value
        ? await BroadcastService.manualRegistration(data)
        : await LearnersService.inviteLearner(data);
      setSnackbarContent('success', "L'apprenant a été invité avec succès");
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      navigate('/success');
    } catch (error) {
      console.error('Error creating learner:', error);
      setSnackbarContent('error', error?.response?.data?.message || 'Error');
    }
  };
  const formatDate = (dateString) => {
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };
  const labelStyle = {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.5,
    maxWidth: '700px',
    textAlign: 'left',
  };

  const returnBack = () => {
    navigate('/diffuser');
  };

  const changePhoneNumber = (phone) => {
    setPhoneNumber(phone);
  };

  useEffect(() => {
    setPlaceholderForInput({ timeout: 1000 });
  }, []);

  useEffect(() => {
    if (broadcast.type && broadcast.type !== diffuserTypes.manual.value) {
      const getCalculatedTrainingsWithTime = async () => {
        setIsCalculatedTrainingsLoading(true);
        try {
          const payload = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: removeSpaces(phoneNumber),
            start_date: customDate,
            tags: [],
            company_id: broadcast.broadcast_id,
            broadcast_id: id,
          };
          const response = await LearnersService.invitationPreview(payload);
          setCalculatedTrainings(response.data.data);
        } catch (e) {
          console.error(e);
          setSnackbarContent('error', e?.response?.data?.message || 'Error');
        } finally {
          setIsCalculatedTrainingsLoading(false);
        }
      };

      getCalculatedTrainingsWithTime();
    }
  }, [customDate, broadcast.type]);

  if (!isBroadcastloaded) {
    return <div></div>;
  }

  const today = new Date();
  const startDate = new Date(broadcast?.trainings[0].date);

  const changeBroadcastTime = (newTime) => {
    const inputDate = new Date(newTime);

    const currentDate = new Date();

    const inputDateMidnight = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate()
    );

    const currentDateMidnight = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const timeDiff = inputDateMidnight - currentDateMidnight;

    const daysDiff = timeDiff / (24 * 60 * 60 * 1000);

    if (daysDiff === 0) {
      changeSelectedStartDate(diffuserDateTypes.today.value);
    } else if (daysDiff === 1) {
      changeSelectedStartDate(diffuserDateTypes.tomorrow.value);
    } else {
      changeSelectedStartDate(diffuserDateTypes.custom.value);
    }

    setCustomDate(dayjs(newTime));
  };

  const changeMenuTrainings = (e, trainingId) => {
    if (trainingId) {
      const isAlreadySelected = menuValues.includes(trainingId);

      if (isAlreadySelected) {
        setMenuValues(menuValues.filter((str) => str !== trainingId));
      } else {
        if (menuValues.length === broadcast?.manual_broadcast?.max_trainings)
          return;

        setMenuValues([...menuValues, trainingId]);
      }
    } else {
      setMenuValues(e.target.value);
    }
  };

  const isManual = broadcast.type === diffuserTypes.manual.value;
  const isTiming = broadcast.type === diffuserTypes.timing.value;

  const isValidSpecific =
    startDate &&
    startDate > today &&
    broadcast.type === diffuserTypes.specific.value;

  const isNotValidSpecific =
    startDate &&
    startDate < today &&
    broadcast.type === diffuserTypes.specific.value;

  const isValidDate =
    (startDate &&
      startDate > today &&
      broadcast.type === diffuserTypes.specific.value) ||
    broadcast.type === diffuserTypes.timing.value;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ReturnButton returnBack={returnBack} isAuhtorized={isAuhtorized} />

      <Box>
        <TypographyUI color="#3c4043" align="center">
          {broadcast.name}
        </TypographyUI>

        {isValidSpecific ? (
          <Typography variant="body1" color="black">
            Inscription ouverte jusqu'au {formatDate(startDate)}
          </Typography>
        ) : null}

        {isNotValidSpecific ? (
          <Typography variant="body1">
            Il n'est plus possible de s'inscrire à ce parcours. Vous avez une
            question ? envoyez-nous un email à{' '}
            <a href="mailto:support@anemon.fr" target="_blank" rel="noreferrer">
              support@anemon.fr
            </a>
          </Typography>
        ) : null}

        {isTiming || isManual ? (
          <Typography variant="body1" color="black">
            Inscription ouverte
          </Typography>
        ) : null}

        {isValidDate || isManual ? (
          <>
            {!isManual && (
              <>
                <Typography variant="body1" color="black">
                  Formulaire Inscription
                </Typography>

                <Typography variant="body1" color="black">
                  1 micro-formation par jour
                </Typography>

                <Typography variant="body1" color="black">
                  Inscription possible à partir de{' '}
                  {broadcast.trainings && broadcast.trainings.length > 0
                    ? formatDate(customDate)
                    : 'N/A'}
                </Typography>

                <Typography variant="body1" color="black">
                  Durée : {broadcast.trainings.length} jour(s)
                </Typography>
              </>
            )}

            <pre
              style={{
                maxWidth: '770px',
                margin: '15px auto 0 auto',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
              }}
            >
              {broadcast.description}
            </pre>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className="broadcast-invite-wrapper">
                <TextField
                  label="Prénom"
                  variant="outlined"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  style={{
                    maxWidth: '770px',
                    width: '100%',
                    marginBottom: '20px',
                  }}
                />
                <TextField
                  label="Nom"
                  variant="outlined"
                  value={lastName}
                  onChange={handleLastNameChange}
                  style={{
                    maxWidth: '770px',
                    width: '100%',
                    marginBottom: '20px',
                  }}
                />
                <Box
                  style={{
                    maxWidth: '770px',
                    width: '100%',
                    marginBottom: '20px',
                  }}
                >
                  <small
                    style={{
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      marginBottom: '5px',
                    }}
                  >
                    (ex: 33 6 85 53 99 59)
                  </small>
                  <PhoneInput
                    defaultCountry="fr"
                    labels={{ number: 'Numéro de téléphone' }}
                    value={phoneNumber}
                    disableCountryGuess={true}
                    forceDialCode={true}
                    onChange={(phone) => changePhoneNumber(phone)}
                    inputStyle={{ width: '100%', height: '50px' }}
                    countrySelectorStyleProps={{
                      buttonStyle: { height: '50px' },
                    }}
                  />
                </Box>

                {broadcast.type === diffuserTypes.timing.value ? (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="selected-date"
                      name="selected-date"
                      value={selectedStartDate}
                      onChange={changeSelectedStartDate}
                      className="broadcast-date-select"
                    >
                      {Object.values(diffuserDateTypes).map((date) => (
                        <FormControlLabel
                          value={date.value}
                          control={<Radio />}
                          label={date.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : null}

                {broadcast.type === diffuserTypes.timing.value ? (
                  <>
                    <DatePicker
                      label="Date de réception"
                      value={customDate}
                      minDate={dayjs()}
                      onChange={changeBroadcastTime}
                      shouldDisableDate={(date) =>
                        isDisabledDate(date, ANEMON_HOLIDAYS)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      sx={{ my: 2 }}
                    />
                    <DesktopTimePicker
                      label="Horaire de réception"
                      className="mobile-clickable"
                      value={customDate}
                      minTime={
                        dayjs().isSame(customDate, 'day')
                          ? dayjs()
                          : dayjs().startOf('day')
                      }
                      onChange={changeBroadcastTime}
                      timeSteps={{ hours: 1, minutes: 1 }}
                      renderInput={(params) => <TextField {...params} />}
                      sx={{ my: 2 }}
                    />
                  </>
                ) : null}

                {isCalculatedTrainingsLoading && (
                  <Skeleton
                    sx={{
                      height: 190,
                      width: '100%',
                      maxWidth: '770px',
                      marginBottom: '20px',
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                )}

                {isManual && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '20px',
                      marginBottom: '15px',
                    }}
                  >
                    <Box>
                      <Typography>
                        Je sélectionne entre{' '}
                        {broadcast?.manual_broadcast?.min_trainings} et{' '}
                        {broadcast?.manual_broadcast?.max_trainings} contenus
                      </Typography>
                    </Box>
                  </Box>
                )}

                {isManual && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '20px',
                      flexDirection: 'column',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow className="green-border">
                          <TableCell></TableCell>
                          <TableCell align="center">Micro-formation</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {broadcast.trainings.map((training, index) => (
                          <TableRow key={index} className="green-border">
                            <TableCell align="center" className="green-border">
                              <Checkbox
                                checked={menuValues.includes(training.id)}
                                onChange={(e) => {
                                  changeMenuTrainings(e, training.id);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" className="green-border">
                              {training.name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography textAlign="left">
                        Je souhaite recevoir les contenus durant le week-end
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isWeekendsMenu}
                            onChange={(e) => {
                              setIsWeekendsMenu(true);
                            }}
                            style={{ marginRight: '10px', top: '0' }}
                          />
                        }
                        label="Oui"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!isWeekendsMenu}
                            onChange={(e) => {
                              setIsWeekendsMenu(false);
                            }}
                            style={{ marginRight: '10px', top: '0' }}
                          />
                        }
                        label="Non"
                      />
                    </Box>

                    <Typography textAlign="left">
                      Je choisis le jour de démarrage et l'horaire de réception.
                      <br />
                      Je recevrais un contenu chaque jour.
                    </Typography>

                    <DateTimePicker
                      ampm={false}
                      label="Date"
                      timeSteps={{ hours: 1, minutes: 1 }}
                      value={menuDate}
                      onChange={(date) => setMenuDate(date)}
                      disablePast={true}
                      components={{
                        OpenPickerIcon: ({ ...other }) => (
                          <CalendarIcon
                            style={{ color: '#66DEAE' }}
                            {...other}
                          />
                        ),
                      }}
                    />
                  </Box>
                )}

                {calculatedTrainings.length && !isCalculatedTrainingsLoading ? (
                  <TableContainer
                    style={{
                      margin: '20px 0',
                      maxWidth: '770px',
                      overflowX: 'initial',
                    }}
                  >
                    <Table className="compaign-invite__table">
                      <TableHead>
                        <TableRow className="green-border">
                          <TableCell>Titre</TableCell>
                          <TableCell>Jour de réception</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            Date de réception
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {calculatedTrainings.map((training, key) => {
                          // Date of the day in French text
                          const daysOptions = { weekday: 'long' };
                          const frenchDate = new Intl.DateTimeFormat(
                            'fr-FR',
                            daysOptions
                          ).format(new Date(training.date));

                          // Time in the format HH:mm
                          const timeOptions = {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          };
                          const formattedTime = new Date(
                            training.date
                          ).toLocaleTimeString('en-US', timeOptions);

                          const capitalizedDate =
                            frenchDate.charAt(0).toUpperCase() +
                            frenchDate.slice(1);

                          return (
                            <TableRow key={key} className="green-border">
                              <TableCell className="green-border">
                                {training.name}
                              </TableCell>
                              <TableCell className="green-border">
                                {capitalizedDate}
                              </TableCell>
                              <TableCell
                                className="green-border"
                                sx={{ textAlign: 'center' }}
                              >
                                <Box>
                                  {training.date &&
                                    formatDates(new Date(training.date))}
                                </Box>
                                <Box>{formattedTime}</Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAccepted}
                      onChange={handleCheckboxChange}
                      style={{ marginRight: '10px', top: '0' }}
                    />
                  }
                  label={<div style={labelStyle}>{broadcast.conditions}</div>}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <Button
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  style={{
                    marginRight: '10px',
                    borderRadius: '20px',
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    border: '1px solid rgba(211, 47, 47, 0.5)',
                    color: 'rgba(211, 47, 47, 0.5)',
                  }}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isAllInputsFilled}
                  style={{
                    marginRight: '10px',
                    borderRadius: '20px',
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    backgroundColor: isAllInputsFilled ? '#66DEAE' : undefined,
                    color: isAllInputsFilled ? 'black' : undefined,
                  }}
                >
                  Je souhaite participer
                </Button>
              </div>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default CompaignInvitation;
