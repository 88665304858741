import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      //   main: "#c2e3d1",
      main: '#66DEAE',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

export default theme;
