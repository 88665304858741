export const isWeekend = (date) => {
  const day = date.day();
  return day === 0 || day === 6; // Sunday or Saturday
};
  
export const isDisabledDate = (date, disabledDates = []) => {
    const formattedDate = date.format('DD.MM.YYYY'); // 'dd.MM.yyyy' format
    return disabledDates.includes(formattedDate) || isWeekend(date);
};

const getUtcDifference = () => {
  var now = new Date();
  var utcHours = now.getUTCHours();
  var localHours = now.getHours();
  return localHours - utcHours;
}

export const getUTCOffset = getUtcDifference;

export const convertDateFormat = (inputString) => {
  const dateObject = new Date(inputString);
  const utcDifference = getUtcDifference();
  dateObject.setHours(dateObject.getHours() + utcDifference);
  
  const day = dateObject.getUTCDate().toString().padStart(2, '0');
  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getUTCFullYear();
  const hours = dateObject.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');

  const formattedString = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedString;
}
  