import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { UsersService } from '../../services/users';
import useStore from '../../store/store';

const RestorePassword = () => {
  const navigate = useNavigate();

  const { secret: code } = useParams();

  const [password, setPassword] = useState('');
  const [retryPassword, setRetryPassword] = useState('');

  const setSnackbarContent = useStore((store) => store.setSnackbarContent);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');

  const [showRetryPassword, setShowRetryPassword] = useState(false);
  const handleClickShowRetryPassword = () =>
    setShowRetryPassword(!showRetryPassword);
  const handleMouseDownRetryPassword = () =>
    setShowRetryPassword(!showRetryPassword);

  const [isBtnClicked, setIfBtnClicked] = useState(false);
  const isBtnActive = [password, retryPassword].every(
    (pass) => pass.trim() !== ''
  );

  const verifyCode = async (code) => {
    try {
      const verifyResponse = await UsersService.verifyRecovery(code);
      setEmail(verifyResponse.data.message);
    } catch (e) {
      console.log(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const checkPassword = (pass) => {
    let error = '';

    if (pass.length < 12 || pass.length > 30) {
      error = 'Le mot de passe doit contenir entre 12 et 30 caractères.';
    } else if (!/[a-z]/.test(pass)) {
      error = 'Le mot de passe doit inclure au moins une lettre minuscule.';
    } else if (!/[A-Z]/.test(pass)) {
      error = 'Le mot de passe doit inclure au moins une lettre majuscule.';
    } else if (!/\d/.test(pass)) {
      error = 'Le mot de passe doit inclure au moins un chiffre.';
    } else if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(pass)) {
      error = 'Le mot de passe doit inclure au moins un caractère spécial.';
    }

    setIsPasswordValid(error === '');
    setPasswordError(error);

    return error;
  };

  const handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    checkPassword(newPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIfBtnClicked(true);

    if (password !== retryPassword) {
      return setSnackbarContent(
        'error',
        'Les mots de passe ne sont pas identiques'
      );
    }

    const error = checkPassword(password);
    console.log('error', error);
    if (error.length) return;

    try {
      await UsersService.verifyRecovery(code);
      const data = { secret: code, password: password };
      await UsersService.resetPassword(data);
      navigate('/success-password');
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  useEffect(() => {
    verifyCode(code);
  }, []);

  return (
    <Box
      sx={{
        maxWidth: '600px',
        margin: 'auto',
      }}
    >
      <Box component="form" onSubmit={handleSubmit}>
        <Typography align="left" fontSize={25} marginTop={2}>
          Veuillez entrer votre mot de passe
        </Typography>

        <Typography align="left" fontSize={13} marginTop={2}>
          Email de l'utilisateur
        </Typography>
        <TextField fullWidth type="text" disabled value={email} />
        <Typography align="left" fontSize={13} marginTop={2}>
          Mot de passe
        </Typography>
        <TextField
          required
          fullWidth
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoFocus
          value={password}
          onChange={handleNewPasswordChange}
          error={isBtnClicked && !isPasswordValid && password.length > 0}
          helperText={isBtnClicked && password.length > 0 ? passwordError : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography align="left" fontSize={13} marginTop={2}>
          Répéter le mot de passe
        </Typography>
        <TextField
          required
          fullWidth
          id="retryPassword"
          name="retryPassword"
          type={showRetryPassword ? 'text' : 'password'}
          value={retryPassword}
          onChange={(e) => setRetryPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle retryPassword visibility"
                  onClick={handleClickShowRetryPassword}
                  onMouseDown={handleMouseDownRetryPassword}
                >
                  {showRetryPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          disabled={!isBtnActive}
          variant="contained"
          type="submit"
          sx={{ mt: 3 }}
        >
          Enregistrer
        </Button>

        <Link
            style={{
              fontSize: 13,
              textDecoration: 'none',
              marginTop: '15px',
              display: 'block',
              color: '#66DEAE',
              marginLeft: '10px'
            }}
            href="/forgotpassword"
          >
            Oups, mon lien a expiré ?
          </Link>
      </Box>

      <Box component="form" sx={{ marginTop: '40px' }}>
        <Typography align="left" fontSize={25}>
          Règles de mot de passe
        </Typography>

        <Box>
          <Typography align="left" fontSize={13} marginTop={2}>
            Le mot de passe doit contenir entre 12 et 30 caractères.
          </Typography>
          <Typography align="left" fontSize={13} marginTop={2}>
            Le mot de passe doit inclure au moins une lettre minuscule.
          </Typography>
          <Typography align="left" fontSize={13} marginTop={2}>
            Le mot de passe doit inclure au moins une lettre majuscule.
          </Typography>
          <Typography align="left" fontSize={13} marginTop={2}>
            Le mot de passe doit inclure au moins un chiffre.
          </Typography>
          <Typography align="left" fontSize={13} marginTop={2}>
            Le mot de passe doit inclure au moins un caractère spécial
            <br />
            {"($ & + , : ; = ? @ # | ' < > . ^ * ( ) % ! -)"}.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RestorePassword;
