import React, { useEffect, useState } from 'react';
import '../styles/Header.scss';
import logo from '../assets/Anemon-LOGO-C-S.png';
import {
  Avatar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
} from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useStore from '../store/store';
import { FaBars, FaTimes } from 'react-icons/fa';
import { getToken } from '../helpers/user';
import { ROLES } from '../constants/auth';
import { useLearnerContext } from '../context/LearnerContext';
import { UsersService } from '../services/users';
import { CompanyServices } from '../services/companies';
import OrganizationPicker from './OrganizationPicker';
import { cleanPages, noHeaderPages } from './AppRouter/routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

function Header({ croute, setCroute }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [image, setImage] = useState(null);
  const storedAvatar = useStore((state) => state.avatar);
  const isMenuOpen = useStore((state) => state.isMenuOpen);
  const toggleMenu = useStore((state) => state.toggleMenu);
  const closeToggleMenu = useStore((state) => state.closeToggleMenu);
  const resetPersistedStore = useStore((state) => state.resetPersistedStore);
  const isToken = getToken();
  const { organization } = useLearnerContext();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const location = useLocation();

  const {
    selectedOrganization,
    setSelectedOrganization,
    setSelectedOrganizationId,
    setSnackbarContent,
    userRole,
    userCompanyId,
    companyLogo,
    setUserRole,
  } = useStore((state) => state);

  const handleChange = (event) => {
    setSelectedOrganization(event.target.value);

    const selectedCompany = organization.find(
      (org) => org.name === event.target.value
    );

    setSelectedOrganizationId(selectedCompany._id);
  };

  const [organizationLogo, setOrganizationLogo] = useState(null);

  useEffect(() => {
    const avatar = localStorage.getItem('avatar');
    if (avatar) setImage(avatar);
  }, [storedAvatar]);

  const open = Boolean(anchorEl);
  const MenuElements = [
    {
      name: 'Mon profil',
      route: '/profile',
      icon: <PermIdentityOutlinedIcon fontSize="small" />,
    },
    {
      name: 'Déconnexion',
      route: '/',
      icon: <LogoutOutlinedIcon fontSize="small" />,
    },
  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigation = (e) => {
    setCroute(e.route);
    navigate(e.route);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUserRole(ROLES.PENDING);

    resetPersistedStore();

    // navigate('/');
    window.location.reload();
  };

  const downloadOrganizationLogo = async ({ organizationName, clientLogo }) => {
    let foundOrganization;

    if (!clientLogo) {
      foundOrganization = organization.find(
        (org) => org.name === organizationName
      );
    } else {
      const response = await CompanyServices.getById(userCompanyId);
      foundOrganization = response.data.data;
    }

    if (foundOrganization?.logo) {
      try {
        setIsImageLoading(true);
        const downloadResponse = await UsersService.downloadAvatar(
          foundOrganization.logo
        );

        const blob = new Blob([downloadResponse.data], {
          type: 'application/octet-stream',
        });

        const reader = new FileReader();

        reader.onload = function () {
          const base64String = reader.result.split(',')[1];

          const logo = `data:image/jpeg;base64, ${base64String}`;
          setOrganizationLogo(logo);
        };

        reader.readAsDataURL(blob);
      } catch (e) {
        console.error(e);
        setSnackbarContent('error', 'Erreur due au téléchargement du logo');
      } finally {
        setIsImageLoading(false);
      }
    } else {
      setOrganizationLogo('');
    }
  };

  useEffect(() => {
    if (userRole === ROLES.ADMIN && selectedOrganization) {
      downloadOrganizationLogo({
        organizationName: selectedOrganization,
        clientLogo: false,
      });
    }
  }, [selectedOrganization, organization]);

  useEffect(() => {
    if (userRole === ROLES.CLIENT && userCompanyId) {
      downloadOrganizationLogo({
        clientLogo: true,
        organizationName: selectedOrganization,
      });
    }
  }, [userRole, userCompanyId]);

  const isHeaderHiden = noHeaderPages.includes(location.pathname) && !isToken;

  const isHeaderAndNavHidden =
    cleanPages.includes(location.pathname) ||
    cleanPages.some((page) => {
      return location.pathname.includes(page);
    });

  if (isHeaderAndNavHidden) {
    return (
      <header className="header">
        <div className="logo">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              margin: '0 auto',
            }}
          >
            <Link
              to="/"
              style={{ display: 'block', cursor: 'pointer', width: '100px' }}
            >
              <img
                src={logo}
                alt="Anemon"
                className="anemon-logo"
                width="100%"
                height="100%"
                onClick={() => handleNavigation({ route: '/' })}
              />
            </Link>

            {companyLogo || organizationLogo ? (
              <FontAwesomeIcon icon={faClose} width={10} height={10} />
            ) : null}

            {companyLogo || organizationLogo ? (
              <>
                <Avatar
                  src={companyLogo || organizationLogo}
                  alt="Organization logo"
                  className="organization-avatar"
                  variant="square"
                  sx={{
                    img: {
                      width: 'auto',
                      minWidth: '40px',
                      height: ' 40px !important',
                    },
                  }}
                />
              </>
            ) : null}
          </Box>
        </div>
      </header>
    );
  }

  return isHeaderHiden ? (
    <></>
  ) : (
    <header className="header">
      {isToken &&
        !cleanPages.includes(location.pathname) &&
        (isMenuOpen ? (
          <FaTimes
            className={`menu-icon close-icon ${isMenuOpen ? 'show-menu' : ''}`}
            onClick={closeToggleMenu}
          />
        ) : (
          <FaBars
            className={`menu-icon ${!isMenuOpen ? 'show-menu' : ''}`}
            onClick={() => toggleMenu(isMenuOpen)}
          />
        ))}

      <div className="logo">
        <Box className="header-logos-wrapper">
          <Link
            to="/"
            style={{ display: 'block', cursor: 'pointer', width: '100px' }}
          >
            <img
              src={logo}
              alt="Anemon"
              className="anemon-logo"
              width="100%"
              height="100%"
              onClick={() => handleNavigation({ route: '/' })}
            />
          </Link>

          <FontAwesomeIcon icon={faClose} width={10} height={10} />

          {isToken && !cleanPages.includes(location.pathname) ? (
            <Tooltip title="Organization" placement="left">
              {isImageLoading ? (
                <></>
              ) : (
                <Avatar
                  alt="Organization logo"
                  src={organizationLogo}
                  className="organization-avatar"
                  variant="square"
                  sx={{
                    img: {
                      width: 'auto',
                      minWidth: '40px',
                      height: ' 40px !important',
                    },
                  }}
                />
              )}
            </Tooltip>
          ) : null}

          {!cleanPages.includes(location.pathname) ? (
            <Box className="DesktopWrapper">
              <OrganizationPicker
                organization={organization}
                selectedOrganization={selectedOrganization}
                handleChange={handleChange}
              />
            </Box>
          ) : null}
        </Box>
        {isToken && !cleanPages.includes(location.pathname) && (
          <div>
            {croute === '/profile' ? (
              <Tooltip title="Retourner à la page" placement="left">
                <ReplyOutlinedIcon
                  className="back-button"
                  onClick={() => handleNavigation({ route: '/' })}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Voir mon profil" placement="left">
                <Avatar
                  alt="User Sharp"
                  src={image}
                  className="profile"
                  onClick={handleClick}
                />
              </Tooltip>
            )}

            <Menu
              id="bulk-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {MenuElements.map((e) => (
                <MenuItem
                  key={e.route}
                  onClick={
                    e.name === 'Déconnexion'
                      ? handleLogout
                      : () => handleNavigation(e)
                  }
                >
                  <ListItemIcon>{e.icon}</ListItemIcon>
                  <ListItemText>{e.name}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
