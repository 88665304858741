import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Button,
  Tooltip,
  Typography,
  Box,
  TableContainer,
  Paper,
} from '@mui/material';
import CreateEditLearner from './CreateEditLearner';
import { actionTypes } from '../../constants/types';
import { useLearnerContext } from '../../context/LearnerContext';
import useSortableTable from '../../hooks/useSortTable';

import Tags from '../../components/Tags/Tags';

function LearnerTable({
  data,
  searchValue,
  deleteLearner,
  formattedTags,
  setNewTag,
  handleAddTag,
  handleTagChange,
  openConfirmDeleteTag,
  isUserAdmin,
  navigatoToUnsubscribePage,
}) {
  const { updatedLearnersData } = useLearnerContext();
  const [filteredLearners, setFilteredLearners] = useState([]);

  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredLearners,
    {
      key: 'firstName',
      direction: 'asc',
    }
  );

  useEffect(() => {
    const filteredTable = updatedLearnersData.filter(
      (learner) =>
        learner.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
        learner.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
        learner.phoneNumber.includes(searchValue) ||
        learner.tags.some((tag) =>
          tag.toLowerCase().includes(searchValue.toLowerCase())
        )
    );

    setFilteredLearners(filteredTable);
  }, [searchValue, updatedLearnersData]);

  return (
    <>
      {filteredLearners.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="green-border">
                <TableCell>
                  <TableSortLabel
                    style={{ textAlign: 'center' }}
                    active={sortConfig.key === 'firstName'}
                    direction={
                      sortConfig.key === 'firstName'
                        ? sortConfig.direction
                        : 'asc'
                    }
                    onClick={() => handleSort('firstName')}
                  >
                    Prénom
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    style={{ textAlign: 'center' }}
                    active={sortConfig.key === 'lastName'}
                    direction={
                      sortConfig.key === 'lastName'
                        ? sortConfig.direction
                        : 'asc'
                    }
                    onClick={() => handleSort('lastName')}
                  >
                    Nom
                  </TableSortLabel>
                </TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>
                  Téléphone
                  <br /> 
                  <small style={{ whiteSpace: 'nowrap'}}>(33 6 85 53 99 59)</small>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    style={{ textAlign: 'center' }}
                    active={sortConfig.key === 'countOfParticipations'}
                    direction={
                      sortConfig.key === 'countOfParticipations'
                        ? sortConfig.direction
                        : 'asc'
                    }
                    onClick={() => handleSort('countOfParticipations')}
                  >
                    Nombre Parcours
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((learner, key) => (
                <TableRow key={key} className="green-border">
                  <TableCell className="green-border">
                    {learner.firstName}
                  </TableCell>
                  <TableCell className="green-border">
                    {learner.lastName}
                  </TableCell>
                  <TableCell className="green-border">
                    <Tags
                      options={formattedTags}
                      value={learner}
                      handleAddTag={handleAddTag}
                      handleTagChange={handleTagChange}
                      openConfirmDeleteTag={openConfirmDeleteTag}
                      setNewTag={setNewTag}
                      key={learner._id}
                    />
                  </TableCell>
                  <TableCell className="green-border">
                    {learner.phoneNumber}
                  </TableCell>
                  <TableCell
                    className="green-border"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {learner?.countOfParticipations || '0'}
                  </TableCell>
                  <TableCell
                    className="green-border"
                    style={{ minWidth: '170px' }}
                  >
                    <Box className="align-cell-center">
                      <CreateEditLearner
                        id={learner._id}
                        type={actionTypes.edit}
                      />
                      {isUserAdmin && (
                        <Tooltip title="Désinscrire">
                          <Button
                            sx={{
                              color: '#4546CD',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            size="small"
                            startIcon={<i className="fa-solid fa-list"></i>}
                            onClick={() =>
                              navigatoToUnsubscribePage(learner._id)
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="Supprimer">
                        <Button
                          sx={{
                            color: '#4546CD',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          size="small"
                          startIcon={<i className="fas fa-trash-alt"></i>}
                          onClick={() => deleteLearner(learner._id)}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <Typography variant="body1" style={{ padding: '16px 16px 0 16px' }}>
            Aucun apprenant enregistré.
          </Typography>
          <Typography variant="body1" style={{ padding: '0 16px 16px 16px' }}>
            Veuillez en ajouter un nouveau.
          </Typography>
        </Box>
      )}
    </>
  );
}

export default LearnerTable;
