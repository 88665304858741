import React, { useEffect, useState } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TypographyUI from '../../components/TypographyUI';
import { CompanyServices } from '../../services/companies';
import useStore from '../../store/store';
import { companyHistoryStatuses } from '../../constants/conpanies';
import { formatUTCDate } from '../../helpers';

const CreditsHistory = () => {
  const [creditsData, setCreditsData] = useState(null);
  const { selectedOrganizationId } = useStore(
    (store) => store
  );

  useEffect(() => {
    const getCompanyCreditHistory = async () => {
      try {
        const response = await CompanyServices.getCompanyCreditHistory(
          selectedOrganizationId
        );
        setCreditsData(response.data.data);
      } catch (e) {
        console.error(e);
      }
    };

    getCompanyCreditHistory();
  }, [selectedOrganizationId]);

  return (
    <Box>
      <TypographyUI
        variant="h6"
        align="left"
        marginBottom="10px"
      >
        Historique des crédits
      </TypographyUI>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="green-border">
              <TableCell>Période en cours</TableCell>
              <TableCell>Volume contractuel mensuel</TableCell>
              <TableCell>Crédits utilisés sur le mois</TableCell>
              <TableCell>Statut du contrat</TableCell>
              <TableCell>Mois de fin de contrat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditsData?.credits_history.map((row, index, arr) => (
              <TableRow className="green-border" key={row.month}>
                <TableCell className="green-border">{row.month}</TableCell>
                <TableCell className="green-border">
                  {row.monthly_authorized_credits}
                </TableCell>
                <TableCell className="green-border">
                  {row.credits_used}
                </TableCell>
                <TableCell className="green-border">
                  {index === arr.length - 1
                    ? companyHistoryStatuses.current
                    : companyHistoryStatuses.closed}
                </TableCell>
                <TableCell>
                  {row?.dateEnd
                    ? formatUTCDate(creditsData.contract_end)
                    : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CreditsHistory;
