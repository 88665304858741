export const reminderLength = 325;

export const diffuserTypes = {
  specific: {
    value: 'specific',
    label: 'Temporaire',
  },
  timing: {
    value: 'timing',
    label: 'Continu',
  },
  manual: {
    value: 'manual',
    label: 'Menu',
  },
};

export const diffuserDateTypes = {
  today: {
    value: 'today',
    label: "Aujourd'hui",
  },
  tomorrow: {
    value: 'tomorrow',
    label: 'Demain',
  },
  custom: {
    value: 'custom',
    label: 'Sélectionner une date',
  },
};

export const diffuserStatuses = {
  PLANNING: {
    value: 'PLANNING',
    label: 'PLANNING',
  },
  ACTIVE: {
    value: 'ACTIVE',
    label: 'En-cours',
  },
  CLOSED: {
    value: 'CLOSED',
    label: 'Terminé',
  },
  DRAFT: {
    value: 'DRAFT',
    label: 'Brouillon',
  },
  COMPLETED: {
    value: 'COMPLETED',
    label: 'Complété',
  },
  UNSUBSCRIBED: {
    value: 'UNSUBSCRIBED',
    label: 'UNSUBSCRIBED',
  },
  DEACTIVATED: {
    value: 'DEACTIVATED',
    label: 'Désactivé',
  },
};

export const diffuserActionTypes = {
  ACTIVE: {
    value: 'ACTIVE',
    text: 'Est-ce que vous êtes sûr ? Cela arrêtera les formations qui sont planifiées. Cette action est irréversible.',
  },
  DRAFT: {
    value: 'DRAFT',
    text: (type, broadcast, credits) => {
      const content =
        type === diffuserTypes.specific.value
          ? `Vous êtes sur le point d'envoyer un
parcours de ${credits && credits.trainings_amount} jour(s), pour un total de ${credits && credits.maximum_credits} crédit(s).  
Vous avez selectioné ${credits && credits.recipients_amount} destinataire(s), pour un total de ${credits && credits.maximum_credits} crédit(s).  
Un lien d'invitation à votre parcours sera aussi généré.
Vous ne serez crédité que pour les contenus réellement envoyés.`
          : `Vous êtes sur le point de diffuser un 
parcours de ${credits && credits.trainings_amount} contenus, pour un total de ${credits && credits.maximum_credits_per_person} crédits par bénéficiaire.  
Un lien d'invitation à votre parcours sera aussi généré.
Vous ne serez crédité que pour les contenus réellement envoyés.`;

      return <pre>{content}</pre>;
    },
  },
};

