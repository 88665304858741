import React, { useState, useEffect } from 'react';
import { useLearnerContext } from '../../context/LearnerContext';
import useStore from '../../store/store';
import { TagService } from '../../services/tags';
import { LearnersService } from '../../services/learners';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { actionTypes } from '../../constants/types';
import CreateButton from './components/CreateButton';
import EditButton from './components/EditButton';
import DialogTitleCustom from '../../components/DialogTitleCustom';
import { TAG_TYPES } from '../../constants/tags';
import { setPlaceholderForInput } from '../../helpers';

const CreateEditLearner = ({ type, id }) => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAddTag, setOpenAddTag] = useState(false);
  const [telephone, setTelephone] = useState('');
  const [newTag, setNewTag] = useState('');
  const { dataLearner, getAllTags, formattedTags } = useLearnerContext();
  const [selectedTags, setSelectedTags] = useState([]);

  const requiredFields = [nom, prenom, telephone];
  const canSubmit = requiredFields.every((field) => field.trim() !== '');

  const { userCompanyId, selectedOrganizationId } = useStore((state) => state);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const filter = createFilterOptions();

  const GetLearnerId = async () => {
    const response = await LearnersService.getById(id);
    const { lastName, firstName, tags, phoneNumber } = response.data.data;
    setNom(lastName);
    setPrenom(firstName);
    setTags(tags);
    setSelectedTags(tags);
    setTelephone(phoneNumber);
  };

  const createLearner = async (data) => {
    try {
      await LearnersService.createLearner(data);
      handleClose();
      dataLearner();
    } catch (error) {
      console.error('Error creating learner:', error);
      setIsError(true);
      setErrorMessage(error?.response?.data?.message || 'Error');
    }
  };

  const editLearner = async (id, updatedData) => {
    try {
      await LearnersService.updateLearner(id, updatedData);
      handleClose();
      dataLearner();
    } catch (error) {
      console.error('Error updating learner:', error);
      setIsError(true);
      setErrorMessage(error?.response?.data?.message || 'Error');
    }
  };

  const handleOpen = () => {
    setOpen(true);
    if (type === actionTypes.edit) GetLearnerId();
    if (type === actionTypes.create) handleReset();
    getAllTags(undefined, TAG_TYPES.LEARNER);
  };

  const changeTelephone = (e, ...rest) => {
    setTelephone(e);
  };

  const handleClose = () => setOpen(false);

  const handleReset = () => {
    setNom('');
    setPrenom('');
    setTags([]);
    setTelephone('');
    setSelectedTags([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsError(false);

    try {
      if (type === actionTypes.create) {
        const data = {
          firstName: prenom,
          lastName: nom,
          tags: selectedTags,
          phoneNumber: telephone,
          courseNumber: 0,
          company_id: selectedOrganizationId || userCompanyId,
        };
        createLearner(data);
      }

      if (type === actionTypes.edit) {
        const updatedData = {
          lastName: nom,
          firstName: prenom,
          tags: selectedTags,
          phoneNumber: telephone,
          company_id: selectedOrganizationId || userCompanyId,
        };

        editLearner(id, updatedData);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsError(true);
      setErrorMessage(error?.response?.data?.message || 'Error');
    }
  };

  const formItemStyle = {
    marginBottom: '16px',
  };

  const handleAddNewTags = async (newTagValue) => {
    try {
      await TagService.createTag(
        newTagValue,
        selectedOrganizationId || userCompanyId,
        TAG_TYPES.LEARNER
      );
      setSelectedTags((prev) => [...prev, newTagValue]);
      setNewTag('');
      getAllTags(undefined, TAG_TYPES.LEARNER);
      setOpenAddTag(false);
    } catch (error) {
      console.error('Error adding new tag:', error);
    }
  };
  const handleTagChange = (tags) => {
    setSelectedTags(tags);
  };

  useEffect(() => {
    if (open) setPlaceholderForInput({ prefix: true });
  }, [open]);

  return (
    <>
      {type === actionTypes.create && <CreateButton handleOpen={handleOpen} />}
      {type === actionTypes.edit && <EditButton handleOpen={handleOpen} />}

      <Dialog
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitleCustom id="modal-modal-title">
          {type === actionTypes.create
            ? 'Ajouter un apprenant'
            : type === actionTypes.edit
            ? 'Modifier un apprenant'
            : ''}
        </DialogTitleCustom>
        <DialogContent>
          <form onSubmit={handleSubmit} sx={{ textAlign: 'center' }}>
            <TextField
              label="Prenom"
              autoComplete="off"
              type="text"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              fullWidth
              variant="outlined"
              style={formItemStyle}
            />
            <TextField
              label="Nom"
              autoComplete="off"
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              fullWidth
              variant="outlined"
              style={formItemStyle}
            />
            <FormControl fullWidth variant="outlined" style={formItemStyle}>
              <Autocomplete
                freeSolo
                clearOnBlur
                disableClearable
                multiple
                filterSelectedOptions={false}
                options={formattedTags.map((tag) => tag.tagName)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== '') {
                    filtered.push(`"Ajouter" ${params.inputValue}`);
                  }
                  return filtered;
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.tagName;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="custom-height"
                    label="Tags"
                  />
                )}
                value={selectedTags}
                onChange={(event, newValue) => {
                  const clickedElement = event.target;
                  let lenghtToCut = 10;

                  if (event.code === 'Backspace' || event.key === 'Backspace') {
                    return;
                  }

                  if (event.code === 'Enter' || event.key === 'Enter') {
                    lenghtToCut = 0;
                  }

                  if (
                    clickedElement instanceof SVGElement ||
                    clickedElement.nodeName.toLowerCase() === 'path'
                  ) {
                    return handleTagChange(newValue);
                  }

                  if (newValue && newValue.length > 0) {
                    const newTagValue = newValue[newValue.length - 1];
                    const existingTags = formattedTags.map((tag) =>
                      tag.tagName.toLowerCase()
                    );

                    if (!existingTags.includes(newTagValue.toLowerCase())) {
                      setNewTag(newTagValue.slice(lenghtToCut));

                      handleAddNewTags(newTagValue.slice(lenghtToCut));
                    } else {
                      handleTagChange(newValue);
                    }
                  }
                }}
              />
            </FormControl>

            <small style={{ whiteSpace: 'nowrap', display: 'flex', marginBottom: '5px'}}>(ex: 33 6 85 53 99 59)</small>
            <FormControl fullWidth variant="outlined" style={formItemStyle}>
              <PhoneInput
                defaultCountry={'fr'}
                disableCountryGuess={true}
                forceDialCode={true}
                label="Téléphone"
                value={telephone}
                onChange={changeTelephone}
                fullWidth
                variant="outlined"
                style={formItemStyle}
                inputStyle={{ width: '100%', height: '50px' }}
                countrySelectorStyleProps={{
                  buttonStyle: { height: '50px' },
                }}
              />
            </FormControl>
            {/* {isUserAdmin && (
              <FormControl fullWidth variant="outlined" style={formItemStyle}>
                <InputLabel id="company-label">Organisation</InputLabel>
                <Select
                  labelId="company-label"
                  id="Organisation"
                  value={selectedCompanyId}
                  onChange={handleCompanyChange}
                  label="Organisation"
                >
                  {organization.map((company) => (
                    <MenuItem key={company._id} value={company._id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )} */}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            Annuler
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!canSubmit}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={isError} autoHideDuration={1000} message={errorMessage} />
    </>
  );
};

export default CreateEditLearner;
