import React from 'react';
import { Avatar, Skeleton } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

function LogoPicker({ image, handleImageUpload, isImageLoading }) {
  return (
    <label
      htmlFor={isImageLoading ? '' : 'avatar-upload'}
      style={{ cursor: isImageLoading ? 'default' : 'pointer' }}
    >
      {isImageLoading ? (
        <Skeleton
          variant="square"
          width={150}
          height={150}
          animation="wave"
          sx={{ margin: '0 auto 20px auto' }}
        />
      ) : (
        <Avatar
          alt="User Sharp"
          src={image || ''}
          variant="square"
          className="profile-avatar"
          sx={{
            width: 150,
            height: 150,
            margin: '0 auto 20px auto',
            position: 'relative',
            '&:hover::before': {
              content: "''",
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.7)',
            },
            '&:hover::before svg': {
              fontSize: 50,
              color: '#fff',
            },
            '& img': {
              height: 'auto',
            },
          }}
        >
          <CameraAltIcon sx={{ fontSize: 50 }} />
        </Avatar>
      )}

      <input
        type="file"
        accept="image/*"
        id="avatar-upload"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
    </label>
  );
}

export default LogoPicker;
