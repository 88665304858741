import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useLearnerContext } from '../../../context/LearnerContext';
import { BroadcastService } from '../../../services/broadcast';
import useStore from '../../../store/store';

const DeleteBroadcastsPopup = ({
  selectedBroadcasts,
  setSelectedBroadcasts,
}) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const setSnackbarContent = useStore((store) => store.setSnackbarContent);
  const { getBroadcasts } = useLearnerContext();

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const deleteBroadcasts = async () => {
    try {
      const payload = {
        broadcasts: selectedBroadcasts.map((broadcast) => broadcast.id),
      };

      await BroadcastService.deleteBroadcasts(payload);
      setDeleteConfirmationOpen(false);
      setSelectedBroadcasts([]);
      getBroadcasts();
    } catch (e) {
      console.log('e');
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  return (
    <>
      <Tooltip
        title={
          !selectedBroadcasts.length
            ? 'Sélectionnez les diffusions à supprimer dans le tableau'
            : ''
        }
      >
        <span>
          <Button
            variant="outlined"
            color="error"
            disabled={!selectedBroadcasts.length}
            onClick={handleOpenDeleteConfirmation}
          >
            Supprimer
          </Button>
        </span>
      </Tooltip>

      <Dialog open={deleteConfirmationOpen}>
        <DialogTitle fontWeight='bold'>Supprimer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirmez-vous la suppression de la campagne{' '}
            <u>
              {selectedBroadcasts.map((broadcast) => '"' + broadcast.name + '"').join(', ')}
            </u>
            ?
            <br />
            Cette action est irréversible. L'évaluation de la campagne sera
            aussi supprimée.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseDeleteConfirmation}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={deleteBroadcasts}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteBroadcastsPopup;
