import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

const TrainingAnswerSubmitted = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100%",
      }}
    >
      <FontAwesomeIcon icon={faCheckCircle} size="4x" color="#66DEAE" />
      <Typography
        variant="h5"
        style={{
          color: "#66DEAE",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 700,
          fontSize: "32px",
          marginTop: "20px",
        }}
      >
        Merci d'avoir répondu.
      </Typography>
    </Box>
  );
};

export default TrainingAnswerSubmitted;
