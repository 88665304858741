export const downloadImageBase64 = async (getLogoFunction, companyId, setLogoFunction) => {
    // Function return Base64 to display image,
    // If there are problems with rendering images, check that getLogoFunction has arrayBuffer type, otherwise, we will always se empty image tag.
    // For Example, our endpoint should look lile:

    // async getCompanyLogo(company_id) {
    //     const response = await axios.get(`${COMPANY_ENDPOINTS.getLogo(company_id)}`, {
    //       headers: {
    //         authorization: token,
    //       },
    //       responseType: 'arraybuffer', --- without arrayBuffer image will be 'broken '
    //     });
    //     return response;
    //   }

    try {
      const response = await getLogoFunction(companyId);
  
      const blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
  
      const reader = new FileReader();
  
      reader.onload = function () {
        const base64String = reader.result.split(',')[1];
  
        const logo = `data:image/jpeg;base64, ${base64String}`;
        setLogoFunction(logo);
      };
  
      reader.readAsDataURL(blob);
    } catch (e) {
      console.error(e);
      setLogoFunction(null);
    }
  };