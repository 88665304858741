import { Button, Tooltip } from '@mui/material';
import React from 'react';

const EditOrganizationButtons = ({ handleOpen }) => {

  return (
    <Tooltip title="Modifier">
      <Button
        onClick={handleOpen}
        sx={{
          color: '#4546CD',
          margin: 0,
          padding: 0,
        }}
        size="small"
        startIcon={<i className="fas fa-edit"></i>}
      />
    </Tooltip>
  );
};

export default EditOrganizationButtons;
