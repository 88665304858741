import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './components/AppRouter/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LearnerProvider from './context/LearnerContext';
import theme from './helpers/theme';
import { ThemeProvider } from '@mui/material/styles';

import { dateLocaleType } from './constants/dates';
import 'dayjs/locale/fr';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={dateLocaleType}
    >
      <LearnerProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </LearnerProvider>
    </LocalizationProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
