import React from 'react';
import NotFoundPicture from './components/NotFoundPicture';
import TypographyUI from '../../components/TypographyUI';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const returnHome = () => {
    navigate('/');
  };

  return (
    <Box className="not-found">
      <NotFoundPicture />

      <Box className="not-found__content">
        <Box sx={{ marginTop: '14px' }}>
          <TypographyUI color="black" fontSize="30px" align="center">
            Page non trouvée
          </TypographyUI>
        </Box>

        <Box sx={{ marginTop: '14px' }}>
          <TypographyUI color="black" fontSize="14px" align="center">
            La page que vous recherchez a peut-être été supprimée, a changé de
            nom ou est temporairement indisponible.
          </TypographyUI>
        </Box>

        <Button onClick={returnHome} className="return-home">
          Return home
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
