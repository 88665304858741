import '../../App.scss';
import '../../pages/Home.css';
import '../../styles/custom.scss';
import '../../styles/mui.scss';
import SideMenu from '../SideMenu';
import Header from '../Header';
import Footer from '../Footer';

import { Suspense, useEffect, useRef, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  adminRoutes,
  clientRoutes,
  unauthorizedRoutes,
  globalRoutes,
  cleanPages,
} from './routes';
import useStore from '../../store/store';
import SideMenuProfile from '../SideMenuProfile';
import { UsersService } from '../../services/users';
import { Box } from '@mui/material';
import { getToken } from '../../helpers/user';
import SnackbarCustom from '../SnackbarCustom';
import { ROLES } from '../../constants/auth';
import { useLearnerContext } from '../../context/LearnerContext';

import { pdfjs } from 'react-pdf';
import { downloadAvatar } from '../../helpers/avatar';
import { changeColorsForDev } from '../../helpers';

function App() {
  const [croute, setCroute] = useState('/');
  const {
    setUserRole,
    userRole,
    setUserEmail,
    setAvatar,
    setUserOrganization,
    setSelectedOrganization,
    setSelectedOrganizationId,
    selectedOrganizationId,
    userOrganization,
    setUserCompanyId,
    resetPersistedStore,
  } = useStore((state) => state);

  const { companiesData } = useLearnerContext();
  const location = useLocation();
  const isToken = getToken();

  const { isSnackbarOpen, closeSnackbar, snackbarContent } = useStore(
    (state) => state
  );

  const handleCloseSnackbar = () => {
    closeSnackbar();
  };

  const navigate = useNavigate();

  async function me() {
    try {
      const { data } = await UsersService.me();
      if (data) {
        setUserRole(data.role);
        setUserEmail(data.email);
        setUserOrganization(data.company_name);
        navigate(location.pathname);
        downloadAvatar(data.avatar, setAvatar);
        setUserCompanyId(data.company_id);

        if (!selectedOrganizationId) {
          setSelectedOrganization(data.company_name);
          setSelectedOrganizationId(data.company_id);
        }
      }
    } catch (e) {
      console.error(e);
      setUserRole(ROLES.UNAUTHORIZED);
      const token = localStorage.getItem('authorization');
      if (token) {
        localStorage.removeItem('authorization');
        resetPersistedStore()
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    if (userRole === ROLES.ADMIN) {
      companiesData();
      if (userOrganization && !selectedOrganizationId) {
        setSelectedOrganization(userOrganization);
      }
    }
  }, [userRole]);

  useEffect(() => {
    setCroute(location.pathname);
    me();
    changeColorsForDev()
  }, []);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  return (
    <div className="App" id='App'>
      <div
        className={`content ${
          (!isToken && 'margin-left-empty') ||
          ((cleanPages.includes(location.pathname) ||
            cleanPages.some((page) => location.pathname.includes(page))) &&
            'margin-left-empty')
        }`}
      >
        <Header croute={croute} setCroute={setCroute} />
        {cleanPages.includes(location.pathname) ||
        cleanPages.some((page) =>
          location.pathname.includes(page)
        ) ? null : croute === '/profile' ? (
          <SideMenuProfile croute={croute} setCroute={setCroute} />
        ) : (
          <SideMenu croute={croute} setCroute={setCroute} />
        )}
        <Box className="page-container">
          <Routes>
            {userRole === ROLES.UNAUTHORIZED
              ? unauthorizedRoutes.concat(globalRoutes).map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <Suspense fallback={<></>}>
                        <route.component />
                      </Suspense>
                    }
                    key={route.path}
                  />
                ))
              : userRole === ROLES.CLIENT
              ? clientRoutes.concat(globalRoutes).map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <Suspense fallback={<></>}>
                        <route.component />
                      </Suspense>
                    }
                    key={route.path}
                  />
                ))
              : adminRoutes.concat(globalRoutes).map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <Suspense fallback={<></>}>
                        <route.component />
                      </Suspense>
                    }
                    key={route.path}
                  />
                ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
      </div>
      <Footer />

      <SnackbarCustom
        isSnackbarOpen={isSnackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        type={snackbarContent.type}
        text={snackbarContent.text}
      />
    </div>
  );
}

export default App;
