import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { ROLES } from '../constants/auth';

const useStore = create(
  persist(
    (set) => ({
      isMenuOpen: false,
      isSnackbarOpen: false,
      userRole: ROLES.PENDING,
      selectedOrganization: null,
      selectedOrganizationId: null,
      userOrganization: '',
      userCompanyId: '',
      userEmail: '',
      avatar: '',
      isNavbarVisible: false,
      snackbarContent: {
        type: 'error',
        text: "Oups, quelque chose s'est mal passé",
      },
      companyLogo: null,
      totallPointsFormSubmit: null,
      selectedCompanyId: null,
      tempCompany: null,

      setTempCompany: (company) => {
        set({ tempCompany: company });
      },
      setSelectedCompanyId: (companyId) => {
        set({ selectedCompanyId: companyId });
      },
      setTotallPointsFormSubmit: (points) => {
        set({ totallPointsFormSubmit: points });
      },
      setCompanyLogo: (logo) => {
        set({ companyLogo: logo });
      },
      toggleMenu: (menu) => {
        set({ isMenuOpen: !menu });
      },
      closeToggleMenu: () => {
        set({ isMenuOpen: false });
      },
      setUserEmail: (email) => set({ userEmail: email }),
      setSelectedOrganization: (organization) =>
        set({ selectedOrganization: organization }),
      setSelectedOrganizationId: (organization) =>
        set({ selectedOrganizationId: organization }),
      setUserRole: (role) => set({ userRole: role }),
      setUserOrganization: (organization) =>
        set({ userOrganization: organization }),
      setAvatar: (newAvatar) => set({ avatar: newAvatar }),
      setUserCompanyId: (company) => set({ userCompanyId: company }),
      openSnackbar: () => set({ isSnackbarOpen: true }),
      closeSnackbar: () => set({ isSnackbarOpen: false }),
      setSnackbarContent: (type, text) =>
        set({ snackbarContent: { type, text }, isSnackbarOpen: true }),
      toggleNavbarVisibility: () =>
        set((state) => ({ isNavbarVisible: !state.isNavbarVisible })),
      resetPersistedStore: () => {
        set(() => ({
          selectedOrganizationId: null,
          selectedOrganization: null,
          selectedCompanyId: null,
          tempCompany: null,
          userRole: ROLES.PENDING,
        }));
      },
    }),
    {
      partialize: (state) => ({
        selectedOrganizationId: state.selectedOrganizationId,
        selectedOrganization: state.selectedOrganization,
        selectedCompanyId: state.selectedCompanyId,
        tempCompany: state.tempCompany,
        userRole: state.userRole,
      }),
    }
  )
);

export default useStore;
