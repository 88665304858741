import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import useStore from '../../store/store';
import { UsersService } from '../../services/users';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ROLES } from '../../constants/auth';

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { setSnackbarContent, setUserRole, userRole } = useStore(
    (store) => store
  );

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitLoading(true);
      const response = await UsersService.login({
        email: email.trim(),
        password: password.trim(),
      });

      const token = response.data.token;
      if (token) {
        localStorage.setItem('authorization', token);
        window.location.reload();
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setSnackbarContent('error', `L'autorisation à échouée`);
      } else {
        setSnackbarContent('error', e?.response?.data?.message || `Quelque chose s'est mal passé`);
      }
      console.error('Login failed', error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (userRole === ROLES.CLIENT || userRole === ROLES.ADMIN) {
      setUserRole(ROLES.PENDING);
    }
  }, []);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <img
          src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F0e2d4cf7342369f2358c6e63a63bb118.cdn.bubble.io%2Ff1660048376354x139552505194282300%2FAnemon-LOGO-C-S.png?w=192&h=64&auto=compress&dpr=1.25&fit=max"
          alt="logo"
          width={153}
          height={51}
        />
        <Box className="login-wrapper">
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, p: '24px 16px' }}
          >
            <Typography
              component="h4"
              variant="h4"
              align="left"
              fontSize={30}
              fontWeight={'bold'}
              color={'black'}
            >
              Connexion
            </Typography>
            <Typography
              color={'black'}
              align="left"
              fontWeight={'bold'}
              fontSize={13}
              marginTop={2}
            >
              Email
            </Typography>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="off"
              inputProps={{ WebkitAutofill: '0 0 0 1000px white inset' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Typography
              color={'black'}
              align="left"
              fontWeight={'bold'}
              fontSize={13}
              marginTop={2}
            >
              Mot de passe
            </Typography>
            <TextField
              required
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              sx={{ width: '100%', marginTop: 1 }}
              label={
                <Typography sx={{ color: 'black', fontSize: 13 }}>
                  Se souvenir de moi
                </Typography>
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitLoading}
              sx={{ mt: 1, mb: 2, borderRadius: 20 }}
            >
              Connexion
            </Button>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <a
                  href="mailto:support@anemon.fr"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: 13 }}
                >
                  Pas encore de compte ? Contactez-nous
                </a>
                <Link
                  style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    marginTop: '15px',
                    display: 'block',
                  }}
                  href="/forgotpassword"
                >
                  Mot de passe oublié?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
