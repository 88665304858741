import { BASE_URL } from '../constants/config';
import { getUTCOffset } from '../helpers/dates';
import axios from './app.utils';

export const CREER_ENDPOINTS = {
  forms: `${BASE_URL}api/trainings/`,
  createBlank: `${BASE_URL}api/trainings/w/blank`,
  statistics: (trainingId, broadcastId) =>
    `${BASE_URL}api/trainings/statistics/${trainingId}?broadcast_id=${broadcastId}`,
  exportStatistics: (trainingId, broadcastId) =>
    `${BASE_URL}api/trainings/statistics/${trainingId}/export?broadcast_id=${broadcastId}&utc=${getUTCOffset()}`,
  crossCopy: `${BASE_URL}api/trainings/cross_copy`,
  webAnswer: (identifier) => `${BASE_URL}api/trainings/w/${identifier}`,
};

const token = localStorage.getItem('authorization');

class Creer {
  async getAll() {
    const response = await axios.get(CREER_ENDPOINTS.forms, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }
  async getTrainingById(id) {
    const response = await axios.get(`${CREER_ENDPOINTS.forms}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }
  async getTrainingByIdentifier(identifier) {
    const response = await axios.get(
      `${CREER_ENDPOINTS.forms}/q/${identifier}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
  async answerWebTrainingByIdentifier(identifier, formData) {
    const response = await axios.post(
      `${CREER_ENDPOINTS.webAnswer(identifier)}`,
      formData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async createBlankWebFlow(data) {
    const response = await axios.post(
      CREER_ENDPOINTS.createBlank,
      {
        data
      },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response.data;
  }

  async getWebAnswerTraining(identifier) {
    const response = await axios.get(
      `${CREER_ENDPOINTS.webAnswer(identifier)}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
  async updateTraining(id, updatedData) {
    const response = await axios.put(
      `${CREER_ENDPOINTS.forms}/${id}`,
      updatedData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }
  async createTraining(formData) {
    const response = await axios.post(CREER_ENDPOINTS.forms, formData, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async crossCopyTraining(payload) {
    const response = await axios.post(CREER_ENDPOINTS.crossCopy, payload, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async deleteTraining(id) {
    const response = await axios.delete(`${CREER_ENDPOINTS.forms}/${id}`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async submitResponse(identifier, answer) {
    const response = await axios.post(
      `${CREER_ENDPOINTS.forms}q/${identifier}`,
      { answer: answer },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async getTrainingStatistics(trainingId, broadcastId) {
    const response = await axios.get(
      `${CREER_ENDPOINTS.statistics(trainingId, broadcastId)}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async getExportTrainingStatistics(trainingId, broadcastId) {
    const response = await axios.get(
      `${CREER_ENDPOINTS.exportStatistics(trainingId, broadcastId)}`,
      {
        responseType: 'blob',
        headers: {
          authorization: token,
        },
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    const filename = `BroadcastData_${new Date()}.xlsx`;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    return response;
  }
}

export const CreerService = new Creer();
