export const roles = ['client', 'admin'];

export const ROLES = {
  CLIENT: 'client',
  ADMIN: 'admin',
  UNAUTHORIZED: 'unauthorized',
  PENDING: 'pending',
};

export const ADMIN = 'admin';

export const ADMIN_COMPANY = 'Anemon';
