import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trainingWebAnswerTypes } from '../../../../constants/trainings';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from '../../../../store/store';
import { CreerService } from '../../../../services/creeer';
import { downloadImageBase64 } from '../../../../helpers/company';
import { CompanyServices } from '../../../../services/companies';

const WebPageUser = () => {
  const [areQuestionsDisabled, setAreQuestionsDisabled] = useState(false);
  const [form, setForm] = useState({
    title: 'Draft',
    description: 'Draft description \nHere is out content\n\nLorem Ipsum',
    options: ['a', 'b', 'c'],
    correctAnswers: [],
    correctResponse: ['a'],
    incorrectResponse: [],
    responseText: '',
    answer: [],
    type: trainingWebAnswerTypes.MULTIPLE_CHOICE.value,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { setSnackbarContent, setCompanyLogo } = useStore(
    (store) => store
  );

  const submitAnswer = async () => {
    const answer = { answer: form.answer };

    const response = await CreerService.answerWebTrainingByIdentifier(
      id,
      answer
    );
    const trainingResponse = response.data;

    if (trainingResponse.icon) {
      navigate(`/w/${id}/${trainingResponse.icon}`);
    }
  };

  const getTrainingAnswer = async () => {
    try {
      const response = await CreerService.getWebAnswerTraining(id);
      const answerTrainingData = response.data.data;

      setForm(answerTrainingData);

      if (answerTrainingData.answer && answerTrainingData.answer.length > 0) {
        setAreQuestionsDisabled(true);
      }
      
      if (answerTrainingData.company_id) {
        const companyId = answerTrainingData.company_id

        downloadImageBase64(
          CompanyServices.getCompanyLogo,
          companyId,
          setCompanyLogo
        );
      }
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', 'Error');
    }
  };

  const answerQuestion = (e, question, isMultipleAnswers) => {
    const answer = e.target.value + '';

    setForm((prev) => {
      const prevForm = { ...prev };
      if (isMultipleAnswers) {
        // New answer
        if (!(prevForm?.answer && prevForm?.answer.length)) {
          return { ...prevForm, answer: [answer] };
        }

        // Unselect answer
        const index = prevForm?.answer.indexOf(answer);
        if (index !== -1) {
          prevForm?.answer.splice(index, 1);
          return prevForm;
        }

        // Add more answers
        return { ...prevForm, answer: [...prevForm?.answer, answer] };
      }

      // Free text
      const result = { ...prevForm, answer: [answer] };
      return result;
    });
  };

  const navigateToResults = () => {
    navigate(`/w/${id}/${form.status.icon}`);
  };

  useEffect(() => {
    getTrainingAnswer();
  }, []);

  function linkify(text) {
    text = text.replace(/(triton\.anemon\.fr\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*)/gi, '<a style="color: #66DEAE;" href="https://$1" target="_blank" rel="noopener noreferrer">$1</a>');
    text = text.replace(/(vps-de6aee50\.vps\.ovh\.net\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*)/gi, '<a style="color: #66DEAE;" href="https://$1" target="_blank" rel="noopener noreferrer">$1</a>');
    return text;
  }

  return (
    <Grid
      spacing={2}
      display={'flex'}
      flexDirection={'column'}
      className="container-shrinked"
    >
      <Box sx={{ textAlign: 'center' }} className="forms-wrapper">
        <form className="form-answers-list">
          <div className="form-title">
            <h1>{form.title}</h1>
            <pre className="form-description" dangerouslySetInnerHTML={{ __html: linkify(form.description) }} />
          </div>

          <div
            style={{ textAlign: 'left', marginBottom: '20px' }}
            className="form-answers-wrapper"
          >
            <div>
              {form.type === trainingWebAnswerTypes.TEXT.value && (
                <TextField
                  multiline
                  onChange={(e) => answerQuestion(e, form)}
                  disabled={areQuestionsDisabled}
                  placeholder={areQuestionsDisabled ? form.answer : ''}
                  variant="standard"
                  style={{
                    width: '100%',
                  }}
                />
              )}
              {form.type === trainingWebAnswerTypes.MULTIPLE_CHOICE.value &&
                [...new Set(form.options)].map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <FormControlLabel
                      key={optionIndex}
                      disabled={areQuestionsDisabled}
                      control={
                        <Checkbox
                          value={option}
                          checked={form.answer && form?.answer.includes(option)}
                          id={`question-option-${optionIndex}`}
                          name="radio-buttons"
                          onChange={(e) => answerQuestion(e, form, true)}
                        />
                      }
                    />
                    <label htmlFor={`question-option-${optionIndex}`}>
                      {option}
                    </label>
                    {areQuestionsDisabled &&
                    (option === form?.correctAnswers ||
                      form?.correctAnswers.includes(option) ||
                      form?.correct?.includes(option)) ? (
                      <Tooltip title="Bonne réponse">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          size="lg"
                          color="#66DEAE"
                          style={{ marginLeft: 20 }}
                        />
                      </Tooltip>
                    ) : null}

                    {areQuestionsDisabled &&
                    form.answer.includes(option) &&
                    !form?.correctAnswers.includes(option) ? (
                      <Tooltip title="Mauvaise réponse">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          size="lg"
                          color="#fa7070"
                          style={{ marginLeft: 20 }}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                ))}
              {form.type === trainingWebAnswerTypes.CHECKBOX.value && (
                <FormControl>
                  {[...new Set(form.options)].map((option, optionIndex) => (
                    <div key={optionIndex}>
                      <FormControlLabel
                        key={optionIndex}
                        disabled={areQuestionsDisabled}
                        control={
                          <Radio
                            value={option}
                            checked={
                              form.answer && form?.answer.includes(option)
                            }
                            id={`question-option-${optionIndex}`}
                            name={`question-${optionIndex}`}
                            onChange={(e) => answerQuestion(e, form, false)}
                          />
                        }
                      />
                      <label htmlFor={`question-option-${optionIndex}`}>
                        {option}
                      </label>
                      {areQuestionsDisabled &&
                      (option === form?.correctAnswers ||
                        form?.correctAnswers?.includes(option) ||
                        form?.correct?.includes(option)) ? (
                        <Tooltip title="Bonne réponse">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            size="lg"
                            color="#66DEAE"
                            style={{ marginLeft: 20 }}
                          />
                        </Tooltip>
                      ) : null}

                      {areQuestionsDisabled &&
                      form.answer.includes(option) &&
                      !form?.correctAnswers.includes(option) ? (
                        <Tooltip title="Mauvaise réponse">
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            size="lg"
                            color="#fa7070"
                            style={{ marginLeft: 20 }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  ))}
                </FormControl>
              )}
            </div>
          </div>
        </form>
        <Box
          sx={{
            display: 'flex',
            gap: '1em',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={
              (form.type === trainingWebAnswerTypes.TEXT.value
                ? !form?.answer?.[0]?.length
                : !form?.answer?.length) || areQuestionsDisabled
            }
            sx={{
              display: 'inline-block',
              backgroundColor: '#66DEAE',
            }}
            onClick={submitAnswer}
          >
            Valider
          </Button>
          {areQuestionsDisabled && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                display: 'inline-block',
                backgroundColor: '#66DEAE',
              }}
              onClick={navigateToResults}
            >
              Résultats
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default WebPageUser;
