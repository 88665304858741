import { BASE_URL } from "../constants/config";
import axios from "axios";

export const LINK_ENDPOINTS = {
  createLink: `${BASE_URL}api/shorten/`,
  getLink: `${BASE_URL}api/l/`,
};

const token = localStorage.getItem("authorization");

class Link {
  async shortenLink(url) {
    const response = await axios.post(LINK_ENDPOINTS.createLink, url, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }

  async getLink(url) {
    const response = await axios.get(`${LINK_ENDPOINTS.getLink}${url}`, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }
}

export const LinkService = new Link();
