import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import CreateButton from '../../components/CreateButton';
import DialogTitleCustom from '../../components/DialogTitleCustom';
import ModifyButton from '../../components/ModifyButton';
import { ADMIN_COMPANY } from '../../constants/auth';
import { actionTypes } from '../../constants/types';
import { useLearnerContext } from '../../context/LearnerContext';
import { removeSpaces, setPlaceholderForInput } from '../../helpers';
import { UsersService } from '../../services/users';
import useStore from '../../store/store';

const CreateEditUser = ({ type, id, refreshUsers }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [isCanSubmit, setIsCanSubmit] = useState(false);

  const { companiesData, organization } = useLearnerContext();
  const setSnackbarContent = useStore((store) => store.setSnackbarContent);

  useEffect(() => {
    if (type === actionTypes.create) {
      const canSubmit = [
        email,
        firstName,
        lastName,
        phoneNumber,
        companyName,
      ].every((field) => field.trim() !== '');

      setIsCanSubmit(canSubmit);
    }

    if (type === actionTypes.edit) {
      const canSubmit = [
        email,
        firstName,
        lastName,
        phoneNumber,
        companyName,
      ].every((field) => field.trim() !== '');

      setIsCanSubmit(canSubmit);
    }
  }, [type, email, password, firstName, lastName, phoneNumber, companyName]);

  const handleOpenDialog = () => {
    companiesData();
    setOpenDialog(true);
    if (type === actionTypes.create) handleReset();
    if (type === actionTypes.edit) getUserData();
  };

  const getUserData = async () => {
    const { data } = await UsersService.getOneUser(id);
    const response = data.data;

    setEmail(response.email);
    setFirstName(response.firstName);
    setLastName(response.lastName);
    setPhoneNumber(response.phoneNumber);
    setCompanyName(response.company_id);
  };

  const handleReset = () => {
    setEmail('');
    setPassword('');
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setCompanyName('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (type === actionTypes.create) {
      const userData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        fullName: `${firstName} ${lastName}`,
        phoneNumber: removeSpaces(phoneNumber),
        company_id: companyName,
        avatar: 'string',
        role:
          organization
            .filter((company) => company.name)
            .find((company) => company.name.trim() === ADMIN_COMPANY.trim())
            ._id.trim() === companyName.trim()
            ? 'admin'
            : 'client',
      };

      createUser(userData);
    }

    if (type === actionTypes.edit) {
      const userData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        fullName: `${firstName} ${lastName}`,
        phoneNumber: removeSpaces(phoneNumber),
        company_id: companyName,
        role:
          organization
            .find((company) => company.name.trim() === ADMIN_COMPANY.trim())
            ._id.trim() === companyName.trim()
            ? 'admin'
            : 'client',
      };

      editUser(id, userData);
    }
  };

  const createUser = async (userData) => {
    try {
      await UsersService.register(userData);
      handleCloseDialog();
      refreshUsers();
    } catch (e) {
      setSnackbarContent('error', e.response.data.message);
    }
  };

  const editUser = async (id, userData) => {
    try {
      await UsersService.updateUser(id, userData);
      handleCloseDialog();
      refreshUsers();
    } catch (e) {
      setSnackbarContent(
        'error',
        e.response.data.message || 'Error due editing user'
      );
    }
  };

  useEffect(() => {
    if (openDialog) setPlaceholderForInput({ prefix: true });
  }, [openDialog]);

  return (
    <Box>
      {type === actionTypes.create && (
        <CreateButton handleOpen={handleOpenDialog} />
      )}
      {type === actionTypes.edit && (
        <ModifyButton handleOpen={handleOpenDialog} />
      )}

      <Dialog
        open={openDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
        maxWidth="md"
        scroll={'paper'}
      >
        <DialogTitleCustom id="modal-modal-title">
          {type === actionTypes.create
            ? 'Créer un utilisateur'
            : "Modifier l'utilisateur"}
        </DialogTitleCustom>
        <DialogContent>
          <form onSubmit={handleSubmit} className="text-center">
            <TextField
              label="Email"
              autoComplete="off"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={type === actionTypes.edit}
              fullWidth
              variant="outlined"
              className="space-bottom"
            />
            <TextField
              label="Prénom"
              autoComplete="off"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              variant="outlined"
              className="space-bottom"
            />
            <TextField
              label="Nom"
              autoComplete="off"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              variant="outlined"
              className="space-bottom"
            />
             <small style={{ whiteSpace: 'nowrap', display: 'flex', marginBottom: '5px'}}>(ex: 33 6 85 53 99 59)</small>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ marginBottom: '16px' }}
            >
              <PhoneInput
                defaultCountry={'fr'}
                disableCountryGuess={true}
                forceDialCode={true}
                label="Téléphone"
                value={phoneNumber}
                onChange={setPhoneNumber}
                fullWidth
                variant="outlined"
                inputStyle={{ width: '100%', height: '50px' }}
                countrySelectorStyleProps={{
                  buttonStyle: { height: '50px' },
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ marginBottom: '16px' }}
            >
              <InputLabel id="company-label">Organisation</InputLabel>
              <Select
                labelId="company-label"
                id="company"
                className="text-align-left"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                label="Organisation"
              >
                {organization.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="outlined" color="error">
            Annuler
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!isCanSubmit}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateEditUser;
