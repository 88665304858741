export const fileTypes = {
  AUDIO: 'audio',
  IMAGE: 'image',
  VIDEO: 'video',
  FILE: 'file',
  PDF: 'pdf',
};

export const externalFileTypes = {
  xlsx: "xlsx",
  docx: "docx"
}

export const accessableFileTypes = {
  png: 'image',
  jpeg: 'image',
  jpg: 'image',
  svg: 'image',
  gif: 'image',

  mp3: 'audio',
  m4a: 'audio',
  flac: 'audio',
  wav: 'audio',
  wma: 'audio',
  aac: 'audio',

  mp4: 'video',
  mow: 'video',
  wmv: 'video',
  avi: 'video',
  flv: 'video',
  avchd: 'video',
  mkv: 'video',
  webm: 'video',

  pdf: 'pdf',

  ...externalFileTypes
};



export const fileError = {
  'File is too big': 'Le fichier est trop volumineux. La taille limite est de 100Mo. Alternativement, vous pouvez insérer un lien vers la vidéo de votre choix.'
}
