import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';

const FormPicker = ({
  allForms,
  handleFormSelect,
  isFormPopupVisible,
  handleCloseFormPicker,
  selectedForm,
  removeForm,
}) => {
  return (
    <Dialog open={isFormPopupVisible} maxWidth="sm" fullWidth>
      <DialogTitle>Ajouter un questionnaire</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 5,
          gap: 20,
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Form</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectedForm}
            onChange={handleFormSelect}
            label="Form"
          >
            {allForms.map((form) => (
              <MenuItem key={form.link} value={form.link}>
                {form.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="align-cell-center">
          <Button
            variant="outlined"
            color="error"
            disabled={!selectedForm}
            onClick={removeForm}
          >
            Effacer
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseFormPicker}
          variant="outlined"
          color="error"
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormPicker;
