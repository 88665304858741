import { BASE_URL } from '../constants/config';
import axios from './app.utils';

export const TAGS_ENDPOINTS = {
  tagsWithQuery: (tagType) => {
    return tagType 
      ?  `${BASE_URL}api/tags?tagType=${tagType}`
      :  `${BASE_URL}api/tags/`
  },
  tags: `${BASE_URL}api/tags/`,
  multileTags: `${BASE_URL}api/tags/multiple`,
  deleteTag: (id, companyId) => {
    return companyId
      ? `${BASE_URL}api/tags/${id}?company_id=${companyId}`
      : `${BASE_URL}api/tags/${id}`;
  },
};

const token = localStorage.getItem('authorization');

class Tags {
  async getAll(tagType) {
    const response = await axios.get(TAGS_ENDPOINTS.tagsWithQuery(tagType), {
      headers: {
        authorization: token,
      },
    });

    return response;
  }

  async createTag(tagName, company_id, tagType) {
    const response = await axios.post(
      TAGS_ENDPOINTS.tagsWithQuery(tagType),
      { tagName, company_id, tagType },
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  }

  async createMiltipleTas(tags) {
    const response = await axios.post(TAGS_ENDPOINTS.multileTags, tags, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

  async deleteTag(id, companyId) {
    const response = await axios.delete(
      TAGS_ENDPOINTS.deleteTag(id, companyId),
      {
        headers: {
          authorization: token,
        },
      }
    );

    return response;
  }
}

export const TagService = new Tags();
