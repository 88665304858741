import React, { useEffect, useState } from 'react';
import '../Home.css';
import {
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useLearnerContext } from '../../context/LearnerContext';
import { BroadcastService } from '../../services/broadcast';
import BroadcastTable from './components/BroadcastTable';
import CreateEditDiffuser from './CreateEditDiffuser';
import { actionTypes } from '../../constants/types';
import { diffuserStatuses, diffuserTypes } from '../../constants/broadcast';
import DeleteBroadcastsPopup from './components/DeleteBroadcastsPopup';
import useStore from '../../store/store';
import { TagService } from '../../services/tags';
import { TAG_TYPES } from '../../constants/tags';
import TypographyUI from '../../components/TypographyUI';
import { ROLES } from '../../constants/auth';
import { formatDates } from '../../helpers';
import dayjs from 'dayjs';
import { calculateErrorFromDates } from '../../helpers/broadcast';

function Broadcast() {
  const [filterType, setFilterType] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const {
    getBroadcasts,
    broadcasts,
    setBroadcasts,
    getOneCompanyStatistics,
    oneCompanyStatistics,
    formattedTags,
    getAllTags,
  } = useLearnerContext();
  const [searchText, setSearchText] = useState('');

  const [clonedBroadcastName, setClonedBroadcastName] = useState('');
  const [open, setOpen] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmedBroadcastId, setConfirmedBroadcastId] = useState(null);
  const [confirmActionType, setConfirmActionType] = useState(null);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);
  const [creditsInfo, setCreditsInfo] = useState(null);
  const [isConfirmCopyDisabled, setIsConfirmCopyDisabled] = useState(false);
  const { selectedOrganizationId, setSnackbarContent } = useStore(
    (store) => store
  );

  const [newTag, setNewTag] = useState('');
  const [isDeleteTagOption, setIsDeleteTagOption] = useState(false);
  const [deleteOptionTag, setDeleteOptionTag] = useState(null);

  const { organization } = useLearnerContext();
  const role = useStore((state) => state.userRole);
  const [selectedOrganizationToCopy, setSelectedOrganizationToCopy] =
    useState(null);

  const changeSelectedBroadcasts = (id, isChecked, name) => {
    if (isChecked) {
      setSelectedBroadcasts((prevSelected) => [...prevSelected, { id, name }]);
    } else {
      setSelectedBroadcasts((prevSelected) =>
        prevSelected.filter((broadcast) => broadcast.id !== id)
      );
    }
  };

  const handleDialogOpen = (broadcast) => {
    setActivePopup(broadcast._id);
    setClonedBroadcastName(`Copie de ${broadcast.name}`);
    setOpen(true);
  };
  const handleDialogClose = () => {
    setActivePopup(null);
    setOpen(false);
    resetCompanyToCopy();
  };
  const cloneBroadcast = async (id) => {
    setIsConfirmCopyDisabled(true);

    if (
      role === ROLES.ADMIN &&
      selectedOrganizationToCopy._id !== selectedOrganizationId
    ) {
      try {
        const broadcastToCopy = broadcasts.find(
          (broadcast) => broadcast._id === activePopup
        );
        const payload = {
          broadcast_id: broadcastToCopy._id,
          company_id: selectedOrganizationToCopy._id,
          title: clonedBroadcastName,
        };
        setSnackbarContent(
          'info',
          'La copie de la diffusion peut prendre un certain temps'
        );
        await BroadcastService.crossCopyBroadcast(payload);
        setSnackbarContent('success', 'Diffusion dupliquée avec succès');
        handleDialogClose();
        getBroadcasts();
        setIsConfirmCopyDisabled(false);
        resetCompanyToCopy();
      } catch (e) {
        console.error(e);
        setSnackbarContent('error', e?.response?.data?.message || 'Error');
      }

      return;
    }

    try {
      const broadcast = broadcasts.find((t) => t._id === id);
      const trainingObject = {};
      broadcast.trainings.forEach((training) => {
        const trainingKey = training.id;
        trainingObject[trainingKey] = {
          name: training.name,
          date: training.date,
          step: training.step ? training.step : 0,
          mandatory: training.mandatory ? training.mandatory : false,
          reminder: training.reminder ? training.reminder : false,
          reminder_content: training.reminder_content ? {stepsDays: 0, stepsHours: 0, ...training.reminder_content} : { content: '', date: dayjs().add(4, 'hour'), stepsDays: 0, stepsHours: 0 },
        };
      });
      const newBroadcast = {
        name: clonedBroadcastName,
        description: broadcast.description,
        status: diffuserStatuses.DRAFT.value,
        trainings: trainingObject,
        type: broadcast.type,
        subscriptionControl: broadcast.subscriptionControl || false,
        recipients: [],
        company_id: selectedOrganizationId,
      };
      setSnackbarContent(
        'info',
        'La copie de la diffusion peut prendre un certain temps'
      );
      await BroadcastService.createBroadcast(newBroadcast);
      setSnackbarContent('success', 'Diffusion dupliquée avec succès');
      setClonedBroadcastName(newBroadcast.name);
      handleDialogClose();
      getBroadcasts();
      setIsConfirmCopyDisabled(false);
      resetCompanyToCopy();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const [isLoaderVisible, setLoaderVisible] = useState(true);

  const handleLinkCopy = (broadcastId) => {
    const linkToCopy = `${window.location.origin}/b/${broadcastId}`;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setIsSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Error copying link:', error);
      });
  };
  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value); // Update the search text state
  };

  const handleCheckboxChange = async (broadcastId, index) => {
    const broadcastToUpdate = broadcasts.find(
      (broadcast) => broadcast._id === broadcastId
    );

    if (!broadcastToUpdate) {
      return;
    }

    if (broadcastToUpdate.status === diffuserStatuses.ACTIVE.value) {
      setConfirmDialogOpen(index);
      setConfirmActionType(diffuserStatuses.ACTIVE.value);
      setConfirmedBroadcastId(broadcastId);
    }

    if (broadcastToUpdate.status === diffuserStatuses.DRAFT.value) {
      setConfirmDialogOpen(index);
      setConfirmActionType(diffuserStatuses.DRAFT.value);
      setConfirmedBroadcastId(broadcastId);
    }
  };

  const checkForErrors = (trainings, broadcastType) => {
    const tableWithErrors = calculateErrorFromDates(trainings, broadcastType);

    return tableWithErrors.some(tr => {
      return tr.isError || (tr.reminder && tr.reminder_content.isError)
    })
  }

  const handleConfirm = async (broadcast, confirmedId) => {
    try {
      const isDraft = broadcast.status === diffuserStatuses.DRAFT.value;
      const isSpecialDate = broadcast.type === diffuserTypes.specific.value;

      const currentDate = new Date();
      const diffuserDate = new Date(broadcast.trainings[0].date);
      const isInFuture = diffuserDate > currentDate;

      const status = isDraft
        ? diffuserStatuses.ACTIVE.value
        : isSpecialDate && isInFuture
        ? diffuserStatuses.DRAFT.value
        : diffuserStatuses.CLOSED.value;

      const data = {
        name: broadcast.name,
        description: broadcast.description,
        type: broadcast.type,
        trainings: broadcast.trainings,
        recipients: broadcast.recipients,
        manual_broadcast: broadcast?.manual_broadcast,
        status,
      };

      if (confirmActionType === diffuserStatuses.DRAFT.value) {
        const hasErrors = checkForErrors(data.trainings, data.type)
        if (hasErrors) return setSnackbarContent('error', "La diffusion comporte des erreurs, veuillez d'abord les corriger");
      }

      if (!broadcast?.manual_broadcast?.max_trainings) delete broadcast.manual_broadcast
      await BroadcastService.updateBroadcast(broadcast._id, data);

      setBroadcasts((prevBroadcasts) =>
        prevBroadcasts.map((broadcast) =>
          broadcast._id === confirmedBroadcastId || broadcast._id === confirmedId
            ? { ...broadcast, status }
            : broadcast
        )
      );
      setConfirmedBroadcastId(null);
      setConfirmDialogOpen(false);
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const handleCancel = () => {
    setConfirmDialogOpen(false);
  };

  const fetchData = async () => {
    Promise.all([
      getBroadcasts(),
      getOneCompanyStatistics(),
      getAllTags(undefined, TAG_TYPES.BROADCAST),
    ]).then(() => {
      setLoaderVisible(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredBroadcasts = broadcasts.filter((broadcast) => {
    const nameMatches = broadcast.name
      .toLowerCase()
      .includes(searchText.toLowerCase());

    const countOfLearnersMatches = ('' + broadcast.recipients_amount).includes(
      searchText.toLowerCase()
    );

    const transformedBroadcast = broadcast.trainingFormatted.map((training) => {
      return training.date
        ? new Date(training.date).toLocaleDateString()
        : 'N/A';
    });

    const dateOfCreating = formatDates(new Date(broadcast.created_at))

    const dates = [...transformedBroadcast, dateOfCreating]

    const dateMatches = dates.some((date) =>
      date.toLowerCase().includes(searchText.toLowerCase())
    );

    const tagsIncludesSearchTerm = (broadcast.tags || []).some((tag) =>
      tag.toLowerCase().includes(searchText.toLowerCase())
    );

    const statusMatches = filterType === '' || broadcast.status === filterType;

    return (
      (nameMatches ||
        dateMatches ||
        countOfLearnersMatches ||
        tagsIncludesSearchTerm) &&
      statusMatches
    );
  });

  const calculateCreditsToLaunch = async (id) => {
    const foundBroadcast = broadcasts.find((broadcast) => broadcast._id === id);
    if (foundBroadcast) {
      const transformedTrainings = foundBroadcast.trainings.reduce(
        (result, item) => {
          result[item.id] = {
            name: item.name,
            date: item.date,
            step: item.step,
            mandatory: item.mandatory,
            reminder: item.reminder,
          };
          return result;
        },
        {}
      );

      const payload = {
        name: foundBroadcast.name,
        description: foundBroadcast.description,
        type: foundBroadcast.type,
        company_id: foundBroadcast.company_id,
        status: foundBroadcast.status,
        recipients: foundBroadcast.type === diffuserTypes.specific.value ? foundBroadcast.recipients : [],
        trainings: transformedTrainings,
        subscriptionControl: foundBroadcast.subscriptionControl
      };

      try {
        const response = await BroadcastService.calculateCredits(payload);
        setCreditsInfo(response?.data?.data);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const updateBroadcast = async (id, updatedData) => {
    await BroadcastService.updateBroadcast(id, updatedData);
  };

  const handleTagChange = ({ event, id }) => {
    const foundBroadcastIndex = broadcasts.findIndex(
      (broadcast) => broadcast._id === id
    );

    if (foundBroadcastIndex !== -1) {
      const updatedBroadcastsList = {
        ...broadcasts[foundBroadcastIndex],
        tags: event,
      };

      const newData = [...broadcasts];
      newData[foundBroadcastIndex] = updatedBroadcastsList;
      setBroadcasts(newData);

      const { _id, tags, ...otherData } = updatedBroadcastsList;
      updateBroadcast(_id, { tags, ...otherData });
    }
  };

  const handleAddNewTag = async (newTagValue) => {
    await TagService.createTag(
      newTagValue,
      selectedOrganizationId,
      TAG_TYPES.BROADCAST
    );
    setNewTag('');
    getAllTags(undefined, TAG_TYPES.BROADCAST);
    setOpen(false);
  };

  const openConfirmDeleteTag = (tagName) => {
    const foundTag = formattedTags.find((tag) => tag.tagName === tagName);
    if (foundTag) {
      setIsDeleteTagOption(true);
      setDeleteOptionTag(foundTag);
    }
  };

  const deleteTag = async () => {
    try {
      await TagService.deleteTag(deleteOptionTag?._id, selectedOrganizationId);
      await Promise.all([
        getAllTags(undefined, TAG_TYPES.BROADCAST),
        getBroadcasts(),
      ]);
      closeDeleteTag();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const closeDeleteTag = () => {
    setIsDeleteTagOption(false);
    setDeleteOptionTag(null);
  };

  useEffect(() => {
    if (
      confirmedBroadcastId &&
      confirmActionType === diffuserStatuses.DRAFT.value
    ) {
      calculateCreditsToLaunch(confirmedBroadcastId);
    }
  }, [confirmedBroadcastId, confirmActionType]);

  const changeCopmanyForCopy = (event) => {
    const selectedCompany = organization.find(
      (org) => org.name === event.target.value.name
    );

    setSelectedOrganizationToCopy(selectedCompany);
  };

  const resetCompanyToCopy = () => {
    const company = organization.find(
      (org) => org._id === selectedOrganizationId
    );

    setSelectedOrganizationToCopy(company);
  };

  useEffect(() => {
    if (!selectedOrganizationToCopy && organization.length) {
      const companyToSelect = organization.find(
        (org) => org._id === selectedOrganizationId
      );

      setSelectedOrganizationToCopy(companyToSelect);
    }
  }, [organization.length]);

  if (isLoaderVisible) return <></>;

  return (
    <>
      <Grid container spacing={0} display={'flex'} flexDirection={'column'}>
        <Grid item>
          <TypographyUI variant="h6" align="left" marginBottom="5px">
            Parcours
          </TypographyUI>
          <Typography variant="body2" align="left" marginTop={'5px'}>
            Crédit(s) utilisé(s): {oneCompanyStatistics?.remaining_credits || 0}{' '}
            / Volume contractuel mensuel :{' '}
            {oneCompanyStatistics?.monthly_authorized_credits || 0}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          marginTop={'10px'}
          marginBottom={'16px'}
        >
          <Grid item>
            <TextField
              sx={{ m: 1, left: '-8px' }}
              label="Recherche"
              autoComplete="off"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
              value={searchText}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', gap: 1 }}>
            <DeleteBroadcastsPopup
              selectedBroadcasts={selectedBroadcasts}
              setSelectedBroadcasts={setSelectedBroadcasts}
            />
            <CreateEditDiffuser type={actionTypes.create} confirmCreateBroadcast={handleConfirm} />
          </Grid>
        </Grid>
      </Grid>
      <BroadcastTable
        filteredBroadcasts={filteredBroadcasts}
        handleLinkCopy={handleLinkCopy}
        handleCheckboxChange={handleCheckboxChange}
        isSnackbarOpen={isSnackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        activePopup={activePopup}
        open={open}
        clonedBroadcastName={clonedBroadcastName}
        cloneBroadcast={cloneBroadcast}
        setClonedBroadcastName={setClonedBroadcastName}
        confirmDialogOpen={confirmDialogOpen}
        confirmActionType={confirmActionType}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        selectedBroadcasts={selectedBroadcasts}
        changeSelectedBroadcasts={changeSelectedBroadcasts}
        creditsInfo={creditsInfo}
        formattedTags={formattedTags}
        handleAddTag={handleAddNewTag}
        setNewTag={setNewTag}
        handleTagChange={handleTagChange}
        openConfirmDeleteTag={openConfirmDeleteTag}
        isConfirmCopyDisabled={isConfirmCopyDisabled}
        changeCopmanyForCopy={changeCopmanyForCopy}
        selectedOrganizationToCopy={selectedOrganizationToCopy}
        setConfirmedBroadcastId={setConfirmedBroadcastId}
      />

      <Dialog open={isDeleteTagOption}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voulez-vous vraiment supprimer la balise{' '}
            <u>'{deleteOptionTag?.tagName}'</u>, elle sera supprimée partout.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={closeDeleteTag}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={deleteTag}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Broadcast;
