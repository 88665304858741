import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableSortLabel,
  Box,
} from '@mui/material';
import './MicroTrainings.scss';
import TableActuins from './components/TableActuins';
import DeleteTraining from './components/DeleteTraining';
import CreateEditTrainings from './CreateEditTrainings';
import { actionTypes } from '../../constants/types';
import { formatDates } from '../../helpers';
import useSortableTable from '../../hooks/useSortTable';
import Tags from '../../components/Tags/Tags';

function TrainingTable({
  trainings,
  searchTerm,
  handleOpen,
  handleClose,
  cloneTraining,
  isCloneBtnDisabled,
  deleteTraining,
  activePopup,
  deleteConfirmationOpen,
  handleCloseDeleteConfirmation,
  confirmDeleteTraining,
  clonedTrainingName,
  setClonedTrainingName,
  formattedTags,
  handleAddTag,
  setNewTag,
  handleTagChange,
  openConfirmDeleteTag,
  changeCopmanyForCopy,
  selectedOrganizationToCopy,
}) {
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const { sortedData, handleSort, sortConfig } = useSortableTable(
    filteredTrainings,
    {
      key: 'created_at',
      direction: 'asc',
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElIndex, setAnchorElIndex] = useState(null);

  const openMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setAnchorElIndex(index);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setAnchorElIndex(null);
  };

  useEffect(() => {
    const searchTermLowerCase = searchTerm.toLowerCase();

    const filteredTable = trainings.filter((training) => {
      const nameIncludesSearchTerm = training.name
        .toLowerCase()
        .includes(searchTermLowerCase);

      const contentIncludesSearchTerm = training.content
        .toLowerCase()
        .includes(searchTermLowerCase);

      const date = formatDates(new Date(training.created_at))

      const dateIncludesSearchTerm = date
        .toLowerCase()
        .includes(searchTermLowerCase);

      const tagsIncludesSearchTerm = (training.tags || []).some((tag) =>
        tag.toLowerCase().includes(searchTermLowerCase.toLowerCase())
      );

      return (
        (nameIncludesSearchTerm ||
          contentIncludesSearchTerm ||
          dateIncludesSearchTerm ||
          tagsIncludesSearchTerm) 
      );
    });

    setFilteredTrainings(filteredTable);
  }, [searchTerm, trainings]);

  return (
    <>
      {filteredTrainings.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className="green-border">
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'created_at'}
                    direction={
                      sortConfig.key === 'created_at' ? sortConfig.direction : 'asc'
                    }
                    onClick={() => handleSort('created_at')}
                  >
                    Date de création
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'name'}
                    direction={
                      sortConfig.key === 'name' ? sortConfig.direction : 'asc'
                    }
                    onClick={() => handleSort('name')}
                  >
                    Titre Contenu
                  </TableSortLabel>
                </TableCell>
                <TableCell>Tags</TableCell>
                <TableCell className="text-center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((tr, index) => {
                const training = JSON.parse(JSON.stringify(tr));
                const date = formatDates(new Date(tr.created_at));
                return (
                  <TableRow key={index} className="green-border">
                    <TableCell className="green-border">{date}</TableCell>
                    <TableCell className="green-border">
                      <CreateEditTrainings
                        id={training._id}
                        type={actionTypes.edit}
                        text={training.name}
                      />
                    </TableCell>
                    <TableCell className="green-border">
                      <Tags
                        options={formattedTags}
                        value={training}
                        handleAddTag={handleAddTag}
                        handleTagChange={handleTagChange}
                        openConfirmDeleteTag={openConfirmDeleteTag}
                        setNewTag={setNewTag}
                        key={training._id}
                      />
                    </TableCell>
                    <TableCell className="green-border">
                      <TableActuins
                        training={training}
                        index={index}
                        handleOpen={handleOpen}
                        activePopup={activePopup}
                        handleClose={handleClose}
                        clonedTrainingName={clonedTrainingName}
                        setClonedTrainingName={setClonedTrainingName}
                        cloneTraining={cloneTraining}
                        isCloneBtnDisabled={isCloneBtnDisabled}
                        deleteTraining={deleteTraining}
                        anchorEl={anchorEl}
                        anchorElIndex={anchorElIndex}
                        openMenu={openMenu}
                        closeMenu={closeMenu}
                        changeCopmanyForCopy={changeCopmanyForCopy}
                        selectedOrganizationToCopy={selectedOrganizationToCopy}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}

      {!trainings.length ? (
        <Box>
          <Typography variant="body1" style={{ padding: '16px 16px 0 16px' }}>
            Aucun contenu disponible.
          </Typography>
          <Typography variant="body1" style={{ padding: '0 16px 16px 16px' }}>
            Vous pouvez en ajouter un nouveau.
          </Typography>
        </Box>
      ) : null}

      {trainings.length && !filteredTrainings.length ? (
        <Box>
          <Typography variant="body1" style={{ padding: '16px 16px 0 16px' }}>
            Aucun contenu disponible.
          </Typography>
          <Typography variant="body1" style={{ padding: '0 16px 16px 16px' }}>
            Vous pouvez en ajouter un nouveau.
          </Typography>
        </Box>
      ) : null}

      <DeleteTraining
        deleteConfirmationOpen={deleteConfirmationOpen}
        handleCloseDeleteConfirmation={handleCloseDeleteConfirmation}
        confirmDeleteTraining={confirmDeleteTraining}
      />
    </>
  );
}
export default TrainingTable;
