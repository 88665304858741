import { Button, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Reminder from './Reminder';
import { isContainLinkOrResponse } from '../../../helpers/trainings';
import { diffuserTypes } from '../../../constants/broadcast';

const CreateEditDiffuserTableActions = ({
  index,
  row,
  getContentForTraining,
  RemoveRow,
  isMandatoryDisabled,
  changeAction,
  tableData,
  setTableData,
  copyTableToCompare,
  diffuserType,
}) => {
  const isFullText = true;
  const contentOfTraining = getContentForTraining(row.selectValue, isFullText);
  const areActionBtnsDisabled = isContainLinkOrResponse(contentOfTraining);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent:
          diffuserType === diffuserTypes.manual.value ? 'center' : 'flex-start',
      }}
    >
      <Tooltip
        title={
          <div style={{ maxWidth: '500px', overflowY: 'auto' }}>
            <h3 style={{ color: '#66deae' }}>Content:</h3>
            <span
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {getContentForTraining(row.selectValue)}
            </span>
          </div>
        }
      >
        <Button>
          <i className="fa-regular fa-eye"></i>
        </Button>
      </Tooltip>

      <Reminder
        tableData={tableData}
        setTableData={setTableData}
        row={row}
        index={index}
        isActive={row.reminder}
        copyTableToCompare={copyTableToCompare}
        diffuserType={diffuserType}
        areActionBtnsDisabled={areActionBtnsDisabled}
      />

      {diffuserType !== diffuserTypes.manual.value && (
        <Tooltip
          title={
            <span style={{ height: '100px', width: '170px' }}>Obligatoire</span>
          }
        >
          <Box
            className="align-cell-center cell-gap"
            onClick={(e) =>
              !isMandatoryDisabled &&
              !areActionBtnsDisabled &&
              changeAction(row.mandatory, index, 'mandatory')
            }
            style={{
              cursor: 'pointer',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="35px"
              height="35px"
              color={
                areActionBtnsDisabled
                  ? '#dedede'
                  : row.mandatory
                  ? '#66DEAE'
                  : '#9f9f9f'
              }
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={
                  areActionBtnsDisabled
                    ? '#dedede'
                    : row.mandatory
                    ? '#66DEAE'
                    : '#9f9f9f'
                }
                stroke="none"
              >
                <path d="M1635 4790 c-11 -4 -310 -299 -665 -654 l-645 -646 0 -930 0 -930 653 -652 652 -653 930 0 930 0 652 653 653 652 0 930 0 930 -653 652 -652 653 -918 2 c-504 1 -926 -2 -937 -7z m2397 -758 l608 -607 0 -865 0 -865 -608 -607 -607 -608 -865 0 -865 0 -607 608 -608 607 0 865 0 865 608 607 607 608 865 0 865 0 607 -608z" />
                <path d="M1202 3917 l-562 -562 0 -795 0 -795 563 -563 562 -562 795 0 795 0 563 563 562 562 0 795 0 795 -563 563 -562 562 -795 0 -795 0 -563 -563z m115 -819 c113 -42 203 -168 203 -284 0 -56 -33 -94 -80 -94 -40 0 -80 39 -80 77 0 45 -41 115 -79 137 -73 42 -174 16 -215 -54 -26 -44 -26 -116 0 -161 24 -43 94 -79 151 -79 113 0 240 -91 281 -203 99 -262 -153 -514 -415 -415 -113 42 -203 168 -203 284 0 56 33 94 80 94 40 0 80 -39 80 -77 0 -45 41 -115 79 -137 45 -26 117 -26 161 0 70 41 96 142 54 215 -24 43 -94 79 -151 79 -114 0 -239 91 -282 205 -19 49 -22 74 -19 135 13 220 226 357 435 278z m1138 -3 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -23 -24 -29 -25 -160 -25 l-135 0 0 -455 0 -456 -25 -24 c-15 -16 -36 -25 -55 -25 -19 0 -40 9 -55 25 l-25 24 0 456 0 455 -135 0 c-131 0 -137 1 -160 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 l24 25 351 0 351 0 24 -25z m622 3 c39 -14 74 -38 109 -72 89 -90 94 -113 94 -466 0 -353 -5 -376 -94 -466 -127 -126 -325 -126 -452 0 -89 90 -94 113 -94 466 0 282 1 303 22 357 60 161 248 244 415 181z m960 0 c77 -29 152 -104 181 -181 46 -122 19 -252 -72 -343 -79 -79 -131 -94 -316 -94 l-150 0 0 -215 c0 -216 0 -216 -25 -240 -32 -33 -78 -33 -110 0 l-25 24 0 511 0 511 25 24 c24 25 25 25 229 25 186 0 211 -2 263 -22z" />
                <path d="M2890 2938 c-33 -17 -51 -35 -67 -68 -22 -43 -23 -54 -23 -310 0 -256 1 -267 23 -310 58 -117 219 -114 277 5 20 42 21 55 18 317 -3 264 -4 274 -26 310 -24 39 -91 78 -133 78 -14 0 -45 -10 -69 -22z" />
                <path d="M3680 2800 l0 -160 133 0 c120 0 138 2 177 23 48 25 90 89 90 136 0 50 -41 114 -90 139 -39 19 -59 22 -177 22 l-133 0 0 -160z" />
              </g>
            </svg>
          </Box>
        </Tooltip>
      )}

      {index !== 0 && (
        <Button onClick={() => RemoveRow(index)}>
          <i className="fa-solid fa-minus"></i>
        </Button>
      )}
    </Box>
  );
};

export default CreateEditDiffuserTableActions;
