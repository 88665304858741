import React, { useState } from 'react';
import '../styles/SideMenu.scss';
import { Link } from 'react-router-dom';
import { getNavbarRoutes } from './AppRouter/routes';
import useStore from '../store/store';
import { ROLES } from '../constants/auth';
import OrganizationPicker from './OrganizationPicker';
import { useLearnerContext } from '../context/LearnerContext';
import { Box } from '@mui/material';

function SideMenu({ croute, setCroute }) {
  const isMenuOpen = useStore((state) => state.isMenuOpen);
  const toggleMenu = useStore((state) => state.toggleMenu);
  const closeToggleMenu = useStore((state) => state.closeToggleMenu);

  const role = useStore((state) => state.userRole);
  const { organization } = useLearnerContext();

  const routes = getNavbarRoutes(role);

  const {
    selectedOrganization,
    setSelectedOrganization,
    setSelectedOrganizationId,
  } = useStore((state) => state);

  const handleChange = (event) => {
    setSelectedOrganization(event.target.value);

    const selectedCompany = organization.find(
      (org) => org.name === event.target.value
    );

    setSelectedOrganizationId(selectedCompany._id);
    closeToggleMenu()
  };

  if (role === ROLES.UNAUTHORIZED) {
    return <></>;
  }

  return (
    <>
      <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <Box className="MobileWrapper">
            <OrganizationPicker
              organization={organization}
              selectedOrganization={selectedOrganization}
              handleChange={handleChange}
            />
          </Box>
          {routes.map((route) => (
            <Link
              to={route.path}
              key={route.path}
              onClick={() => {
                setCroute(route.path);
                closeToggleMenu();
              }}
              className={route.path === croute ? 'side-menu-selected' : ''}
            >
              {route.label}
              <span
                className={route.path === croute ? 'side-menu-bubble' : ''}
              />
            </Link>
          ))}
        </ul>
      </div>
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </>
  );
}

export default SideMenu;
