import { faSadCry } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const Thanks = () => {
  return (
    <Box
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      height: "100%",
    }}
  >
    <Typography
      variant="h5"
      style={{
        color: "#66DEAE",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        fontSize: "32px",
        marginTop: "20px",
      }}
    >
      We're sorry to see you go!
    </Typography>
    <Typography
      variant="body1"
      style={{
        color: "#333",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "18px",
        margin: "20px 0",
      }}
    >
      Your account removal request has been received.
    </Typography>
    <Typography
      variant="body1"
      style={{
        color: "#333",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "18px",
        margin: "20px 0",
      }}
    >
      We understand that circumstances change, and we hope to see you back with us in the future.
    </Typography>
    <Typography
      variant="body1"
      style={{
        color: "#333",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "18px",
        margin: "20px 0",
      }}
    >
      If you ever decide to return, don't hesitate to contact us!
    </Typography>
    <Typography
      variant="body1"
      style={{
        color: "#333",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "18px",
        margin: "20px 0",
      }}
    >
      Thank you for being a part of our community, and we wish you all the best in your future endeavors.
    </Typography>
  </Box>
  )
}

export default Thanks