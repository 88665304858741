import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { setPlaceholderForInput } from '../../../helpers';

function ProfileForm({
  prenom,
  nom,
  organisation,
  telephone,
  handleInputChange,
  handlePasswordDialogOpen,
  handleSubmit,
}) {

  useEffect(() => {
    setPlaceholderForInput({ prefix: true });
  }, []);

  return (
    <div className="profile-elements">
      <TextField
        required
        label="Nom"
        value={prenom}
        onChange={(e) => handleInputChange('prenom', e.target.value)}
        className="profile-element"
        style={{
          maxWidth: '428px',
          height: '50px',
          border: '1px',
          padding: '19px, 15px, 19px, 15px',
          gap: '10px',
        }}
      />
      <TextField
        required
        label="Prénom"
        value={nom}
        onChange={(e) => handleInputChange('nom', e.target.value)}
        className="profile-element"
        style={{
          maxWidth: '428px',
          height: '50px',
          border: '1px',
          padding: '19px, 15px, 19px, 15px',
          gap: '10px',
        }}
      />
      <PhoneInput
        required
        defaultCountry={'fr'}
        placeholder="Phone Number"
        disableCountryGuess={true}
        forceDialCode={true}
        value={telephone}
        onChange={(value) => handleInputChange('telephone', value)}
        style={{ margin: '0 10px' }}
        inputStyle={{
          maxWidth: '385px',
          width: '100%',
          height: '56px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        countrySelectorStyleProps={{
          buttonStyle: { height: '56px', marginTop: '10px' },
        }}
        dialCodePreviewStyleProps={{
          style: { height: '56px', alignSelf: 'center' },
        }}
      />
      <TextField
        required
        disabled
        label="Company Name"
        value={organisation}
        onChange={(e) => handleInputChange('organisation', e.target.value)}
        className="profile-element"
        style={{
          maxWidth: '428px',
          height: '50px',
          border: '1px',
          padding: '19px, 15px, 19px, 15px',
          gap: '10px',
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
          marginTop: '20px',
          gap: '20px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePasswordDialogOpen}
          style={{
            backgroundColor: '#66DEAE',
            width: '253px',
            height: '51px',
            radius: '5px',
            padding: '20px, 25px, 20px, 25px',
            gap: '10px',
            marginLeft: '10px',
          }}
        >
          Modifier le mot de passe
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{
            backgroundColor: '#66DEAE',
            width: '155px',
            height: '51px',
            radius: '5px',
            padding: '20px, 25px, 20px, 25px',
            gap: '10px',
            marginRight: '10px',
          }}
        >
          Enregistrer
        </Button>
      </div>
    </div>
  );
}

export default ProfileForm;
