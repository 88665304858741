import { Box } from '@mui/system';
import React from 'react';
import { lengthOfInput } from '../../../constants/trainings';

const ContentLength = ({
  characterCount,
  spacing = false,
  margin = '0 50px 0 0',
  limitter = lengthOfInput,
}) => {
  return (
    <Box
      sx={{
        fontSize: '12px',
        color: 'gray',
        textAlign: 'right',
        marginBottom: spacing ? '20px' : '10px',
        margin: margin,
      }}
    >
      {characterCount}/{limitter} caractères
    </Box>
  );
};

export default ContentLength;
