import { MenuItem, FormControl, Select } from '@mui/material';
import React from 'react';
import { ROLES } from '../constants/auth';
import useStore from '../store/store';

const OrganizationPicker = ({
  organization,
  selectedOrganization,
  handleChange,
}) => {
  const role = useStore((state) => state.userRole);

  if (role === ROLES.ADMIN) {
    return (
      <FormControl
        className="full-width"
        variant="standard"
        sx={{ minWidth: 120 }}
      >
        <Select
          id="organizations-select-id"
          value={selectedOrganization}
          onChange={(e) => handleChange(e)}
          label="Organizations"
        >
          {organization.map((org) => (
            <MenuItem key={org.name} value={org.name} name={org}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return <></>;
};

export default OrganizationPicker;
