import { Box } from '@mui/system';
import EmojiPicker from 'emoji-picker-react';
import React from 'react';

const EmojiePickerWrapper = ({
  responseActionType = null,
  emojiPickerRef,
  handleEmojiSelect,
  floatingBottom = false,
  originalPosition,
  isFixed,
}) => {
  return (
    <Box
      ref={emojiPickerRef}
      className="emojie-picker-wrapper"
      style={{
        position: isFixed ? 'fixed' : 'absolute',
        zIndex: '999',
        right: floatingBottom
          ? '40px'
          : originalPosition?.x
          ? originalPosition.x + 'px'
          : '85px',
        top: floatingBottom
          ? 'unset'
          : originalPosition?.y
          ? originalPosition.y + 'px'
          : '160px',
        bottom: floatingBottom ? '125px' : 'unset',
      }}
    >
      <EmojiPicker
        onEmojiClick={(emojie) => handleEmojiSelect(emojie, responseActionType)}
        lazyLoadEmojis
        width={250}
        skinTonesDisabled={true}
        previewConfig={{ showPreview: false }}
      />
    </Box>
  );
};

export default EmojiePickerWrapper;
