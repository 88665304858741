import { Button, Tooltip } from '@mui/material';
import React from 'react';

const EditButton = ({
  handleOpen,
  disabled = false,
  tooltipText = 'Modifier',
  icon = null,
}) => {
  return (
    <Tooltip title={tooltipText}>
      <Button
        onClick={handleOpen}
        disabled={disabled}
        sx={{
          opacity: disabled ? '0.3' : '1',
          margin: 0,
          padding: 0,
        }}
        size="small"
        startIcon={icon ? icon : <i className="fas fa-edit"></i>}
      />
    </Tooltip>
  );
};

export default EditButton;
