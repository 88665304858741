import { Snackbar } from '@mui/base';
import { Alert } from '@mui/material';
import React from 'react';

const SnackbarCustom = ({
  isSnackbarOpen,
  handleCloseSnackbar,
  type = 'error',
  text = 'Oops, something went wrong',
}) => {
  return (
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={5000}
      onClose={handleCloseSnackbar}
      elevation={6}
      className="custom-snackbar"
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={type}
        sx={{ maxWidth: 500 }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarCustom;
