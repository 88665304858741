import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import './Form.scss';
import React, { useRef, useState } from 'react';
import { FormsService } from '../../services/forms';
import FormQuestionsBuilder from './components/FormQuestionsBuilder';
import {
  newQuestionDevider,
  questionDevider,
  responseTypes,
} from '../../constants/form';
import { useLearnerContext } from '../../context/LearnerContext';
import useStore from '../../store/store';
import { actionTypes } from '../../constants/types';
import CreateButton from '../../components/CreateButton';
import ModifyButton from '../../components/ModifyButton';

function CreateEditForm({
  id = null,
  type,
  callbackOnClickIcon = null,
  text = null,
}) {
  const [open, setOpen] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const bottomRef = useRef(null);
  const { changeSelectedForm, getForms } = useLearnerContext();
  const [isLoaderVisible, setLoaderVisible] = useState(false);
  const { selectedOrganizationId, setSnackbarContent } = useStore(
    (store) => store
  );

  const handleOpen = () => {
    if (type === actionTypes.create) resetForm();
    if (type === actionTypes.edit) getFormById();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedAnswer({});
    if (callbackOnClickIcon) callbackOnClickIcon();
  };

  const resetForm = () => {
    setQuestions([]);
    setFormTitle('');
    setFormDescription('');
    setSelectedAnswer({});
  };

  const getAllForms = async () => {
    await getForms();
  };

  const getFormById = async () => {
    setLoaderVisible(true);
    try {
      const { data } = await FormsService.getById(id);
      const response = data.data;
      setFormTitle(response.title);
      setFormDescription(response.description);
      setQuestions(response.content);

      const transformedObj = {};

      for (const questionIndex in response.content) {
        const question = response.content[questionIndex];
        const answers = question?.correctAnswer || null;

        if (!answers?.length) continue;

        for (let i = 0; i < answers.length; i++) {
          const answer = answers[i];
          const index = question.options.indexOf(answer);
          if (index !== -1) {
            transformedObj[questionIndex] = transformedObj[questionIndex] || [];
            transformedObj[questionIndex].push(
              `${questionIndex}${questionDevider}${index}${questionDevider}${answer}`
            );
          }
        }

        if (transformedObj[questionIndex]) {
          transformedObj[questionIndex] =
            transformedObj[questionIndex].join(newQuestionDevider);
        }
      }
      setSelectedAnswer(transformedObj);
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', "Erreur due à l'obtention des formulaires");
    } finally {
      setLoaderVisible(false);
    }
  };

  const updateForm = async (event) => {
    try {
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].hasOwnProperty('correctAnswer')) {
          questions[i].correctAnswer = [];
        }
      }

      const form = {
        title: formTitle,
        description: formDescription,
        content: questions.map((question, index) => {
          const newQuestion = structuredClone(question);
          const answers = structuredClone(selectedAnswer[index]);

          if (answers) {
            const questions = answers.split(newQuestionDevider);

            for (const qeustion of questions) {
              const results = qeustion.split(questionDevider);
              const value = results[2];

              if (value) {
                if (!newQuestion.correctAnswer) newQuestion.correctAnswer = [];
                newQuestion.correctAnswer.push(value);
              }
            }
          }

          newQuestion.options = [...new Set(newQuestion.options)];
          newQuestion.correctAnswer = [
            ...new Set(
              newQuestion.correctAnswer.filter((answer) => answer !== null)
            ),
          ];

          return newQuestion;
        }),
      };
      await FormsService.updateForm(id, form);
      handleClose();
      getForms();
    } catch (e) {
      console.error(e);
      setSnackbarContent(
        'error',
        "Erreur due à la modification d'un formulaire"
      );
    }
  };

  const createForm = async () => {
    try {
      const form = {
        title: formTitle,
        company_id: selectedOrganizationId,
        content: questions.map((question, index) => {
          const newQuestion = { ...question };
          const answers = selectedAnswer[index];

          if (answers) {
            const questions = answers.split(newQuestionDevider);

            for (const qeustion of questions) {
              const results = qeustion.split(questionDevider);
              const value = results[2];

              if (value) newQuestion.correctAnswer.push(value);
            }
          }

          newQuestion.options = [...new Set(newQuestion.options)];
          newQuestion.correctAnswer = [
            ...new Set(
              newQuestion.correctAnswer.filter((answer) => answer !== null)
            ),
          ];

          return newQuestion;
        }),
      };

      if (formDescription) form['description'] = formDescription;

      await FormsService.createForm(form);
      getAllForms();
      resetForm();
      handleClose();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', "Erreur due à la création d'un formulaire");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type === actionTypes.edit) {
      updateForm();
    }

    if (type === actionTypes.create) {
      createForm();
    }
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleTogglePreview = () => {
    changeSelectedForm({
      title: formTitle,
      description: formDescription,
      content: questions,
    });
    window.open('/form-preview', '_blank');
  };
  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: '',
        responseType: responseTypes.TEXT.value,
        options: [],
        points: 0,
        required: false,
        linear_scale_size: 10,
        correctAnswer: [],
      },
    ]);
    setTimeout(() => {
      bottomRef.current?.scrollIntoView(
        {
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth',
        },
        500
      );
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestions(items);
  };

  const handleQuestionChange = (questionIndex, key, value) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex][key] = value;

      if (
        key === 'question_type_option_question_type' &&
        value !== 'Linear scale'
      ) {
        delete newQuestions[questionIndex]['linear_scale_size'];
      }

      return newQuestions;
    });
  };

  const handleDeleteOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push('');
    setQuestions(newQuestions);
  };

  const handleLinearScaleSizeChange = (questionIndex, newSize) => {
    if (newSize > 10 || newSize < 1) return;

    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[questionIndex].linear_scale_size = newSize;
      return newQuestions;
    });
  };

  const handleDuplicateQuestion = (index) => {
    const newQuestions = [...questions];
    const duplicateQuestion = structuredClone({ ...newQuestions[index] }); // Make a copy of the question to be duplicated
    newQuestions.push(duplicateQuestion); // Add the duplicate question to the end of the questions array
    setQuestions(newQuestions);
  };

  const handleDeleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <div>
      {type === actionTypes.create && <CreateButton handleOpen={handleOpen} />}
      {type === actionTypes.edit && !text ? (
        <ModifyButton handleOpen={handleOpen} />
      ) : null}
      {type === actionTypes.edit && text ? (
        <span className="text-link" onClick={handleOpen}>
          {text}
        </span>
      ) : null}

      <Dialog
        open={open && !isLoaderVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 24px',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#4546CD', fontWeight: 'bold' }}
            >
              {type === actionTypes.create
                ? 'Créer un formulaire'
                : 'Modifier un formulaire'}
            </Typography>
          </Box>
          <DialogContent>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginBottom: 3,
                }}
                autoComplete="off"
              >
                <TextField
                  label="Titre"
                  value={formTitle}
                  variant="outlined"
                  onChange={(e) => setFormTitle(e.target.value)}
                />
                <TextField
                  id="form-description"
                  label="Description"
                  value={formDescription}
                  onChange={(e) => setFormDescription(e.target.value)}
                  multiline
                  minRows={3}
                />
              </Box>

              <FormQuestionsBuilder
                ref={bottomRef}
                handleOnDragEnd={handleOnDragEnd}
                questions={questions}
                handleQuestionChange={handleQuestionChange}
                selectedAnswer={selectedAnswer}
                setSelectedAnswer={setSelectedAnswer}
                handleOptionChange={handleOptionChange}
                handleDeleteOption={handleDeleteOption}
                handleAddOption={handleAddOption}
                handleLinearScaleSizeChange={handleLinearScaleSizeChange}
                handleDuplicateQuestion={handleDuplicateQuestion}
                handleDeleteQuestion={handleDeleteQuestion}
              />
            </Box>
          </DialogContent>
          <DialogActions className="dialog-actions-form">
            <Button variant="outlined" color="error" onClick={handleClose}>
              Annuler
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddQuestion}
            >
              Ajouter une question
            </Button>

            <Button
              variant="contained"
              disabled={!(questions.length && formTitle)}
              color="primary"
              sx={{
                display: 'block',
                backgroundColor: '#66DEAE',
              }}
              onClick={handleTogglePreview}
            >
              Aperçu
            </Button>

            <Button
              disabled={!(questions.length && formTitle)}
              variant="contained"
              color="primary"
              sx={{
                display: 'block',
                backgroundColor: '#66DEAE',
              }}
              onClick={handleSubmit}
            >
              Enregistrer
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
}

export default CreateEditForm;
