import React, { useEffect, useState } from 'react';
import '../Home.css';
import {
  TextField,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
} from '@mui/material';
import { useLearnerContext } from '../../context/LearnerContext';
import BulkInvite from './BulkInvite';
import { LearnersService } from '../../services/learners';
import { TagService } from '../../services/tags';
import LearnerTable from '../../pages/Learners/LearnerTable';
import TypographyUI from '../../components/TypographyUI';
import Wrapper from './components/Wrapper';
import CreateEditLearner from './CreateEditLearner';
import { actionTypes } from '../../constants/types';
import useStore from '../../store/store';
import { TAG_TYPES } from '../../constants/tags';
import { isAdmin } from '../../helpers/user';

function Learners() {
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [newTag, setNewTag] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [learnerToDeleteId, setLearnerToDeleteId] = useState(null);
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const { selectedOrganizationId, setSnackbarContent, selectedCompanyId } =
    useStore((state) => state);

  const [isDeleteTagOption, setIsDeleteTagOption] = useState(false);
  const [deleteOptionTag, setDeleteOptionTag] = useState(null);

  const role = useStore((state) => state.userRole);
  const isUserAdmin = isAdmin(role);

  const {
    data,
    dataLearner,
    getAllTags,
    formattedTags,
    setData,
    getBroadcasts,
  } = useLearnerContext();

  useEffect(() => {
    fetchData();
  }, []);

  const navigatoToUnsubscribePage = (learnerId) => {
    window.open('/p/' + learnerId, '_blank', 'noreferrer');
  };

  const fetchData = async () => {
    Promise.all([
      getAllTags(undefined, TAG_TYPES.LEARNER),
      dataLearner(),
      getBroadcasts(),
    ]).then(() => {
      setLoaderVisible(false);
    });
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const deleteLearner = (learnerId) => {
    setLearnerToDeleteId(learnerId);
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const confirmDeleteLearner = async () => {
    if (learnerToDeleteId) {
      await LearnersService.deleteLearner(learnerToDeleteId, selectedCompanyId);
      dataLearner();
    }
    handleCloseDeleteConfirmation();
  };
  const UpdateLearner = async (id, updatedData) => {
    await LearnersService.updateLearner(id, updatedData);
  };

  // Refactor
  const handleTagChange = ({ event, id }) => {
    const foundLearnerIndex = data.findIndex((learner) => learner._id === id);

    if (foundLearnerIndex !== -1) {
      const updatedLearner = { ...data[foundLearnerIndex], tags: event };

      const newData = [...data];
      newData[foundLearnerIndex] = updatedLearner;
      setData(newData);

      const { _id, tags, ...otherData } = updatedLearner;
      UpdateLearner(_id, { tags, ...otherData });
    }
  };

  const handleAddNewTag = async (newTagValue) => {
    await TagService.createTag(
      newTagValue,
      selectedOrganizationId,
      TAG_TYPES.LEARNER
    );
    setNewTag('');
    getAllTags(undefined, TAG_TYPES.LEARNER);
  };

  const openConfirmDeleteTag = (tagName) => {
    const foundTag = formattedTags.find((tag) => tag.tagName === tagName);
    if (foundTag) {
      setIsDeleteTagOption(true);
      setDeleteOptionTag(foundTag);
    }
  };

  const deleteTag = async () => {
    try {
      await TagService.deleteTag(deleteOptionTag?._id, selectedOrganizationId);
      await Promise.all([
        getAllTags(undefined, TAG_TYPES.LEARNER),
        dataLearner(),
      ]);
      closeDeleteTag();
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const closeDeleteTag = () => {
    setIsDeleteTagOption(false);
    setDeleteOptionTag(null);
  };

  // Till here

  if (isLoaderVisible) return <></>;

  return (
    <div>
      <TypographyUI variant="h2" marginBottom="10px">
        Liste
      </TypographyUI>
      <Wrapper>
        <Box>
          <TextField
            label="Recherche"
            autoComplete="off"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <CreateEditLearner type={actionTypes.create} />
          <BulkInvite refetch={fetchData} />
          {/* To do in future: export excel file with users */}
          {/* <Button variant="contained" color="primary">
            Exporter
          </Button> */}
        </Box>
      </Wrapper>
      <LearnerTable
        data={data}
        sortField={sortField}
        sortOrder={sortOrder}
        handleSort={handleSort}
        searchValue={searchValue}
        deleteLearner={deleteLearner}
        formattedTags={formattedTags}
        setNewTag={setNewTag}
        handleAddTag={handleAddNewTag}
        handleTagChange={handleTagChange}
        openConfirmDeleteTag={openConfirmDeleteTag}
        isUserAdmin={isUserAdmin}
        navigatoToUnsubscribePage={navigatoToUnsubscribePage}
      />
      <Dialog open={deleteConfirmationOpen}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cet apprenant ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseDeleteConfirmation}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={confirmDeleteLearner}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeleteTagOption}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voulez-vous vraiment supprimer la balise{' '}
            <u>'{deleteOptionTag?.tagName}'</u>, elle sera supprimée partout.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={closeDeleteTag}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={deleteTag}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Learners;
