import { useState } from "react";
import "../Home.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import demoVideo from '../../assets/videos/Démonstration de la plateforme anime.mp4'

function Home() {
  console.log('Version 1.1');
  return (
    <div className="home-content">
      <Grid container className="home-container" >
        <Grid item xs={12} md={6} style={{ maxWidth: '550px' }}>
          <Typography
            variant="h4"
            style={{
              color: "#4546CD",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 700,
              fontSize: "32px",
            }}
          >
            Bienvenue
          </Typography>
          <div
            style={{
              width: "130px",
              height: "3px",
              backgroundColor: "#66DEAE",
              margin: "0 auto",
              borderRadius: "2px",
              marginTop: "15px",
            }}
          />
          <Typography variant="body1" style={{ paddingTop: "10%" }}>
            En quelques minutes, cette plateforme vous permet de créer, diffuser
            et évaluer vos parcours de micro-formations. Pour créer votre
            première micro-formation, c'est ici:
          </Typography>
          <Link to="/create">
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#66DEAE", marginTop: "5%" }}
            >
              Créer
            </Button>
          </Link>
          <Box className="video-wrapper">
            {/* old demo video
            <iframe
              title="Tuto Anemon"
              width="100%"
              height="300px"
              src="https://www.youtube.com/embed/GHU6tHp3B8Q"
              style={{ border: "none" }}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}


            <video preload="none" autoplay="false" width={"100%"} muted="false" loop="false" controls>
                <source src={demoVideo} type="video/mp4" />
                <source src={demoVideo} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} style={{ maxWidth: '550px' }}>
          <img
            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F0e2d4cf7342369f2358c6e63a63bb118.cdn.bubble.io%2Ff1660051761851x322394136095710100%2FMy%2520project-1%25281%2529.png?w=384&h=425&auto=compress&dpr=1.25&fit=max"
            alt="Your Image"
            style={{ width: "100%", height: "auto" }}
          />
          <Typography
            variant="h5"
            style={{
              color: "#4546CD",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 700,
              fontSize: "32px",
            }}
          >
            Découvrez la plateforme
          </Typography>
          <div
            style={{
              width: "150px",
              height: "3px",
              backgroundColor: "#66DEAE",
              margin: "0 auto",
              borderRadius: "2px",
              marginTop: "15px",
            }}
          />
          <Typography variant="body1" style={{ paddingTop: "10%" }}>
            Cette vidéo vous explique le process de réalisation d'un parcours.
            Pour toute question : support@anemon.fr
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
