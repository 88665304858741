import React from 'react';

const Wrapper = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '20px',
        marginBottom: '20px',
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
