import { BASE_URL } from "../constants/config";
import axios from "axios";

export const EMAILS_ENDPOINTS = {
  forms: `${BASE_URL}api/emails/`,
};

const token = localStorage.getItem("authorization");

class Emails {
  async getAll() {
    const response = await axios.get(EMAILS_ENDPOINTS.forms, {
      headers: {
        authorization: token,
      },
    });

    return response;
  }

  async getById(id) {
    const response = await axios.get(`${EMAILS_ENDPOINTS.forms}/${id}`, {
      headers : {
        authorization : token
      }
    })
    return response
  }

  async updateEmail(id,updatedEmail) {
    const response = await axios.put(`${EMAILS_ENDPOINTS.forms}/${id}`,updatedEmail, {
      headers:{
        authorization :  token
      }
    });
    return response
  }
  async createEmail(emailData) {
    const response = await axios.post(EMAILS_ENDPOINTS.forms, emailData, {
      headers: {
        authorization: token,
      },
    });
    return response;
  }

}

export const EmailService = new Emails();
