import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Button, FormControlLabel, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../services/app.utils';
import { LearnersService } from '../../services/learners';
import { BASE_URL } from '../../constants/config';
import useStore from '../../store/store';
import { downloadImageBase64 } from '../../helpers/company';
import { CompanyServices } from '../../services/companies';

const Welcome = () => {
  const [response, setResponse] = useState([]);
  const { id } = useParams();
  const [selectedTrainingIds, setSelectedTrainingIds] = useState([]);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [unsubscribeReason, setUnsubscribeReason] = useState('');
  const navigate = useNavigate();

  const { setSnackbarContent, companyLogo, setCompanyLogo } = useStore(
    (state) => state
  );

  const getBroadcastByLearner = async () => {
    try {
      const response = await LearnersService.welcomeLearner(id);
      setResponse(response.data.data);

      const containedTraining = response?.data?.data[0];

      if (!companyLogo && response && containedTraining?.company_id) {
        downloadImageBase64(
          CompanyServices.getCompanyLogo,
          containedTraining.company_id,
          setCompanyLogo
        );
      }
    } catch (e) {
      console.error(e);
      setSnackbarContent('error', 'Error');
    }
  };
  useEffect(() => {
    getBroadcastByLearner();
  }, []);

  const toggleTrainingSelection = (trainingName) => {
    if (selectedTrainingIds.includes(trainingName)) {
      setSelectedTrainingIds(
        selectedTrainingIds.filter((id) => id !== trainingName)
      );
    } else {
      setSelectedTrainingIds([...selectedTrainingIds, trainingName]);
    }
  };

  const handleUnsubscribeConfirm = async () => {
    try {
      const data = {
        learner_id: id,
        broadcast_id_list: selectedTrainingIds,
      };
      await axios.post(`${BASE_URL}api/learners/unsubscribe`, data);
      const new_broadcasts = response.filter(
        (f) => !selectedTrainingIds.includes(String(f._id))
      );
  
      setResponse(new_broadcasts);
  
      setSelectedTrainingIds([]);
      setUnsubscribed(false);
      setUnsubscribeReason('');
      setSnackbarContent(
        'success',
        'Merci. Votre demande a bien été prise encompte'
      );
    } catch(e) {
      console.error(e);
      setSnackbarContent('error', 'Erreur due à la désinscription');
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await LearnersService.unsubscribeLearner(id, unsubscribeReason);

      setUnsubscribeReason('');
      setUnsubscribed(false);
      navigate('/thanks');
    } catch (error) {
      console.error(error);
      setSnackbarContent('error', 'Erreur due à la désinscription');
    }
  };

  const formatTrainingNames = (trainings) => {
    return trainings.map((training) => (
      <div key={training.id}>{training.name}</div>
    ));
  };

  const formatTrainingStartDates = (trainings) => {
    return trainings.map((training) => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      const formattedDate = new Date(training.date).toLocaleDateString(
        undefined,
        options
      );
      const formattedTime = new Date(training.date).toLocaleTimeString(
        undefined,
        {
          hour: '2-digit',
          minute: '2-digit',
        }
      );
      return (
        <div
          key={training.id}
        >{`Commence Le ${formattedDate} à ${formattedTime}`}</div>
      );
    });
  };

  return (
    <div>
      {response.length === 0 ? (
        <Typography variant="body1" marginTop="50px">
          Vous n'avez pas encore de formation en cours
        </Typography>
      ) : (
        <div>
          <Typography variant="h5" marginTop="30px" align="left">
            Désinscription des formations
          </Typography>
          <Typography variant="body1" marginBottom="10px" align="left">
            Veuillez sélectionner le parcours dont vous souhaitez vous
            désinscrire
          </Typography>
          <Table>
            <TableHead>
              <TableRow className="green-border">
                <TableCell></TableCell>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">La formation</TableCell>
                <TableCell align="center">Date de début</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.map((broadcast, index) => (
                <TableRow key={index} className="green-border">
                  <TableCell align="center" className="green-border">
                    <Checkbox
                      checked={selectedTrainingIds.includes(broadcast._id)}
                      onChange={() => toggleTrainingSelection(broadcast._id)}
                    />
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {broadcast.name}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {formatTrainingNames(broadcast.trainings)}
                  </TableCell>
                  <TableCell align="center" className="green-border">
                    {formatTrainingStartDates(broadcast.trainings)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '20px' }}
              onClick={handleUnsubscribeConfirm}
              disabled={selectedTrainingIds.length === 0}
            >
              Se désinscrire
            </Button>
          </div>
          <Typography variant="h5" marginTop="30px" align="left">
            Suppression du compte
          </Typography>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={unsubscribed}
                    onChange={() => setUnsubscribed(!unsubscribed)}
                  />
                }
                label="Je souhaite me désinscrire totalement de la base de données Anemon"
              />
            </div>
            {unsubscribed && (
              <div>
                <Typography variant="body1" align="left">
                  Pour quelle raison?
                </Typography>
                <TextField
                  multiline
                  minRows={3}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  autoComplete='off'
                  value={unsubscribeReason}
                  onChange={(e) => setUnsubscribeReason(e.target.value)}
                />
              </div>
            )}
            <div style={{ marginTop: '20px', display: 'flex' }}>
              <Button
                variant="contained"
                color="error"
                onClick={handleUnsubscribe}
                disabled={!unsubscribed}
              >
                Supprimer mon compte
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Welcome;
