import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import './MicroTrainings.scss';
import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { useLearnerContext } from '../../context/LearnerContext';
import { CreerService } from '../../services/creeer';
import { useDropzone } from 'react-dropzone';
import { FilesService } from '../../services/files';
import { LinkService } from '../../services/link';
import { actionTypes } from '../../constants/types';
import ResponseFields from './components/ResponseFields';
import SelectResponseType from './components/SelectResponseType';
import ContentLength from './components/ContentLength';
import EmojiePickerWrapper from './components/EmojiePickerWrapper';
import FormPicker from './components/FormPicker';
import ModalFields from './components/ModalFields';
import CreateButton from '../../components/CreateButton';
import ModifyButton from '../../components/ModifyButton';
import {
  lengthOfInput,
  lengthOfLink,
  trainingResponseType,
} from '../../constants/trainings';
import useStore from '../../store/store';
import DialogTitleCustom from '../../components/DialogTitleCustom';
import { urlPattern } from '../../constants/url';
import { extractTextAndLink } from '../../helpers/trainings';
import { fileError } from '../../constants/files';

function CreateEditTrainings({
  type,
  id,
  text = null,
  callbackOnClickIcon = null,
}) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedOption, setSelectedOption] = useState('Informative');
  const { getTrainings, getForms, allForms } = useLearnerContext();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [isFormPopupVisible, setIsFormPopupVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [responseType, setResponseType] = useState('Non');
  const [openResponse, setOpenResponse] = useState('');
  const [tempQuestionLink, setTempQuestionLink] = useState('');
  const [closedResponses, setClosedResponses] = useState([]);
  const [correctResponse, setCorrectResponse] = useState('');
  const [incorrectResponse, setIncorrectResponse] = useState('');
  const [webAnswer, setWebAnswer] = useState(null);

  const [openUrl, setOpenUrl] = useState(false);
  const [url, setUrl] = useState('');
  const requiredFields = [title, content];
  const canSubmit = requiredFields.every((field) => field?.trim() !== '');

  const textFieldRef = useRef(null);
  const openResponsedRef = useRef(null);
  const closedResponseCorrectRef = useRef(null);
  const closedResponseIncorrectRef = useRef(null);

  const emojiPickerRef = useRef(null);
  const emojiPickerOpenResponsedRef = useRef(null);
  const emojiPickerClosedResponseCorrectRef = useRef(null);
  const emojiPickerClosedResponseIncorrectRef = useRef(null);

  const [selectedForm, setSelectedForm] = useState('');
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const { setSnackbarContent, selectedOrganizationId } = useStore(
    (store) => store
  );

  useEffect(() => {
    if (open && id) {
      const checkSubmitBtn = () => {
        if (responseType === 'Non') {
          setSubmitBtnDisabled(false);
        }

        if (responseType === 'Oui réponse ouverte') {
          const isOpenResponseEmpty = openResponse.trim() === '';
          if (isOpenResponseEmpty) setSubmitBtnDisabled(true);
          else setSubmitBtnDisabled(false);
        }

        if (responseType === 'Oui réponse fermée') {
          const areAnswersEmpty = closedResponses.length === 0;
          const areResponsesEmpty = [correctResponse, incorrectResponse].some(
            (response) => response.trim() === ''
          );

          if (areAnswersEmpty || areResponsesEmpty) setSubmitBtnDisabled(true);
          else setSubmitBtnDisabled(false);
        }
      };

      checkSubmitBtn();
    }
  }, [
    open,
    id,
    openResponse,
    closedResponses,
    correctResponse,
    incorrectResponse,
    canSubmit,
    responseType,
  ]);

  const isResponseTypeEmpty = responseType !== 'Non';
  const isWebAnswerDisabled = responseType !== 'Non' || !!selectedForm;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSelectedFile(file);
      }
    },
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        !emojiPickerRef.current.classList.contains('.emojie-picker-wrapper')
      ) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleResponseTypeChange = (event) => {
    setResponseType(event.target.value);
    setOpenResponse('');
    setClosedResponses([]);
    setCorrectResponse('');
    setIncorrectResponse('');
  };

  const getSelectedContent = (type) => {
    if (type === trainingResponseType.open) {
      return openResponse;
    }
    if (type === trainingResponseType.closedCorrect) {
      return correctResponse;
    }
    if (type === trainingResponseType.closedIncorrect) {
      return incorrectResponse;
    }
    return content;
  };

  const changeContent = (type, value) => {
    if (type === trainingResponseType.open) {
      setOpenResponse(value);
      return;
    }
    if (type === trainingResponseType.closedCorrect) {
      setCorrectResponse(value);
      return;
    }
    if (type === trainingResponseType.closedIncorrect) {
      setIncorrectResponse(value);
      return;
    }
    setContent(value);
  };

  const getSelectedRef = (refToChange) => {
    return refToChange === trainingResponseType.open
      ? openResponsedRef
      : refToChange === trainingResponseType.closedCorrect
      ? closedResponseCorrectRef
      : refToChange === trainingResponseType.closedIncorrect
      ? closedResponseIncorrectRef
      : textFieldRef;
  };

  const focusTextField = (refToChange) => {
    if (refToChange && trainingResponseType?.[refToChange]) {
      const selectedRef = getSelectedRef(refToChange);

      setTimeout(() => {
        selectedRef.current.focus();
        selectedRef.current?.setSelectionRange(
          selectedRef.current.value.length,
          selectedRef.current.value.length
        );
      }, 500);
      return;
    }

    setTimeout(() => {
      textFieldRef.current.focus();
      textFieldRef.current?.setSelectionRange(
        textFieldRef.current.value.length,
        textFieldRef.current.value.length
      );
    }, 500);
  };

  const openDialog = (type) => {
    setIsDialogOpen((prev) => (prev ? false : type));
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenUrl = (type) => {
    setOpenUrl((prev) => (prev ? false : type));
    setUrl('');
  };
  const handleCloseUrl = () => {
    setOpenUrl(false);
  };
  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };
  const handleSubmitUrl = async (
    responseActionType = trainingResponseType.none
  ) => {
    const dataLink = {
      source: url,
    };
    const response = await LinkService.shortenLink(dataLink);
    let shortenLink = response.data.link;
    // if (window?.location?.host.includes('localhost')) {
    //   shortenLink = shortenLink.replace(/[^/]+/, 'localhost:3001');
    // }

    const characterLength = checkCharacterLimit(responseActionType);
    if (characterLength + shortenLink.length > lengthOfInput) {
      setSnackbarContent('error', "Il n'y a pas d'espace vide");
      handleCloseUrl();
      focusTextField(responseActionType);
      return;
    }

    const selectedRef = getSelectedRef(responseActionType);

    if (selectedRef.current) {
      const input = selectedRef.current;
      const cursorPosition = input.selectionStart;
      const selectedContent = getSelectedContent(responseActionType);
      const currentValue = selectedContent;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        ' ' +
        shortenLink +
        ' ' +
        currentValue.substring(cursorPosition);

      setTimeout(() => {
        input.focus();
        const newCursorPosition = cursorPosition + shortenLink.length + 2;
        input?.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 500);

      changeContent(responseActionType, newValue);
    } else {
      const selectedContent = getSelectedContent(responseActionType);
      changeContent(responseActionType, selectedContent + shortenLink);
      focusTextField(responseActionType, responseActionType);
    }

    handleCloseUrl();
  };

  const handleFileUpload = async (
    responseActionType = trainingResponseType.none
  ) => {
    if (selectedFile) {
      try {
        setSnackbarContent(
          'info',
          'Le téléchargement des médias peut prendre un certain temps'
        );
        const response = await FilesService.uploadFile(
          selectedFile,
          1,
          null,
          selectedOrganizationId
        );

        setSnackbarContent('success', 'Fichier téléchargé avec succès');

        let link = response.link;
        // if (window?.location?.host.includes('localhost')) {
        //   link = link.replace(/[^/]+/, 'localhost:3001');
        // }
        setUploadedFile(link);

        const characterLength = checkCharacterLimit(responseActionType);
        if (characterLength + link.length > lengthOfInput) {
          setSnackbarContent('error', "Il n'y a pas d'espace vide");
          closeDialog();
          setSelectedFile(null);
          focusTextField(responseActionType);
          return;
        }

        const selectedRef = getSelectedRef(responseActionType);

        if (selectedRef.current) {
          const input = selectedRef.current;
          const cursorPosition = input.selectionStart;
          const selectedContent = getSelectedContent(responseActionType);
          const currentValue = selectedContent;
          const newValue =
            currentValue.substring(0, cursorPosition) +
            ' ' +
            link +
            ' ' +
            currentValue.substring(cursorPosition);

          changeContent(responseActionType, newValue);
          setTimeout(() => {
            input.focus();
            const newCursorPosition = cursorPosition + link.length + 2;
            input?.setSelectionRange(newCursorPosition, newCursorPosition);
          }, 500);
        } else {
          const selectedContent = getSelectedContent(responseActionType);
          changeContent(responseActionType, selectedContent + link);
          focusTextField(responseActionType);
        }

        closeDialog();
        setSelectedFile(null);
      } catch (error) {
        console.error(error);
        setSelectedFile(null);
        setSnackbarContent(
          'error',
          fileError[error]
            ? fileError[error]
            : 'Erreur lors du téléchargement du fichier.'
        );
      }
    }
  };

  const handleEmojiSelect = (
    emoji,
    responseActionType = trainingResponseType.none
  ) => {
    const characterLength = checkCharacterLimit(responseActionType);
    if (characterLength + 1 > lengthOfInput) {
      setSnackbarContent('error', "Il n'y a pas d'espace vide");
      setShowEmojiPicker(false);
      focusTextField(responseActionType);
      return;
    }

    const selectedRef = getSelectedRef(responseActionType);

    if (selectedRef.current) {
      const input = selectedRef.current;
      const cursorPosition = input.selectionStart;
      const selectedContent = getSelectedContent(responseActionType);
      const currentValue = selectedContent;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        emoji.emoji +
        currentValue.substring(cursorPosition);

      changeContent(responseActionType, newValue);
      setShowEmojiPicker(false);

      setTimeout(() => {
        input.focus();
        const newCursorPosition = cursorPosition + 2;
        input?.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 500);
    } else {
      const selectedContent = getSelectedContent(responseActionType);
      changeContent(responseActionType, selectedContent + emoji.emoji);
      setShowEmojiPicker(false);
      focusTextField(responseActionType);
    }
  };
  const toggleEmojiPicker = (type) => {
    setShowEmojiPicker((prev) => (prev ? false : type));
  };

  const handleOpen = () => {
    getForms();
    setOpen(true);
    if (type === actionTypes.create) handleReset();
    if (type === actionTypes.edit) getTrainingById();
  };
  const handleClose = () => {
    setOpen(false);
    if (callbackOnClickIcon) callbackOnClickIcon();
  };
  const handleReset = () => {
    setTitle('');
    setContent('');
    setSelectedOption('Informative');
    setResponseType('Non');
    setOpenResponse('');
    setClosedResponses([]);
    setCorrectResponse('');
    setIncorrectResponse('');
    setSelectedForm('');
    setUrl('');
    setTempQuestionLink('');
    setUploadedFile('');
    setUploadedFile('');
    setWebAnswer(null);
  };

  const checkForSelectedForm = (response) => {
    const match = response.content.match(urlPattern);
    if (match) {
      const url = match[0];
      setSelectedForm(url);
    } else {
      setSelectedForm('');
    }
  };

  const getTrainingById = async () => {
    const { data } = await CreerService.getTrainingById(id);
    const response = data.data;

    const content = extractTextAndLink(response.content);
    const isQuestionType = response.responseType && response.responseType !== 'Non';

    if (type === actionTypes.edit && isQuestionType) {
      setTempQuestionLink(content?.link || '');
    }

    setTitle(response.name);
    setContent(
      isQuestionType ? content?.text || response.content : response.content
    );
    setSelectedOption(response.type);
    setResponseType(response.responseType);
    setOpenResponse(response.openResponse || '');
    setClosedResponses(response.closedResponses || []);
    setCorrectResponse(response.correctResponse || '');
    setIncorrectResponse(response.incorrectResponse || '');
    setWebAnswer(response?.webFlow || null);

    checkForSelectedForm(response);
  };

  const createTraining = async (formData) => {
    await CreerService.createTraining(formData);
    handleClose();
    getTrainings();
  };

  const editTraining = async (id, updatedData) => {
    const isQuestionType = responseType && responseType !== 'Non';
    const tempContent = isQuestionType
      ? (content.endsWith('\n') ? content : content + '\n') + tempQuestionLink
      : content;

    const data = {
      ...updatedData,
      content: tempContent,
    };

    await CreerService.updateTraining(id, data);
    handleClose();
    getTrainings();
  };

  const submitWebAnswer = async (webAnswerPayload) => {
    if (webAnswerPayload) {
      const options = webAnswerPayload?.options;
      if (options) {
        const allOptions = options.map((option) => option.value);

        const correctValues = options
          .filter((option) => option.isCorrect)
          .map((option) => option.value);

        const webAnswersData = {
          ...webAnswerPayload,
          link: webAnswer?.link,
          options: allOptions,
          correctAnswers: correctValues,
        };
        if (!webAnswersData.link) {
          const blank = await CreerService.createBlankWebFlow(webAnswersData);
          webAnswersData.identifier = blank.identifier;
          webAnswersData.link = blank.link;
        }
        if (content.indexOf('/w/') < 0 && webAnswersData.link) {
          setContent(content+ ' ' + webAnswersData.link);
        }
        setWebAnswer(webAnswersData);
      }
    }
  };

  const unselectWebAnswerDialog = () => {
    setWebAnswer(null);
  };

  const areLengthOfInputsCorrect = () => {
    const contentCharacterLength = checkCharacterLimit(
      trainingResponseType.none
    );
    const openResponseCharacterLength = checkCharacterLimit(
      trainingResponseType.open
    );
    const closedCorrectCharacterLength = checkCharacterLimit(
      trainingResponseType.closedCorrect
    );
    const closedIncorrectCharacterLength = checkCharacterLimit(
      trainingResponseType.closedIncorrect
    );

    let isSuccess = true;

    if (responseType === 'Non') {
      isSuccess = contentCharacterLength <= lengthOfInput;
    }

    if (responseType === 'Oui réponse ouverte') {
      isSuccess =
        contentCharacterLength <= lengthOfInput &&
        openResponseCharacterLength <= lengthOfInput;
    }

    if (responseType === 'Oui réponse fermée') {
      isSuccess =
        contentCharacterLength <= lengthOfInput &&
        closedCorrectCharacterLength <= lengthOfInput &&
        closedIncorrectCharacterLength <= lengthOfInput;
    }

    return isSuccess;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isSuccess = areLengthOfInputsCorrect();

    if (!isSuccess) {
      setSnackbarContent(
        'error',
        'Vous avez saisi plus de caractères que ce qui est autorisé.'
      );
      return;
    }

    if (type === actionTypes.create) {
      const formData = {
        name: title,
        content: content,
        type: selectedOption,
        responseType: responseType,
        company_id: selectedOrganizationId,
        webFlow: webAnswer,
        openResponse:
          responseType === 'Oui réponse ouverte' ? openResponse : '',
        closedResponses:
          responseType === 'Oui réponse fermée' ? closedResponses : [],
        correctResponse:
          responseType === 'Oui réponse fermée' ? correctResponse : '',
        incorrectResponse:
          responseType === 'Oui réponse fermée' ? incorrectResponse : '',
      };

      createTraining(formData);
    }

    if (type === actionTypes.edit) {
      const updatedData = {
        name: title,
        content: content,
        type: selectedOption,
        webFlow: webAnswer,
        responseType: responseType,
        openResponse: openResponse,
        closedResponses: closedResponses,
        correctResponse: correctResponse,
        incorrectResponse: incorrectResponse,
      };

      editTraining(id, updatedData);
    }
  };

  const checkCharacterLimit = (type = trainingResponseType.none) => {
    if (type === trainingResponseType.none && webAnswer) {
      return content.length;
    }

    if (type === trainingResponseType.none) {
      return responseType !== 'Non' && type === actionTypes.create
        ? content.length + lengthOfLink
        : responseType !== 'Non' && type === actionTypes.edit
        ? content.length + (tempQuestionLink.length || lengthOfLink)
        : content.length;
    }

    if (type === trainingResponseType.open) {
      return openResponse.length;
    }

    if (type === trainingResponseType.closedCorrect) {
      return correctResponse.length;
    }

    if (type === trainingResponseType.closedIncorrect) {
      return incorrectResponse.length;
    }
  };

  const handleFormSelect = (
    event,
    responseActionType = trainingResponseType.none
  ) => {
    const form = event.target.value;

    let link = form;

    const characterLength = checkCharacterLimit(responseActionType);
    if (characterLength + link.length > lengthOfInput) {
      setSnackbarContent('error', "Il n'y a pas d'espace vide");
      setIsFormPopupVisible(false);
      focusTextField(responseActionType);
      return;
    }

    if (selectedForm) {
      let tempForm = selectedForm;

      const currentValue = content;
      const startIndexOfPreviousForm = currentValue.indexOf(tempForm);
      const endIndexOfPreviousForm = startIndexOfPreviousForm + tempForm.length;

      const newValue =
        currentValue.substring(0, startIndexOfPreviousForm) +
        link +
        currentValue.substring(endIndexOfPreviousForm);

      setContent(newValue);

      setSelectedForm(form);
      setIsFormPopupVisible(false);
      focusTextField(responseActionType);
      return;
    }

    if (textFieldRef.current) {
      const input = textFieldRef.current;
      const cursorPosition = input.selectionStart;
      const currentValue = content;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        ' ' +
        link +
        ' ' +
        currentValue.substring(cursorPosition);

      setContent(newValue);

      setTimeout(() => {
        input.focus();
        const newCursorPosition = cursorPosition + link.length + 2;
        input?.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 500);
    } else {
      setContent(content + link);
      focusTextField(responseActionType);
    }

    setSelectedForm(form);
    setIsFormPopupVisible(false);
  };

  const removeForm = () => {
    let tempForm = selectedForm;

    // if (window?.location?.host.includes('localhost')) {
    //   tempForm = tempForm.replace(/[^/]+/, 'localhost:3001');
    // }

    const currentValue = content;
    const startIndexOfPreviousForm = currentValue.indexOf(tempForm);
    const endIndexOfPreviousForm = startIndexOfPreviousForm + tempForm.length;

    const newValue =
      currentValue.substring(0, startIndexOfPreviousForm) +
      currentValue.substring(endIndexOfPreviousForm);

    setContent(newValue);

    setSelectedForm(null);
    setIsFormPopupVisible(false);
  };

  const handleOpenForms = () => {
    isFormPopupVisible
      ? setIsFormPopupVisible(false)
      : setIsFormPopupVisible(true);
  };

  return (
    <>
      {type === actionTypes.create && <CreateButton handleOpen={handleOpen} />}
      {type === actionTypes.edit && !text ? (
        <ModifyButton handleOpen={handleOpen} />
      ) : null}
      {type === actionTypes.edit && text ? (
        <span className="text-link" onClick={handleOpen}>
          {text}
        </span>
      ) : null}
      <Dialog
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
        maxWidth="md"
        scroll={'paper'}
        className="micro-trainings"
      >
        <DialogTitleCustom id="modal-modal-title">
          {type === actionTypes.create
            ? 'Créer un contenu'
            : 'Modifier le contenu'}
        </DialogTitleCustom>
        <DialogContent>
          <form onSubmit={handleSubmit} className="text-center">
            <TextField
              label="Titre"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              variant="outlined"
              className="space-bottom"
            />

            <ModalFields
              setContent={setContent}
              content={content}
              toggleEmojiPicker={toggleEmojiPicker}
              openDialog={openDialog}
              handleOpenUrl={handleOpenUrl}
              openUrl={openUrl}
              handleCloseUrl={handleCloseUrl}
              handleInputChange={handleInputChange}
              url={url}
              handleSubmitUrl={handleSubmitUrl}
              isDialogOpen={isDialogOpen}
              closeDialog={closeDialog}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              selectedFile={selectedFile}
              textFieldRef={textFieldRef}
              handleOpenForms={handleOpenForms}
              isResponseTypeEmpty={isResponseTypeEmpty}
              responseActionType={trainingResponseType.none}
              handleFileUpload={handleFileUpload}
              submitWebAnswer={submitWebAnswer}
              unselectWebAnswerDialog={unselectWebAnswerDialog}
              webAnswer={webAnswer}
              isWebAnswerDisabled={isWebAnswerDisabled}
            />

            <FormPicker
              allForms={allForms}
              isFormPopupVisible={isFormPopupVisible}
              handleFormSelect={handleFormSelect}
              handleCloseFormPicker={handleOpenForms}
              selectedForm={selectedForm}
              removeForm={removeForm}
            />

            {showEmojiPicker === trainingResponseType.none && (
              <EmojiePickerWrapper
                emojiPickerRef={emojiPickerRef}
                handleEmojiSelect={handleEmojiSelect}
              />
            )}

            <ContentLength
              characterCount={checkCharacterLimit(trainingResponseType.none)}
              spacing
            />

            <SelectResponseType
              responseType={responseType}
              handleResponseTypeChange={handleResponseTypeChange}
              selectedForm={selectedForm}
              webAnswer={webAnswer}
            />

            <ResponseFields
              responseType={responseType}
              openResponse={openResponse}
              setOpenResponse={setOpenResponse}
              closedResponses={closedResponses}
              setClosedResponses={setClosedResponses}
              correctResponse={correctResponse}
              incorrectResponse={incorrectResponse}
              setCorrectResponse={setCorrectResponse}
              setIncorrectResponse={setIncorrectResponse}
              selectedForm={selectedForm}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              toggleEmojiPicker={toggleEmojiPicker}
              openDialog={openDialog}
              handleOpenUrl={handleOpenUrl}
              openUrl={openUrl}
              handleInputChange={handleInputChange}
              url={url}
              handleSubmitUrl={handleSubmitUrl}
              handleCloseUrl={handleCloseUrl}
              isDialogOpen={isDialogOpen}
              selectedFile={selectedFile}
              closeDialog={closeDialog}
              openResponsedRef={openResponsedRef}
              closedResponseCorrectRef={closedResponseCorrectRef}
              closedResponseIncorrectRef={closedResponseIncorrectRef}
              handleEmojiSelect={handleEmojiSelect}
              showEmojiPicker={showEmojiPicker}
              ref={{
                emojiPickerOpenResponsedRef,
                emojiPickerClosedResponseCorrectRef,
                emojiPickerClosedResponseIncorrectRef,
              }}
              handleFileUpload={handleFileUpload}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            Annuler
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!canSubmit || isSubmitBtnDisabled}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CreateEditTrainings;
