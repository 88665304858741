import React from 'react';

const NotFoundPicture = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 420.07 204.87"
      style={{ maxWidth: '60vw' }}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-65"
          y1="72.16"
          x2="262.93"
          y2="255.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopOpacity="0"></stop>
          <stop offset="0.99"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="217.16"
          y1="164.75"
          x2="115.32"
          y2="227.42"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="425.36"
          y1="249.21"
          x2="137.36"
          y2="3.69"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="295.89"
          y1="167.21"
          x2="-18.16"
          y2="154.47"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="259.24"
          y1="105.38"
          x2="169.04"
          y2="201.94"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="246.43"
          y1="180.33"
          x2="233.21"
          y2="117.97"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="206.63"
          y1="128.36"
          x2="209.22"
          y2="127.46"
          xlinkHref="#linear-gradient"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="207.32"
          y1="138.38"
          x2="222.61"
          y2="150.91"
          xlinkHref="#linear-gradient"
        ></linearGradient>
      </defs>
      <path
        d="M24.31,232A61.89,61.89,0,0,1,18,139.67c9.16-9.24,21-15.18,31.16-23.18,17.57-13.75,30.58-33.61,50.18-44.11,23.6-12.65,52.2-9.31,78.12-3S229.42,84.73,256,82.45c15.83-1.37,31.07-6.73,46.75-9.34s32.88-2.14,46,7c19.1,13.39,23.64,40.45,39.31,57.9,7.59,8.46,17.67,14.48,24.39,23.68,10.81,14.79,10.53,37-.65,51.51-14.32,18.55-40.16,21.49-61,31.69-11.6,5.68-22,13.87-34,18.63-25.22,10-53.59,3.49-79.45-4.59s-52.5-17.8-79.27-13.84c-25,3.72-47.53,19.15-72.74,21.49-11.7,1.09-25.06-1-32.21-11.1C45.44,244.65,34.72,240,24.31,232Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
        opacity="0.18"
        style={{ isolation: 'isolate' }}
      ></path>
      <path
        d="M31.09,174.69c6.79-7.55,6.79-7.55,14.49-16.61l32.77-38.35c7.25-8.31,10.12-12.08,14-18h31a217,217,0,0,0-1.21,25.06V175.9h4.38a105.88,105.88,0,0,0,15-1.21v27.48a120.64,120.64,0,0,0-14.8-.9h-4.53v7.55a105.46,105.46,0,0,0,1.21,18.57H92.24a131,131,0,0,0,1.21-18.87v-7.4H50.72c-9.82,0-14,.15-19.63.6Zm62.51-16c0-6.8.3-15.1.91-21.75-3,4.23-5.29,7.25-9.36,12.38L63.1,176.2H93.6Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M31.09,174.69c6.79-7.55,6.79-7.55,14.49-16.61l32.77-38.35c7.25-8.31,10.12-12.08,14-18h31a217,217,0,0,0-1.21,25.06V175.9h4.38a105.88,105.88,0,0,0,15-1.21v27.48a120.64,120.64,0,0,0-14.8-.9h-4.53v7.55a105.46,105.46,0,0,0,1.21,18.57H92.24a131,131,0,0,0,1.21-18.87v-7.4H50.72c-9.82,0-14,.15-19.63.6Zm62.51-16c0-6.8.3-15.1.91-21.75-3,4.23-5.29,7.25-9.36,12.38L63.1,176.2H93.6Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient)"
      ></path>
      <path
        d="M199.13,136.82c3,.5,5.79,2.34,8.8,3,2.63.6,5.4.29,8,1.21,4.85,1.72,8,7.57,8.69,13.44s-.54,11.81-1.94,17.51C219,187,214,202,205,213.39c-.63.79-1.39,1.62-2.32,1.6a3.22,3.22,0,0,1-1.73-.81,28.88,28.88,0,0,1-5.15-4.51c-2.4-2.89-3.79-6.7-5-10.48-3.41-10.64-9.92-17.38-11.13-28.66C178.35,158.7,184.82,134.46,199.13,136.82Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M189.45,144.4a5.39,5.39,0,0,0-3.71.67,10.08,10.08,0,0,0-4.59,6.54,25.28,25.28,0,0,0-.29,8.19,110.85,110.85,0,0,0,4.63,23.58c.7,2.22-1.3,7.49.15,9.31,1.18,1.48.59,10.37,2.13,11.47,4.27,3.08,13.14,1.38,17.41,4.46,5.53,4,5.16-6.56,4.28-10.91-1-4.85-8.51-4-14.26-20.38C187.55,155.53,191.41,144.85,189.45,144.4Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-2)"
      ></path>
      <path
        d="M264.24,174.13c6.79-7.55,6.79-7.55,14.49-16.61l32.77-38.36a150.72,150.72,0,0,0,14-18h31a217,217,0,0,0-1.21,25.07v49.08h4.38a107.39,107.39,0,0,0,14.95-1.21v27.48a118.69,118.69,0,0,0-14.8-.91h-4.53v7.55a105.32,105.32,0,0,0,1.21,18.57H325.39A131,131,0,0,0,326.6,208v-7.4H283.87c-9.82,0-14.05.15-19.63.61Zm62.51-16c0-6.8.3-15.1.9-21.74-3,4.22-5.28,7.24-9.36,12.38l-22,26.88h30.5Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M264.24,174.13c6.79-7.55,6.79-7.55,14.49-16.61l32.77-38.36a150.72,150.72,0,0,0,14-18h31a217,217,0,0,0-1.21,25.07v49.08h4.38a107.39,107.39,0,0,0,14.95-1.21v27.48a118.69,118.69,0,0,0-14.8-.91h-4.53v7.55a105.32,105.32,0,0,0,1.21,18.57H325.39A131,131,0,0,0,326.6,208v-7.4H283.87c-9.82,0-14.05.15-19.63.61Zm62.51-16c0-6.8.3-15.1.9-21.74-3,4.22-5.28,7.24-9.36,12.38l-22,26.88h30.5Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-3)"
      ></path>
      <path
        d="M166.53,214.94a47.64,47.64,0,0,1-11-16.77,100.34,100.34,0,0,1-5.89-34.58c0-15.85,3.78-32.31,9.52-41.82,9.21-15.41,24.91-23.86,43.94-23.86,14.65,0,27.48,5,36.69,14a47.6,47.6,0,0,1,11,16.76,100.62,100.62,0,0,1,5.88,34.73c0,15.86-3.77,32.47-9.51,42C238.1,220.52,222.25,229,203.07,229,188.27,229,175.74,224.14,166.53,214.94Zm14-52.55c0,27,7.7,40.92,22.65,40.92s22.35-13.59,22.35-40.47c0-25.82-7.85-39.41-22.5-39.41C188.88,123.43,180.57,137.77,180.57,162.39Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M166.53,214.94a47.64,47.64,0,0,1-11-16.77,100.34,100.34,0,0,1-5.89-34.58c0-15.85,3.78-32.31,9.52-41.82,9.21-15.41,24.91-23.86,43.94-23.86,14.65,0,27.48,5,36.69,14a47.6,47.6,0,0,1,11,16.76,100.62,100.62,0,0,1,5.88,34.73c0,15.86-3.77,32.47-9.51,42C238.1,220.52,222.25,229,203.07,229,188.27,229,175.74,224.14,166.53,214.94Zm14-52.55c0,27,7.7,40.92,22.65,40.92s22.35-13.59,22.35-40.47c0-25.82-7.85-39.41-22.5-39.41C188.88,123.43,180.57,137.77,180.57,162.39Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-4)"
      ></path>
      <path
        d="M34.48,174.13c6.8-7.55,6.8-7.55,14.5-16.61l32.77-38.36a150.72,150.72,0,0,0,14-18h31a217,217,0,0,0-1.21,25.07v49.08h4.38a107.39,107.39,0,0,0,15-1.21v27.48a118.69,118.69,0,0,0-14.8-.91h-4.53v7.55a105.32,105.32,0,0,0,1.21,18.57H95.64A131,131,0,0,0,96.85,208v-7.4H54.11c-9.81,0-14,.15-19.63.61Zm62.52-16c0-6.8.3-15.1.9-21.74-3,4.22-5.28,7.24-9.36,12.38l-22,26.88H97Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M169.92,215.5a47.62,47.62,0,0,1-11-16.76A100.34,100.34,0,0,1,153,164.16c0-15.85,3.78-32.31,9.52-41.83,9.21-15.4,24.91-23.85,43.94-23.85,14.64,0,27.48,5,36.69,14a47.48,47.48,0,0,1,11,16.76A100.35,100.35,0,0,1,260.07,164c0,15.85-3.77,32.47-9.51,42-9.06,15.1-24.92,23.55-44.09,23.55C191.67,229.54,179.14,224.71,169.92,215.5ZM184,163c0,27,7.7,40.92,22.65,40.92,14.8,0,22.35-13.59,22.35-40.46,0-25.83-7.86-39.42-22.5-39.42C192.27,124,184,138.34,184,163Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M231.1,110.45a7.06,7.06,0,0,1,2.71,7.34,14.34,14.34,0,0,1,9.74,5.2,18.8,18.8,0,0,1,3.75,9.77,51.3,51.3,0,0,1-.23,10.58,11,11,0,0,1-.74,3.54,7.5,7.5,0,0,1-5.13,3.85,22.09,22.09,0,0,1-6.6.31l-5.25-.28a1.08,1.08,0,0,1-.5-.11,1,1,0,0,1-.35-.72c-.36-2.15-.91-3.09-1.3-5.24a33.51,33.51,0,0,0-5.6-12.59,4.72,4.72,0,0,1-1-1.95,5.29,5.29,0,0,1,.64-2.86A39.39,39.39,0,0,0,223,122a55.82,55.82,0,0,1,1.65-6.38,9.36,9.36,0,0,1,4-5.08"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-5)"
      ></path>
      <path
        d="M228.65,173.26s19.22,1.7,28-10c3.5-4.64-4.3-26.5-4.3-26.5l-8.87,15.88-14,3.18Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-6)"
      ></path>
      <path
        d="M267.92,174.13c6.79-7.55,6.79-7.55,14.49-16.61l32.77-38.36c7.25-8.3,10.12-12.08,14-18h31A217,217,0,0,0,359,126.26v49.08h4.38a107.39,107.39,0,0,0,14.95-1.21v27.48a118.69,118.69,0,0,0-14.8-.91H359v7.55a105.32,105.32,0,0,0,1.21,18.57H329.07A131,131,0,0,0,330.28,208v-7.4H287.55c-9.82,0-14.05.15-19.63.61Zm62.51-16c0-6.8.3-15.1.91-21.74-3,4.22-5.29,7.24-9.36,12.38l-22.05,26.88h30.5Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M183.55,139.06a10.18,10.18,0,0,0-1.5.69,3.47,3.47,0,0,0-1.16.95,1.48,1.48,0,0,0-.2,1.43,1.86,1.86,0,0,0,1.93.81c1.23-.12,2.37-.73,3.6-.94s2.39-1.06,1.52-2.37S184.78,138.6,183.55,139.06Z"
        transform="translate(-0.27 -63.77)"
        fill="#805841"
      ></path>
      <path
        d="M187.47,142c-1.8-.47-3.68.32-5.3,1.23a3.13,3.13,0,0,0-1,.77,1,1,0,0,0,.69,1.78,3.39,3.39,0,0,0,1.1-.07l2.49-.43a5.34,5.34,0,0,0,1.67-.48C188.25,144.14,189.21,142.43,187.47,142Z"
        transform="translate(-0.27 -63.77)"
        fill="#805841"
      ></path>
      <path
        d="M184,145.31a11.38,11.38,0,0,0-1.5.7,3.47,3.47,0,0,0-1.16,1,1.49,1.49,0,0,0-.2,1.42,1.86,1.86,0,0,0,1.93.81c1.23-.12,2.37-.73,3.6-.94s2.39-1.05,1.52-2.37S185.21,144.85,184,145.31Z"
        transform="translate(-0.27 -63.77)"
        fill="#805841"
      ></path>
      <path
        d="M187.88,147.83c-1.85-.15-3.56,1-5,2.14a3.28,3.28,0,0,0-.87.95,1,1,0,0,0,1,1.62,3.3,3.3,0,0,0,1.06-.26l2.37-.87a5.34,5.34,0,0,0,1.57-.77C189,149.82,189.67,148,187.88,147.83Z"
        transform="translate(-0.27 -63.77)"
        fill="#805841"
      ></path>
      <path
        d="M240.88,119.6a20.16,20.16,0,0,0-9.35-1.95,3.9,3.9,0,0,0-1.6.26,1.4,1.4,0,0,0-.84,1.28,1.94,1.94,0,0,0,.76,1.18,17,17,0,0,0,3.94,2.68,3.51,3.51,0,0,1,1.16.75,1.06,1.06,0,0,1,.17,1.27c-.33.46-1.09.48-1.34,1-.33.65.5,1.29,1.22,1.41a4.43,4.43,0,0,0,2.28-.21,4.25,4.25,0,0,1,1.64-.46,4,4,0,0,1,1.56.71c1.58.9,6.49.93,6.18-1.81C246.37,123.17,242.9,120.65,240.88,119.6Z"
        transform="translate(-0.27 -63.77)"
        fill="#805841"
      ></path>
      <path
        d="M216.72,141.65s22.11,9.11,24.64,8.64S239.8,128,239.8,128l7.54-3.45s17,33.7,3.08,38.6a40.45,40.45,0,0,1-26.48-.28Z"
        transform="translate(-0.27 -63.77)"
        fill="#3333cc"
      ></path>
      <path
        d="M228.48,129a14.94,14.94,0,0,1-1.36,5.66,10,10,0,0,1-7.55,4.77,22.39,22.39,0,0,0-3.34.47,3.15,3.15,0,0,0-2.29,1.91c-.89,2.16-1.81,4.35-4.68,3.62a3.6,3.6,0,0,1-.78-.27,7.72,7.72,0,0,1-2.39-1.75,14.39,14.39,0,0,1-2.18-2.84,4.15,4.15,0,0,1-.62-1.58,5.16,5.16,0,0,1,.24-2.05c1-4,1.16-8.53,2.58-12.43a18.24,18.24,0,0,1,7.86-9.42c3.59-2.09,8.58-2.12,11.63.73a10.5,10.5,0,0,1,3,6.2A29.12,29.12,0,0,1,228.48,129Z"
        transform="translate(-0.27 -63.77)"
        fill="#805841"
      ></path>
      <path
        d="M204.16,124c.19-1.88.18-3.79.55-5.64a8,8,0,0,1,2.67-4.87,4.44,4.44,0,0,1,1.2-.68c.39-.14.8-.22,1.2-.33a15.76,15.76,0,0,0,3.34-1.66,16.49,16.49,0,0,1,5.12-2,22.74,22.74,0,0,1,3.69-.26q3.4-.06,6.83-.06a3.36,3.36,0,0,1,1.57.26,2.53,2.53,0,0,1,1.11,1.48,7.05,7.05,0,0,1,0,4.24,20.22,20.22,0,0,1-1.75,3.92,6.84,6.84,0,0,1-1.46,2.09c-.53-.92-1-1.87-1.48-2.83-1.53,1.59-3.94,2-6.14,1.83s-4.36-.74-6.56-.77a4,4,0,0,0-2.94.87,4.6,4.6,0,0,0-1,2,49.56,49.56,0,0,0-1.13,5c-.26-.49-1.31-1.77-1.86-1.75a1.16,1.16,0,0,0-.81.45c-.91,1-.46,4.67.38,5.73a1.44,1.44,0,0,1,.37.58,1.12,1.12,0,0,1-.24.83,2.23,2.23,0,0,1-1.9.78c-.88,0-1.05-1.68-1.14-2.3C203.41,128.6,203.94,126.27,204.16,124Z"
        transform="translate(-0.27 -63.77)"
        fill="#291d17"
      ></path>
      <path
        d="M207.67,128.22a1.67,1.67,0,0,1-.16-.83,2.42,2.42,0,0,0-.09-.85,1.09,1.09,0,0,0-.51-.59.38.38,0,0,0-.21,0,.39.39,0,0,0-.23.28,5,5,0,0,0,.11,3.35,1.33,1.33,0,0,0,.77.86,1,1,0,0,0,.91-.28,1.23,1.23,0,0,0,.26-1.27C208.35,128.49,207.88,128.58,207.67,128.22Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-7)"
      ></path>
      <path
        d="M216.23,139.88a3.15,3.15,0,0,0-2.29,1.91c-.89,2.16-1.81,4.35-4.68,3.62a3.6,3.6,0,0,1-.78-.27,2.33,2.33,0,0,1-.58-.75,2.69,2.69,0,0,1,.08-2.1,4.69,4.69,0,0,1,4.22-2.9,13.45,13.45,0,0,1,2.27.3c.46.06,1,0,1.42.05A.87.87,0,0,1,216.23,139.88Z"
        transform="translate(-0.27 -63.77)"
        fill="url(#linear-gradient-8)"
      ></path>
    </svg>
  );
};

export default NotFoundPicture;
