import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  TextField,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { actionTypes } from '../../../constants/types';
import CreateEditTrainings from '../CreateEditTrainings';
import { extractTextAndLink } from '../../../helpers/trainings';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ROLES } from '../../../constants/auth';
import { getCompanyIndex } from '../../../helpers';
import { useLearnerContext } from '../../../context/LearnerContext';
import useStore from '../../../store/store';

const TableActuins = ({
  training,
  index,
  handleOpen,
  activePopup,
  handleClose,
  clonedTrainingName,
  setClonedTrainingName,
  cloneTraining,
  isCloneBtnDisabled,
  deleteTraining,
  anchorEl,
  anchorElIndex,
  openMenu,
  closeMenu,
  changeCopmanyForCopy,
  selectedOrganizationToCopy,
}) => {
  const content = extractTextAndLink(training.content);
  const extractedContent = content ? content.text : training.content;
  const { organization } = useLearnerContext();
  const role = useStore((state) => state.userRole);

  return (
    <Box className="align-center">
      <IconButton onClick={(e) => openMenu(e, index)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={anchorElIndex !== null && anchorElIndex === index}
        onClose={closeMenu}
      >
        <Tooltip
          title={
            <div
              style={{
                maxWidth: '500px',
                overflowY: 'auto',
              }}
            >
              <h3 style={{ color: '#66deae' }}>Content:</h3>

              <span
                style={{
                  whiteSpace: 'pre-line',
                }}
              >
                {' '}
                {extractedContent}
              </span>
            </div>
          }
        >
          <Button
            size="small"
            startIcon={<i className="fas fa-eye"></i>}
            style={{ margin: '0', padding: '0' }}
          ></Button>
        </Tooltip>
        <CreateEditTrainings
          id={training._id}
          type={actionTypes.edit}
          callbackOnClickIcon={() => closeMenu()}
        />
        <Tooltip title="Dupliquer">
          <Button
            sx={{
              color: '#4546CD',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            size="small"
            startIcon={<i className="fas fa-clone"></i>}
            onClick={() => {
              handleOpen(training._id, index);
              closeMenu();
            }}
            style={{ margin: '0', padding: '0' }}
          />
        </Tooltip>

        <Tooltip title="Supprimer">
          <Button
            sx={{
              color: '#4546CD',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            size="small"
            startIcon={<i className="fas fa-trash-alt"></i>}
            onClick={() => {
              deleteTraining(training._id);
              closeMenu();
            }}
            style={{ margin: '0', padding: '0' }}
          />
        </Tooltip>
      </Menu>

      <Dialog open={activePopup === index}>
        <DialogTitle>
          Dupliquer la micro-formation "{training.name}"
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={clonedTrainingName}
            fullWidth
            variant="standard"
            onChange={(e) => setClonedTrainingName(e.target.value)}
          />
          {role === ROLES.ADMIN && (
            <FormControl
              className="full-width"
              variant="standard"
              sx={{ minWidth: 120, marginTop: 5 }}
            >
              <Select
                id="organizations-form-select"
                value={
                  organization.length
                    ? organization[
                        getCompanyIndex(
                          selectedOrganizationToCopy?._id,
                          organization
                        )
                      ]
                    : ''
                }
                onChange={changeCopmanyForCopy}
                label="Organizations"
              >
                {organization.map((org) => (
                  <MenuItem key={org.name} value={org}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => cloneTraining(training._id)}
            disabled={isCloneBtnDisabled}
          >
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TableActuins;
