export const lengthOfInput = 650;
export const lengthOfLink = 44;

export const trainingTypes = {
  withForm: 'withForm',
  withQuestion: 'withQuestion',
  withWebAnswer: 'withWebAnswer',
};

export const contentWithFormString =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? 'vps-de6aee50.vps.ovh.net/g/'
    : 'triton.anemon.fr/g/';

export const dynamicUrlPattern =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? /Lien pour répondre: vps-de6aee50\.vps\.ovh\.net\/[m]?[q]\/[^\s\n]+/
    : /Lien pour répondre: triton\.anemon\.fr\/[m]?[q]\/[^\s\n]+/;

export const trainingResponseType = {
  // Closed response
  none: 'none',
  open: 'open',
  closedCorrect: 'closedCorrect',
  closedIncorrect: 'closedIncorrect',

  // Web answer
  webDescription: 'webDescription',
  webCorrectReponse: 'webCorrectReponse',
  webIncorrectResponse: 'webIncorrectResponse',
  webInfoResponse: 'webInfoResponse',
};

export const trainingWebAnswerTypes = {
  TEXT: {
    value: 'Short Answer',
    label: 'Réponse courte',
  },
  MULTIPLE_CHOICE: {
    value: 'Multiple choice',
    label: 'Choix multiple',
  },
  CHECKBOX: {
    value: 'Checkboxes',
    label: 'Case à cocher',
  },
};
