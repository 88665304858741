import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { BASE_URL } from '../../constants/config';
import { useLearnerContext } from '../../context/LearnerContext';
import { actionTypes } from '../../constants/types';
import CreateButton from '../../pages/Learners/components/CreateButton';
import LogoPicker from './LogoPicker';
import { UsersService } from '../../services/users';
import useStore from '../../store/store';
import DialogTitleCustom from '../DialogTitleCustom';
import { ADMIN_COMPANY } from '../../constants/auth';
import EditOrganizationButtons from './editOrganizationButtons';
import { companyConsentText } from '../../constants/organizations';
import { CompanyServices } from '../../services/companies';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone';

const dayJs = dayjs.extend(utc).extend(timezone)

const CreateEditOrganization = ({ type, id, companyName }) => {
  const [open, setOpen] = useState(false);
  const [organisation, setOrganisation] = useState('');
  const [credit, setCredit] = useState('');
  const [contractType, setContractType] = useState('');
  const [conditions, setConditions] = useState('');
  const [remainingCredit, setRemainingCredit] = useState('');
  const [logoFormData, setLogoFormData] = useState(null);
  const { companiesData } = useLearnerContext();
  const [image, setImage] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const setSnackbarContent = useStore((store) => store.setSnackbarContent);
  const [logoId, setLogoId] = useState('');
  const [isNameDisabled, setIsNameDisabled] = useState(false);

  const [isSuspend, setIsSuspend] = useState(false);
  const [startDate, setStartDate] = useState(() => dayjs());
  const [endDate, setEndDate] = useState(() => dayjs().add(3, 'month'));

  const formItemStyle = {
    marginBottom: '16px',
  };

  const handleReset = () => {
    setOrganisation('');
    setConditions(companyConsentText);
    setCredit('');
    setContractType('monthly');
    setRemainingCredit('');
    setLogoFormData('');
    setImage('');
  };

  const handleChangeContractType = (data) => {
    setContractType(data.target.value);
  }

  const handleOpen = () => {
    setOpen(true);
    if (type === actionTypes.edit) getCompanyId();
    if (type === actionTypes.create) handleReset();
  };
  const handleClose = () => setOpen(false);

  const createOrganization = async (data) => {
    try {
      await CompanyServices.createCompany(data);
      handleClose();
      companiesData();
    } catch (e) {
      console.log(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const downloadLogo = async (imageId) => {
    if (imageId) {
      try {
        setIsImageLoading(true);
        setLogoId(imageId);
        const downloadResponse = await UsersService.downloadAvatar(imageId);

        const blob = new Blob([downloadResponse.data], {
          type: 'application/octet-stream',
        });

        const reader = new FileReader();

        reader.onload = function () {
          const base64String = reader.result.split(',')[1];

          const logo = `data:image/jpeg;base64, ${base64String}`;
          setImage(logo);
        };

        reader.readAsDataURL(blob);
      } catch (e) {
        console.error(e);
        setSnackbarContent('error', 'Erreur due au téléchargement du logo');
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  const getCompanyId = async () => {
    const response = await CompanyServices.getById(id);
    const {
      name,
      monthly_authorized_credits,
      remaining_credits,
      contract_credits,
      contract_type,
      conditions,
      status,
    } = response.data.data;

    const contractStart = response?.data?.data?.contract_start;
    const contractEnd = response?.data?.data?.contract_end;

    setOrganisation(name);
    setIsNameDisabled(name.trim() === ADMIN_COMPANY);
    setCredit(contract_credits);
    setContractType(contract_type);
    setConditions(conditions);
    setRemainingCredit(remaining_credits);
    setIsSuspend(status === 'temp_active');
    if (contractStart) {
      setStartDate(dayjs(contractStart));
    }
    if (contractEnd) {
      setEndDate(dayJs.utc(contractEnd))
    }
    await downloadLogo(response.data.data?.logo || '');
  };

  const updateOrganization = async (id, updatedData) => {
    try {
      await CompanyServices.updateCompany(id, updatedData);
      handleClose();
      companiesData();
    } catch (e) {
      console.log(e);
      setSnackbarContent('error', e?.response?.data?.message || 'Error');
    }
  };

  const handleImageUpload = async (event) => {
    const selectedImage = event.target.files[0];

    if (!selectedImage) {
      return;
    }

    const imageURL = URL.createObjectURL(selectedImage);
    setImage(imageURL);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const imageBuffer = event.target.result;

      const formData = new FormData();
      formData.append(
        'file',
        new Blob([imageBuffer], { type: selectedImage.type })
      );

      setLogoFormData(formData);
    };

    reader.readAsArrayBuffer(selectedImage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempLogoId = logoId;

    if (logoFormData) {
      const uploadResponse = await UsersService.uploadAvatar(logoFormData);

      const identifier = uploadResponse.data.identifier;
      tempLogoId = identifier;
    }

    if (type === actionTypes.create) {
      const data = {
        data: {
          name: organisation.trim(),
          contract_credits: credit,
          contract_type: contractType,
          remaining_credits: credit,
          conditions,
          logo: tempLogoId,
          contract_start: startDate,
          contract_end: endDate,
        },
      };
      createOrganization(data);
    }

    if (type === actionTypes.edit) {
      const updatedData = {
        data: {
          name: organisation.trim(),
          monthly_authorized_credits: credit,
          remaining_credits: credit,
          conditions,
          logo: tempLogoId,
          contract_start: startDate,
          contract_end: endDate,
          admin_activation: isSuspend,
        },
      };
      updateOrganization(id, updatedData);
    }

    setLogoFormData(null);
  };

  return (
    <Box className="align-cell-center">
      {type === actionTypes.create && <CreateButton handleOpen={handleOpen} />}
      {type === actionTypes.edit && (
        <EditOrganizationButtons
          handleOpen={handleOpen}
          companyName={companyName}
        />
      )}
      <Dialog
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitleCustom id="modal-modal-title">
          {type === actionTypes.create
            ? 'Ajouter une organisation'
            : type === actionTypes.edit
            ? 'Modifier une organisation'
            : ''}
        </DialogTitleCustom>
        <DialogContent>
          <form onSubmit={handleSubmit} sx={{ textAlign: 'center' }}>
            <LogoPicker
              image={image}
              handleImageUpload={handleImageUpload}
              isImageLoading={isImageLoading}
            />
            <Tooltip
              title={
                isNameDisabled
                  ? "Le nom de l'organisation administrateur ne peut pas être modifié"
                  : ''
              }
            >
              <TextField
                label="Organisation"
                autoComplete="off"
                type="text"
                disabled={isNameDisabled}
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
                fullWidth
                variant="outlined"
                style={formItemStyle}
              />
            </Tooltip>
            <TextField
              label="Crédits autorisés"
              autoComplete="off"
              type="text"
              value={credit}
              onChange={(e) => {
                setCredit(e.target.value.replace(/[^0-9]/g, ''));
              }}
              fullWidth
              variant="outlined"
              style={formItemStyle}
            />
            {/** Do NOT REMOVE this comment, will be sed in future enhancement */}
            {/* <FormControl style={formItemStyle} fullWidth>
              <InputLabel id="demo-simple-select-label">Type de contrat</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contractType}
                label="Type de contrat"
                onChange={handleChangeContractType}
              >
                <MenuItem value={"monthly"}>Mensuel</MenuItem>
                <MenuItem value={"date_range"}>Plage de dates</MenuItem>
              </Select>
            </FormControl> */}
            <TextField
              label="Conditions d'utilisation"
              autoComplete="off"
              type="text"
              value={conditions}
              onChange={(e) => setConditions(e.target.value)}
              fullWidth
              multiline
              minRows={3}
              variant="outlined"
              style={formItemStyle}
            />
            <DatePicker
              views={['year', 'month']}
              className="full-width "
              label="Début de contrat"
              // minDate={dayjs()}
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{
                popper: {
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'applyCustom',
                        enabled: true,
                        phase: 'beforeWrite',
                        requires: ['computeStyles'],
                        fn: ({ state }) => {
                          state.styles.popper.height = '358px';
                          if (state.placement.includes('top-start')) {
                            state.styles.popper = {
                              ...state.styles.popper,
                              display: 'flex',
                              alignItems: 'flex-end',
                            };
                          }
                          if (state.placement.includes('bottom')) {
                            state.styles.popper = {
                              ...state.styles.popper,
                              display: 'block',
                            };
                          }
                        },
                      },
                    ],
                  },
                },
              }}
            />
            <DatePicker
              views={['year', 'month']}
              className="full-width "
              label="Fin de contrat"
              // minDate={startDate}
              sx={{ mt: 2 }}
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{
                popper: {
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'applyCustom',
                        enabled: true,
                        phase: 'beforeWrite',
                        requires: ['computeStyles'],
                        fn: ({ state }) => {
                          state.styles.popper.height = '358px';
                          if (state.placement.includes('top-start')) {
                            state.styles.popper = {
                              ...state.styles.popper,
                              display: 'flex',
                              alignItems: 'flex-end',
                            };
                          }
                          if (state.placement.includes('bottom')) {
                            state.styles.popper = {
                              ...state.styles.popper,
                              display: 'block',
                            };
                          }
                        },
                      },
                    ],
                  },
                },
              }}
            />
            <Box sx={{ marginTop: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '-9px',
                }}
              >
                <FormControlLabel
                  className="centered-label"
                  control={<Switch color="primary" />}
                  label="Suspendre"
                  labelPlacement="start"
                  checked={isSuspend}
                  onChange={() => setIsSuspend((prev) => !prev)}
                />
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="error">
            Annuler
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateEditOrganization;
