import {
  Autocomplete,
  Box,
  createFilterOptions,
  TextField,
} from '@mui/material';
import React, { forwardRef } from 'react';
import ContentLength from './ContentLength';
import ResponseActions from './ResponseActions';
import { trainingResponseType } from '../../../constants/trainings';
import EmojiePickerWrapper from './EmojiePickerWrapper';

const ResponseFields = forwardRef(({
  responseType,
  openResponse,
  setOpenResponse,
  closedResponses,
  setClosedResponses,
  correctResponse,
  incorrectResponse,
  setCorrectResponse,
  setIncorrectResponse,
  getRootProps,
  getInputProps,
  toggleEmojiPicker,
  openDialog,
  handleOpenUrl,
  openUrl,
  handleInputChange,
  url,
  handleSubmitUrl,
  handleCloseUrl,
  isDialogOpen,
  selectedFile,
  closeDialog,
  openResponsedRef,
  closedResponseCorrectRef,
  closedResponseIncorrectRef,
  handleEmojiSelect,
  showEmojiPicker,
  handleFileUpload,
}, ref) => {
  const filter = createFilterOptions();

  const { emojiPickerOpenResponsedRef, emojiPickerClosedResponseCorrectRef,emojiPickerClosedResponseIncorrectRef } = ref;

  if (responseType === 'Oui réponse ouverte') {
    return (
      <div>
        <Box className="response-field-wrapper">
          <TextField
            label="Quelle réponse automatique souhaitez-vous envoyer lorsque l'apprenant répond?"
            type="text"
            value={openResponse}
            onChange={(e) => {
              setOpenResponse(e.target.value)
            }}
            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            className="space-bottom"
            inputRef={openResponsedRef}
          />
          <ResponseActions
            responseActionType={trainingResponseType.open}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            openDialog={openDialog}
            toggleEmojiPicker={toggleEmojiPicker}
            handleOpenUrl={handleOpenUrl}
            openUrl={openUrl}
            handleInputChange={handleInputChange}
            url={url}
            handleSubmitUrl={handleSubmitUrl}
            handleCloseUrl={handleCloseUrl}
            isDialogOpen={isDialogOpen}
            selectedFile={selectedFile}
            closeDialog={closeDialog}
            handleFileUpload={handleFileUpload}
          />
          {showEmojiPicker === trainingResponseType.open && (
            <EmojiePickerWrapper
              responseActionType={trainingResponseType.open}
              emojiPickerRef={emojiPickerOpenResponsedRef}
              handleEmojiSelect={handleEmojiSelect}
              floatingBottom
            />
           )}
        </Box>
        <ContentLength characterCount={openResponse.length} />
      </div>
    );
  } else if (responseType === 'Oui réponse fermée') {
    return (
      <div>
        <Box className="response-field-wrapper spacing">
          <Autocomplete
            className="autocomplete-reest space-bottom autocomplete-full-width"
            freeSolo
            disableClearable
            multiple
            filterSelectedOptions={false}
            options={[]}
            value={closedResponses}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push(`"Ajouter" ${params.inputValue}`);
              }
              return filtered;
            }}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.tagName;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Texte du bénéficiaire considéré comme correct"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
            onChange={(event, newValue) => {
              const existingValues = closedResponses;
              const clickedElement = event.target;
              let lenghtToCut = 10;

              const newTagValue = newValue[newValue.length - 1];

              if (event.code === 'Backspace' || event.key === 'Backspace') {
                return;
              }

              if (event.code === 'Enter' || event.key === 'Enter') {
                lenghtToCut = 0;
              }

              if (
                clickedElement instanceof SVGElement ||
                clickedElement.nodeName.toLowerCase() === 'path'
              ) {
                return setClosedResponses(newValue);
              }

              if (newValue && newValue.length > 0) {
                const addedValue = newTagValue.slice(lenghtToCut);

                if (existingValues.includes(addedValue)) {
                  return;
                }

                setClosedResponses((prev) => [...prev, addedValue.trim()]);
              }
            }}
          />
        </Box>
        <ContentLength characterCount={closedResponses.join('').length} />
        <Box className="response-field-wrapper">
          <TextField
            label="Réponse à lui envoyer si correct"
            type="text"
            value={correctResponse}
            onChange={(e) => setCorrectResponse(e.target.value)}
            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            className="space-bottom"
            inputRef={closedResponseCorrectRef}
          />
          <ResponseActions
            responseActionType={trainingResponseType.closedCorrect}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            openDialog={openDialog}
            toggleEmojiPicker={toggleEmojiPicker}
            handleOpenUrl={handleOpenUrl}
            openUrl={openUrl}
            handleInputChange={handleInputChange}
            url={url}
            handleSubmitUrl={handleSubmitUrl}
            handleCloseUrl={handleCloseUrl}
            isDialogOpen={isDialogOpen}
            selectedFile={selectedFile}
            closeDialog={closeDialog}
            handleFileUpload={handleFileUpload}
          />
          {showEmojiPicker === trainingResponseType.closedCorrect && (
            <EmojiePickerWrapper
            responseActionType={trainingResponseType.closedCorrect}
              emojiPickerRef={emojiPickerClosedResponseCorrectRef}
              handleEmojiSelect={handleEmojiSelect}
              floatingBottom
            />
          )}
        </Box>
        <ContentLength characterCount={correctResponse.length} />
        <Box className="response-field-wrapper">
          <TextField
            label="Réponse à lui envoyer si faux"
            type="text"
            value={incorrectResponse}
            onChange={(e) => setIncorrectResponse(e.target.value)}
            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            className="space-bottom"
            inputRef={closedResponseIncorrectRef}
          />
          <ResponseActions
            responseActionType={trainingResponseType.closedIncorrect}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            openDialog={openDialog}
            toggleEmojiPicker={toggleEmojiPicker}
            handleOpenUrl={handleOpenUrl}
            openUrl={openUrl}
            handleInputChange={handleInputChange}
            url={url}
            handleSubmitUrl={handleSubmitUrl}
            handleCloseUrl={handleCloseUrl}
            isDialogOpen={isDialogOpen}
            selectedFile={selectedFile}
            closeDialog={closeDialog}
            handleFileUpload={handleFileUpload}
          />
          {showEmojiPicker === trainingResponseType.closedIncorrect && (
            <EmojiePickerWrapper
            responseActionType={trainingResponseType.closedIncorrect}
              emojiPickerRef={emojiPickerClosedResponseIncorrectRef}
              handleEmojiSelect={handleEmojiSelect}
              floatingBottom
            />
          )}
        </Box>
        <ContentLength characterCount={incorrectResponse.length} />
      </div>
    );
  } else {
    return <></>;
  }
});

export default ResponseFields;
