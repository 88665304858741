import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { accessableFileTypes, fileTypes } from '../../constants/files';
import { FilesService } from '../../services/files';
import './Files.scss';
import { downloadImageBase64 } from '../../helpers/company';
import { CompanyServices } from '../../services/companies';
import useStore from '../../store/store';

import FileViewer from 'react-file-viewer-extended';
import ErrorFile from './components/ErrorFile';
import { Document, Page } from 'react-pdf';

const Files = () => {
  const { id } = useParams();
  const [file, setFile] = useState('');
  const [fileType, setFileType] = useState('File');
  const [responseFileName, setResponseFileName] = useState('');
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isExternalType, setIsExternalType] = useState(false);
  const [isError, setIsError] = useState(false);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const { setCompanyLogo, setSnackbarContent } = useStore((state) => state);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    const getFile = async (identifier) => {
      setIsFileLoaded(false);
      setIsError(false);

      try {
        const response = await FilesService.downloadFile(identifier);
        const fileName = response.headers['content-disposition'];

        const str = "=UTF-8''";

        const indexOfTypeDivider = fileName.lastIndexOf('.');
        const indexOfFileName = fileName.indexOf(str);
        const responseFileName = fileName.slice(
          indexOfFileName + str.length,
          fileName.length
        );
        const type = fileName.slice(indexOfTypeDivider + 1, fileName.length);
        setIsFileLoaded(true);
        setResponseFileName(responseFileName);

        const fileData = response.data;
        const fileTyppe = accessableFileTypes[type.toLowerCase()];

        switch (fileTyppe) {
          case 'image': {
            const blob = new Blob([fileData], { type: 'image/jpeg' });
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType('image');
            break;
          }
          case 'audio': {
            const blob = new Blob([fileData], { type: 'audio/mpeg' });
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType('audio');
            break;
          }
          case 'video': {
            const blob = new Blob([fileData], { type: 'video/mp4' });
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType('video');
            break;
          }
          case 'pdf': {
            const blob = new Blob([fileData], { type: 'application/pdf' });
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType('pdf');
            break;
          }
          case 'xlsx': {
            const blob = new Blob([fileData]);
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType(type);
            setIsExternalType(true);
            break;
          }
          case 'docx': {
            const blob = new Blob([fileData]);
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType(type);
            setIsExternalType(true);
            break;
          }
          default: {
            const blob = new Blob([fileData]);
            const objectUrl = URL.createObjectURL(blob);
            setFile(objectUrl);
            setFileType('file');
            setIsExternalType(false);
          }
        }

        if (response.headers.has('Company-Id')) {
          const companyId = response.headers.get('Company-Id');

          downloadImageBase64(
            CompanyServices.getCompanyLogo,
            companyId,
            setCompanyLogo
          );
        }
      } catch (e) {
        console.error(e);
        // setSnackbarContent('error', 'Fichier introuvable');
        setIsError(true);
      }
    };

    if (id) getFile(id);
  }, [id]);

  const handleDownloadClick = () => {
    const a = document.createElement('a');
    a.href = file;
    a.download = `${responseFileName}`;

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (isError) {
    return (
      <Box className="files-wrapper">
        <Typography>Fichier introuvable</Typography>
      </Box>
    );
  }

  if (!isFileLoaded) {
    return (
      <Box className="files-wrapper">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Box>
    );
  }

  return (
    <Box className="files-wrapper">
      <Box sx={{ width: isExternalType ? '100%' : 'auto' }}>
        {fileType === fileTypes.IMAGE && <img alt="Media" src={file} />}
        {fileType === fileTypes.AUDIO && <audio controls src={file}></audio>}
        {fileType === fileTypes.VIDEO && (
          <video
            id="video-download"
            controls="controls"
            preload="none"
            src={file}
          >
            <source src={file} type="video/mp4" />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
        )}
        {fileType === fileTypes.PDF && (
          <>
            {numPages > 1 && (
              <>
                <Typography style={{ marginBottom: '10px' }}>
                  Page {pageNumber} sur {numPages}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      display: 'inline-block',
                      backgroundColor: '#66DEAE',
                    }}
                    onClick={goToPreviousPage}
                    disabled={pageNumber <= 1}
                  >
                    Précédent
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      display: 'inline-block',
                      backgroundColor: '#66DEAE',
                    }}
                    onClick={goToNextPage}
                    disabled={pageNumber >= numPages}
                  >
                    Suivant
                  </Button>
                </Box>
              </>
            )}
            <Box className="file-viewer-wrapper pdf-wrapper">
              {/* <FileViewer fileType={fileType} filePath={file} /> */}

              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            </Box>
          </>
        )}
        {fileType === fileTypes.FILE && (
          <ErrorFile responseFileName={responseFileName} />
        )}
        {isExternalType && (
          <Box className="file-viewer-wrapper">
            <FileViewer fileType={fileType} filePath={file} />
          </Box>
        )}
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          display: 'inline-block',
          backgroundColor: '#66DEAE',
        }}
        onClick={handleDownloadClick}
      >
        Télécharger
      </Button>
    </Box>
  );
};

export default Files;
